@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* Login page styles that we're reusing */
.login-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 125px); /* Reduced height */
  background-color: #f0f4f8;
  padding: 40px 20px; /* Increased vertical padding */
  overflow: hidden; /* Prevent dice from causing scrollbars */
  margin: -20px 0; /* Offset the content padding from App.css */
}

.login-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allow clicks to pass through to elements below */
  z-index: 0;
}

.login-dice {
  position: absolute;
  filter: invert(28%) sepia(20%) saturate(1100%) hue-rotate(188deg) brightness(90%) contrast(87%);
  transform-origin: center center;
  pointer-events: none;
}

.login-container {
  position: relative;
  width: 100%;
  max-width: 350px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #3D5A80;
  z-index: 1; /* Ensure container is above the dice */
}

.login-container h2 {
  text-align: center;
  margin-bottom: 24px;
  color: #1e3a5f;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  color: #4a5568;
  font-size: 14px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.input-group input:focus {
  outline: none;
  border-color: #3D5A80;
}

.submit-btn,
.switch-mode-btn {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  display: block;
}

.submit-btn {
  padding: 12px;
  background-color: #3D5A80;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #2c4057;
}

.submit-btn:disabled {
  background-color: #A0AEC0;
  cursor: not-allowed;
}

.error-message {
  background-color: #fed7d7;
  border: 1px solid #f56565;
  color: #c53030;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.success-message {
  background-color: #c6f6d5;
  border: 1px solid #48bb78;
  color: #2f855a;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.auth-options {
  margin-top: 20px;
  text-align: center;
}

.forgot-password-link {
  color: #3D5A80;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s ease;
  display: inline-block;
  margin-top: 10px;
}

.forgot-password-link:hover {
  color: #EE6C4D;
  text-decoration: underline;
}

.switch-mode-btn {
  background-color: #ffffff;
  color: #3D5A80;
  border: 1px solid #3D5A80;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 10px;
  width: 100%;
}

.switch-mode-btn:hover {
  background-color: #3D5A80;
  color: #ffffff;
}

@media (max-width: 480px) {
  .login-container {
    padding: 20px;
  }

  .submit-btn,
  .switch-mode-btn {
    max-width: 100%;
  }
} 