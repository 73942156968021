.spell-rating {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 15px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  gap: 20px;
}

.rating-section {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px;
}

.rating-section h3 {
  font-size: 1rem;
  margin: 0 0 10px 0;
  color: #3d5a80;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.rating-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.community-rating {
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
}

.user-rating-section {
  background-color: #e6f3ff;
  border: 1px solid #b8daff;
}

/* Community dice styling */
.community-dice {
  display: flex;
}

.community-die-container {
  position: relative;
  margin: 0 1px;
  overflow: visible;
}

/* Remove the background fill approach */
.community-die-fill {
  display: none;
}

.d20-icon {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  cursor: pointer;
}

.d20-icon img {
  width: 100%;
  height: 100%;
  filter: brightness(0.9) invert(1);
  opacity: 0.9;
  transition: all 0.2s ease;
}

/* Filled dice styling with glow */
.d20-icon.filled img {
  filter: brightness(1.1) invert(0.7) sepia(1) saturate(6) hue-rotate(5deg) drop-shadow(0 0 5px rgba(218, 165, 32, 0.8));
  opacity: 1;
  transform: scale(1.05);
}

/* Half-filled dice styling with two-image approach */
.d20-icon.half-filled {
  position: relative;
  overflow: hidden;
}

/* Left half (glowing) */
.d20-left-half {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
}

.d20-left-half img {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  filter: brightness(1.1) invert(0.7) sepia(0.8) saturate(5) hue-rotate(5deg) drop-shadow(0 0 3px rgba(218, 165, 32, 0.6));
  opacity: 0.9;
}

/* Right half (non-glowing) */
.d20-right-half {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.d20-right-half img {
  position: absolute;
  top: 0;
  right: 0;
  width: 200%;
  height: 100%;
  filter: brightness(0.9) invert(1);
  opacity: 0.9;
}

/* Enhance glow effect in the header for half-filled dice */
.spell-rating-header .d20-left-half img {
  filter: brightness(1.1) invert(0.7) sepia(0.8) saturate(5) hue-rotate(5deg) drop-shadow(0 0 4px rgba(218, 165, 32, 0.7));
  opacity: 0.9;
}

.d20-icon:hover {
  transform: scale(1.1);
}

/* User rating stars */
.rating-stars {
  display: flex;
  margin-bottom: 8px;
}

.star-icon {
  cursor: pointer;
  fill: #e2e2e2;
  stroke: #ccc;
  stroke-width: 0.5px;
  margin: 0 2px;
  transition: all 0.2s ease;
}

.star-icon:hover {
  transform: scale(1.1);
}

.star-icon.filled {
  fill: #ffc107;
  stroke: #e0a800;
}

.rating-info {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: #495057;
}

.average-rating {
  font-size: 1.2rem;
  font-weight: 600;
  color: #3d5a80;
  margin-right: 5px;
}

.rating-count {
  color: #6c757d;
  font-size: 0.85rem;
}

.login-prompt {
  margin-top: 5px;
  font-size: 0.85rem;
  color: #6c757d;
  font-style: italic;
}

.spell-rating.loading {
  color: #6c757d;
  font-size: 0.9rem;
  padding: 10px;
  text-align: center;
}

.rating-error {
  color: #dc3545;
  font-size: 0.85rem;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #f8d7da;
  border-radius: 4px;
  text-align: center;
  width: 100%;
}

@media (max-width: 768px) {
  .spell-rating {
    padding: 10px;
    margin: 10px 0;
    gap: 15px;
  }

  .rating-section {
    padding: 8px;
  }

  .rating-section h3 {
    font-size: 0.9rem;
  }

  .d20-icon,
  .community-die-container {
    width: 20px;
    height: 20px;
  }

  .average-rating {
    font-size: 1rem;
  }

  .rating-count,
  .login-prompt {
    font-size: 0.8rem;
  }
}

/* Rating container */
.rating-container {
  position: relative;
}

/* Header rating styles */
.spell-rating-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.15);
  transition: background-color 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.spell-rating-header:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.spell-rating-header .community-dice {
  display: flex;
  margin-right: 8px;
}

.rating-text {
  display: flex;
  align-items: baseline;
}

.spell-rating-header .rating-value {
  font-weight: 600;
  color: #fff;
  margin-right: 3px;
  font-size: 1rem;
}

.spell-rating-header .rating-count {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.8rem;
}

.spell-rating-header.loading {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
  padding: 4px 8px;
}

/* Dropdown container */
.dropdown-container {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  margin-top: 5px;
}

/* Rating dropdown styles */
.rating-dropdown {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  width: 220px;
  padding: 15px;
  animation: dropdown-fade-in 0.2s ease;
  border: 1px solid #3d5a80;
}

@keyframes dropdown-fade-in {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.rating-dropdown-header {
  margin-bottom: 12px;
  text-align: center;
}

.rating-dropdown-header h3 {
  margin: 0;
  color: #3d5a80;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.rating-dropdown-dice {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.rating-dropdown-dice .d20-icon {
  margin: 0 4px;
}

.rating-dropdown-dice .d20-icon img {
  filter: brightness(0.9) invert(0.2);
}

.rating-dropdown-dice .d20-icon.filled img {
  filter: brightness(1.1) invert(0.7) sepia(1) saturate(6) hue-rotate(5deg) drop-shadow(0 0 5px rgba(218, 165, 32, 0.8));
}

.rating-dropdown-info {
  text-align: center;
  color: #6c757d;
  font-size: 0.85rem;
  margin-top: 12px;
}

.user-rating-info {
  margin-top: 6px;
  font-weight: 500;
  color: #3d5a80;
}

.remove-rating {
  cursor: pointer;
  color: #6c757d;
  font-size: 0.85rem;
  margin-left: 5px;
  font-weight: normal;
  transition: color 0.2s ease;
}

.remove-rating:hover {
  color: #dc3545;
  text-decoration: underline;
}

.rating-error {
  color: #dc3545;
  font-size: 0.85rem;
  margin: 10px 0;
  padding: 5px 10px;
  background-color: #f8d7da;
  border-radius: 4px;
  text-align: center;
}

@media (max-width: 768px) {
  .spell-rating-header {
    padding: 5px 10px;
  }

  .community-die-container {
    width: 20px !important;
    height: 20px !important;
  }

  .rating-dropdown {
    width: 200px;
    padding: 12px;
  }

  .rating-dropdown-header h3 {
    font-size: 0.9rem;
  }

  .rating-dropdown-dice .d20-icon {
    width: 24px;
    height: 24px;
  }
}

/* Rating popup styles */
.rating-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.rating-popup-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  padding: 20px;
  animation: popup-fade-in 0.3s ease;
}

@keyframes popup-fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.rating-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.rating-popup-header h3 {
  margin: 0;
  color: #3d5a80;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #6c757d;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.close-button:hover {
  color: #343a40;
}

.rating-popup-stars {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.rating-popup-stars .star-icon {
  margin: 0 5px;
  cursor: pointer;
}

.rating-popup-stars .star-icon:hover {
  transform: scale(1.1);
}

.rating-popup-info {
  text-align: center;
  color: #6c757d;
  font-size: 0.9rem;
  margin-top: 15px;
}

.user-rating-info {
  margin-top: 8px;
  font-weight: 500;
  color: #3d5a80;
}

.rating-error {
  color: #dc3545;
  font-size: 0.85rem;
  margin: 10px 0;
  padding: 5px 10px;
  background-color: #f8d7da;
  border-radius: 4px;
  text-align: center;
}

@media (max-width: 768px) {
  .rating-popup-content {
    padding: 15px;
  }

  .rating-popup-header h3 {
    font-size: 1.1rem;
  }

  .rating-popup-stars .star-icon {
    width: 28px;
    height: 28px;
  }
}

/* Enhance glow effect in the header */
.spell-rating-header .d20-icon.filled img {
  filter: brightness(1.2) invert(0.7) sepia(1) saturate(6) hue-rotate(5deg) drop-shadow(0 0 6px rgba(218, 165, 32, 0.9));
  opacity: 1;
}

/* Enhance glow effect in the header for half-filled dice */
.spell-rating-header .d20-icon.half-filled img {
  filter: brightness(1.1) invert(0.7) sepia(0.8) saturate(5) hue-rotate(5deg) drop-shadow(0 0 4px rgba(218, 165, 32, 0.7));
  opacity: 0.9;
  /* Apply a clip-path to create the half-filled effect */
  clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
  -webkit-clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
}