.confirmation-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.confirmation-popup {
  background-color: white;
  border-radius: 8px;
  border: 2px solid #3d5a80;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  font-family: 'Poppins', sans-serif;
  position: relative;
  padding: 10px;
}

.confirmation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
}

.confirmation-header h2 {
  margin: 0;
  color: #3d5a80;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  text-align: center;
  font-size: 1.5rem;
  flex: 1;
}

.confirmation-header .close-button {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: #3d5a80;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-header .close-button:hover {
  color: #2c4a6e;
}

.confirmation-header .close-button svg {
  width: 20px;
  height: 20px;
}

.confirmation-content {
  padding: 0 20px;
  margin-bottom: 20px;
}

.confirmation-content p {
  margin: 0;
  color: #000000;
  font-size: 0.95rem;
  line-height: 1.4;
  text-align: center;
}

.confirmation-actions {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 0 20px 10px;
}

.confirmation-actions button {
  padding: 8px 16px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 100px;
}

.confirmation-actions .confirm-button {
  background-color: #ef4444;
  color: white;
  border: none;
}

.confirmation-actions .confirm-button:hover {
  background-color: #dc2626;
}

.confirmation-actions .confirm-button.confirm-button-blue {
  background-color: #3d5a80;
}

.confirmation-actions .confirm-button.confirm-button-blue:hover {
  background-color: #2c4a6e;
}

.confirmation-actions .cancel-button {
  background-color: #f8fafc;
  color: #3d5a80;
  border: 1px solid #3d5a80;
}

.confirmation-actions .cancel-button:hover {
  background-color: #e5e7eb;
}

@media (max-width: 640px) {
  .confirmation-popup {
    width: 95%;
    margin: 0 10px;
  }

  .confirmation-actions {
    flex-direction: column;
    gap: 10px;
  }

  .confirmation-actions button {
    width: 100%;
  }
}
