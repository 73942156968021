.all-filters-tags-container {
  margin: 8px 0;
  padding: 6px 10px;
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 6px;
  font-family: 'Poppins', sans-serif;
}

.filters-tags-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  font-size: 0.75rem;
  color: #3d5a80;
  font-weight: 600;
}

.clear-filters-button {
  background: none;
  border: none;
  color: #e63946;
  font-size: 0.7rem;
  cursor: pointer;
  padding: 2px 5px;
  font-family: 'Poppins', sans-serif;
  transition: all 0.2s;
}

.clear-filters-button:hover {
  text-decoration: underline;
}

.filters-tags-content {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.filter-tag-group {
  display: flex;
  align-items: center;
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  padding: 2px 6px;
  margin-right: 5px;
}

.filter-type-label {
  font-size: 0.7rem;
  font-weight: 600;
  color: #3d5a80;
  margin-right: 5px;
  font-family: 'Poppins', sans-serif;
}

.filter-tag-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}

.filter-tag {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 0.7rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s;
  color: white;
}

.filter-tag.include {
  background-color: #3d5a80;
}

.filter-tag.include:hover {
  background-color: #2c4a6e;
}

.filter-tag.exclude {
  background-color: #e74c3c;
}

.filter-tag.exclude:hover {
  background-color: #c0392b;
}

.filter-value {
  margin-right: 3px;
}

.remove-filter {
  background: none;
  border: none;
  color: white;
  margin-left: 1px;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 0 0 0 1px;
}

.remove-filter:hover {
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .all-filters-tags-container {
    padding: 5px 8px;
  }
  
  .filters-tags-header {
    font-size: 0.7rem;
  }
  
  .filter-tag {
    font-size: 0.65rem;
    padding: 2px 5px;
  }
} 