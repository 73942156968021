@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Metrophobic';
  src: url('../../assets/fonts/Metrophobic/Metrophobic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mondia-Light';
  src: url('../../assets/fonts/Mondia-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Add this import at the top of the file */
@font-face {
  font-family: 'Metrophobic';
  src: url('../../assets/fonts/Metrophobic/Metrophobic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Add the font-face declaration if not already present */
@font-face {
  font-family: 'Mondia-Light';
  src: url('../../assets/fonts/Mondia Light/Mondia Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Add these styles at the top of the file */
.spell-list-header {
  position: relative;
  width: 100%;
  height: 25vh;
  min-height: 180px;
  max-height: 250px;
  margin: -20px 0 20px 0;
  overflow: hidden;
}

.spell-list-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../../assets/images/dice-hero1.jpg') center/cover no-repeat;
  background-position: center 25%; /* Position the image to show the top 25% */
  opacity: 1;
  z-index: 0;
}

.spell-list-header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(30, 44, 64, 0.95) 0%,
    rgba(30, 44, 64, 0.8) 40%,
    rgba(30, 44, 64, 0.2) 100%
  );
  z-index: 1;
}

.spell-list-header-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.spell-list-header h1 {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
  text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
}

.spell-list-header p {
  color: #E0FBFC;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}

.spell-list {
  margin: 0 auto;
  padding: 0; /* No padding here */
  overflow-x: auto; /* Allow horizontal scrolling on mobile */
  max-width: 100%; /* Use full width of container */
  width: 100%;
  box-sizing: border-box;
}

.spell-list table {
  width: 100%; /* Full width */
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #98C1D9; 
  border-radius: 4px;
  overflow: hidden;
  table-layout: fixed; /* This ensures that the column widths are respected */
  font-size: 0.85rem;
  empty-cells: show;
  margin: 0; /* No margin */
}

.spell-list th,
.spell-list td {
  padding: 6px 8px; /* Reduced padding */
  border-bottom: none; /* Remove bottom border */
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Remove this style as we no longer need a special border for the header row */
/*
.spell-list thead tr {
  border-bottom: 2px solid #ddd;
}
*/

/* Column widths */
.spell-list th:nth-child(1),
.spell-list td:nth-child(1) {
  width: 20px; /* Reduced from 30px to 20px */
}

.spell-list th:nth-child(2),
.spell-list td:nth-child(2) {
  width: 230px; /* Increased from 220px to 230px */
}

.spell-list th:nth-child(3),
.spell-list td:nth-child(3) {
  width: 40px; /* Reduced from 50px to 40px (Level column) */
}

.spell-list th:nth-child(4),
.spell-list td:nth-child(4) {
  width: 90px; /* Increased from 80px to 90px (School column) */
}

.spell-list th:nth-child(5),
.spell-list td:nth-child(5) {
  width: 90px; /* Decreased from 100px to 90px (Components column) */
}

.spell-list th:nth-child(6),
.spell-list td:nth-child(6) {
  width: 110px; /* Unchanged (Casting Time column) */
}

.spell-list th:nth-child(7),
.spell-list td:nth-child(7) {
  width: 100px; /* Unchanged (Range column) */
}

.spell-list th:nth-child(8),
.spell-list td:nth-child(8) {
  width: 110px; /* Unchanged (Duration column) */
}

.spell-list th:nth-child(9),
.spell-list td:nth-child(9) {
  width: 110px; /* Increased from 90px to 110px (Concentration column) */
}

.spell-list th:nth-child(10),
.spell-list td:nth-child(10) {
  width: 50px; /* Reduced from 60px to 50px (Ritual column) */
}

.spell-list thead {
  background-color: #f8fafc;
  border-bottom: 1px solid #98C1D9; /* Add a bottom border to the thead */
}

.spell-list th {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #3d5a80;
  font-weight: bold;
}

.spell-list th:hover {
  background-color: #e2eef9;
}

.spell-list th:last-child {
  border-right: none; /* Ensure the last header cell has no right border */
}

.spell-list td {
  border-bottom: none; /* Remove bottom border */
  border-right: none; /* Remove right border from data cells */
  padding: 5px 8px; /* Increased from 4px to 5px */
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.8rem; /* Reduced from 0.85rem */
}

/* Spell name cell specific styling - override the font */
.spell-list td:nth-child(2) {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

/* Add this new style to remove borders from the checkbox column */
.spell-list th:first-child,
.spell-list td:first-child {
  border-right: none;
  border-left: none;
}

.spell-list tr:nth-child(even) {
  background-color: #f8fafc; /* Very pale blue background for even rows */
}

.spell-list tr:hover {
  background-color: #d9ebff; /* Slightly darker blue on hover */
}

.spell-list a {
  color: #1a4b8c; /* Darker blue */
  text-decoration: none;
  font-weight: 600; /* Make text bold */
  font-size: 0.875rem;
}

.spell-list a:hover {
  text-decoration: underline;
}

.spell-list input[type="checkbox"] {
  cursor: pointer;
  width: 14px; /* Smaller checkbox */
  height: 14px; /* Smaller checkbox */
  vertical-align: middle;
}

/* Center the checkbox input */
.spell-list th:first-child input[type="checkbox"],
.spell-list td:first-child input[type="checkbox"] {
  margin: 0 auto;
  display: block;
  width: 14px;
  height: 14px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 6px;
}

.filter-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 120px;
}

.filter-group select {
  padding: 6px 10px;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;
  color: #3d5a80;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 16px auto;
}

/* Add specific styles for ritual, level, and class dropdowns */
.filter-group select[name="ritual"],
.filter-group select[name="level"],
.filter-group select[name="class"] {
  min-width: 60px;
}

/* Add new styles for concentration dropdown */
.filter-group select[name="concentration"] {
  min-width: 140px; /* Increased from 120px to 140px */
}

/* Keep ritual dropdown at its current size */
.filter-group select[name="ritual"] {
  min-width: 80px; /* Changed from 100px to 80px */
}

.filter-group select:hover {
  border-color: #2c4a6e;
}

.filter-group select:focus {
  outline: none;
  border-color: #2c4a6e;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

.filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.filter-tag-group {
  display: flex;
  align-items: center;
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  padding: 2px 6px;
  margin-right: 5px;
}

.filter-type-label {
  font-size: 0.75rem;
  font-weight: 600;
  color: #3d5a80;
  margin-right: 5px;
  font-family: 'Poppins', sans-serif;
}

.filter-tag-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}

.filter-tag {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #EE6C4D;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.75rem; /* Reduced from 0.8em to 0.75rem */
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s;
}

.filter-tag:hover {
  background-color: #D95C3E; /* Darker shade of #EE6C4D for hover effect */
}

.filter-tag button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  line-height: 0.8;
  color: white; /* Changed from #666 to white */
  margin-left: 5px;
  padding: 0 3px;
}

.filter-tag button:hover {
  color: #FFD7CE; /* Lighter shade for hover effect on the close button */
}

.clear-filters {
  margin-left: auto;
  padding: 5px 10px;
  background-color: #3d5a80;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 0.9em;
  transition: all 0.3s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.clear-filters:hover {
  background-color: #2c4a6e; /* Darker shade of blue for hover effect */
}

.conversion-checkbox {
  margin-bottom: 10px;
  text-align: right;
}

.conversion-checkbox label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.conversion-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.search-and-conversion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 10px;
}

.search-and-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
}

.search-counter-group {
  display: flex;
  align-items: center;
  gap: 30px;
  flex: 1;
}

.search-bar {
  flex-grow: 1;
}

.spell-counter {
  flex-shrink: 0;
  background-color: transparent !important;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 10px 0 12px !important;
  justify-content: space-between;
  align-items: center;
}

/* Create a wrapper for the checkboxes */
.checkbox-group {
  display: flex;
  gap: 20px;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-family: 'Poppins', sans-serif;
  color: #4b5563;
  cursor: pointer;
  white-space: nowrap;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 5px;
}

/* Stack checkboxes on smaller screens with adjusted spacing */
@media (max-width: 768px) {
  .checkbox-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .checkbox-group {
    flex-direction: column;
    gap: 8px;
  }

  .create-custom-spell-button {
    margin-top: 8px;
    width: 100%;
  }
}

.floating-buttons {
  position: fixed;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.98);
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid #98c1d9;
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
}

.floating-close-button {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #3d5a80;
  color: white;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  z-index: 1001;
}

.floating-close-button:hover {
  background-color: #2c4a6e;
  transform: scale(1.1);
}

.floating-button {
  background-color: #3d5a80;
  border: 1px solid #98c1d9;
  color: white;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  white-space: nowrap;
  text-align: center;
  min-width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.floating-button:hover {
  background-color: #2c4a6e;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.floating-add-button {
  background-color: #3d5a80;
  color: white;
  border: 1px solid #98c1d9;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  white-space: nowrap;
  text-align: center;
  min-width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.floating-add-button:hover {
  background-color: #2c4a6e;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.generate-cards-button {
  background-color: #edf3f8;  /* Pale blue background */
  border: 1px solid #3d5a80;
  color: #3d5a80;
}

.generate-cards-button:hover {
  background-color: #dde9f3;  /* Slightly darker pale blue on hover */
}

.spell-list .checkbox-cell {
  width: 30px;
  text-align: center;
  vertical-align: middle;
}

.spell-list .checkbox-cell input[type="checkbox"] {
  margin: 0;
  vertical-align: middle;
}

.spell-name-cell {
  position: relative;
  padding-right: 55px;
}

.info-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-to-spellbook-icon {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Ensure the SVGs fill their container */
.info-icon svg,
.add-to-spellbook-icon svg {
  width: 100%;
  height: 100%;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff; /* White background */
  border: 1px solid #e0e8f5; /* Very light blue border */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
}

.back-to-top:hover {
  background-color: #f0f4f8; /* Light blue background on hover */
  border-color: #3d5a80; /* Darker blue border on hover */
}

.back-to-top svg {
  width: 28px;
  height: 28px;
  /* The fill color is already set in the SVG itself */
}

.spell-list tbody tr.spell-row {
  cursor: pointer;
  border-top: 1px solid #e5e7eb; /* Add a light gray border on top of spell rows */
}

.spell-list tbody tr.spell-row:first-child {
  border-top: none; /* Remove the top border for the first spell row */
}

.spell-list tbody tr.description-row {
  border-top: none; /* Ensure no border on top of description rows */
}

.spell-list tbody tr.spell-row:hover {
  background-color: #d9ebff;
}

/* Remove or comment out these styles */
/*
.spell-list tbody tr.spell-row.expanded {
  background-color: #d9ebff;
}

.spell-list tbody tr.description-row {
  background-color: inherit;
}
*/

/* Update this style to keep the background color consistent */
.spell-list tbody tr.description-row td {
  padding: 5px 10px;
  border-top: none;
  color: #333;
  font-size: 0.95em;
  line-height: 1.4;
  white-space: normal;
}

/* Remove or comment out this style */
/*
.spell-list tbody tr.spell-row:hover,
.spell-list tbody tr.spell-row.expanded:hover,
.spell-list tbody tr.spell-row.expanded + tr.description-row:hover {
  background-color: #d9ebff;
}
*/

.short-description-checkbox {
  margin-top: 5px;
}

.spell-list th {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #3d5a80;
  font-weight: bold;
}

.spell-list th:hover {
  background-color: #e2eef9;
}

/* Remove the hover effect for the entire header row */
.spell-list thead tr:hover {
  background-color: inherit;
}

/* Add this new style for the expanded spell row */
.spell-list tbody tr.spell-row.expanded td:not(:first-child):not(:nth-child(2)) {
  color: #666;
}

.spell-list tbody tr.spell-row.expanded {
  background-color: #f8fafc; /* Lighter background */
}

.spell-list tbody tr.spell-row.expanded .short-description {
  text-align: left;
  padding: 10px;
  font-size: 0.95em;
  line-height: 1.3;
  color: #000000 !important;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.spell-list tbody tr.spell-properties-row {
  background-color: #f8fafc;
  font-size: 0.8em; /* Reduced from 0.85em */
  color: #4a4a4a; /* Darker color for less faded look */
}

.spell-list tbody tr.spell-properties-row td {
  padding: 3px 8px; /* Reduced vertical padding */
  opacity: 0.9; /* Increased opacity for less faded effect */
}

.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3); /* Changed from 0.4 to 0.3 for even less darkness */
  backdrop-filter: blur(5px);
  z-index: 999;
}

/* Adjust the z-index of the SpellInfoBox to be above the overlay */
.spell-info-box {
  z-index: 1000;
}

/* Update this existing style */
.spell-list thead {
  border: 1px solid #98C1D9; /* Changed from #3d5a80 to #98C1D9 */
  border-bottom: none; /* Remove bottom border as it will be added to th elements */
  border-radius: 4px 4px 0 0; /* Add rounded corners to the top */
  overflow: hidden; /* Ensure the rounded corners are visible */
}

.spell-list th:first-child {
  border-top-left-radius: 4px; /* Round the top-left corner of the first header cell */
}

.spell-list th:last-child {
  border-top-right-radius: 4px; /* Round the top-right corner of the last header cell */
}

.spell-list th {
  /* ... existing styles ... */
  border-bottom: 1px solid #98C1D9; /* Changed from #3d5a80 to #98C1D9 */
}

/* Remove this style if it exists */
/* .spell-list thead::after {
  content: '';
  display: block;
  height: 2px;
  background-color: #e5e7eb;
  margin-top: -2px;
} */

/* Add this new style to ensure consistent appearance when table is empty */
.spell-list tbody:empty {
  display: none; /* Hide empty tbody */
}

.spell-list tbody:empty + tfoot::before {
  content: '';
  display: table-row;
  height: 1px; /* Minimal height to prevent collapsing */
}

/* Add this to each of the CSS files */
.empty-table-row td {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #6b7280;
  background-color: #ffffff; /* Changed from #f9fafb to white */
}

/* Add these new styles */
.spell-list td:nth-child(3),  /* Level column */
.spell-list td:nth-child(9),  /* Concentration column */
.spell-list td:nth-child(10) { /* Ritual column */
  text-align: center;
}

/* Center the headers for these columns as well */
.spell-list th:nth-child(3),
.spell-list th:nth-child(9),
.spell-list th:nth-child(10) {
  text-align: center;
}

/* Update this existing style */
.spell-list tbody tr.spell-properties-row {
  background-color: #f8fafc;
  font-size: 0.8em;
  color: #4a4a4a;
}

.spell-list tbody tr.spell-properties-row td {
  padding: 3px 8px 1px; /* Reduced bottom padding */
  opacity: 0.9;
  vertical-align: top; /* Align content to the top */
}

/* Add this new style for the expanded row */
.spell-list tbody tr.spell-row.expanded td {
  padding-bottom: 1px; /* Reduce bottom padding */
}

.spell-list tbody tr.spell-row.expanded + tr.spell-properties-row td {
  padding-top: 1px; /* Reduce top padding */
}

/* Add media queries to hide columns based on screen width, excluding the level column */
@media (max-width: 1200px) {
  .spell-list th:nth-child(10),
  .spell-list td:nth-child(10) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .spell-list th:nth-child(9),
  .spell-list td:nth-child(9) {
    display: none;
  }
}

@media (max-width: 1000px) {
  .spell-list th:nth-child(8),
  .spell-list td:nth-child(8) {
    display: none;
  }
}

@media (max-width: 900px) {
  .spell-list th:nth-child(7),
  .spell-list td:nth-child(7) {
    display: none;
  }
}

@media (max-width: 800px) {
  .spell-list th:nth-child(6),
  .spell-list td:nth-child(6) {
    display: none;
  }
}

@media (max-width: 700px) {
  .spell-list th:nth-child(5),
  .spell-list td:nth-child(5) {
    display: none;
  }
}

@media (max-width: 600px) {
  .spell-list th:nth-child(4),
  .spell-list td:nth-child(4) {
    display: none;
  }
}

/* Ensure the level column (nth-child(3)) is not hidden */

/* Update the spell-name-cell class for SpellList */
.spell-list .spell-name-cell {
  position: relative;
  padding-right: 55px; /* Adjusted for two icons */
}

/* Position icons specifically for SpellList */
.spell-list .info-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.spell-list .add-to-spellbook-icon {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Add responsive styles */
@media (max-width: 768px) {
  .spell-list-header {
    min-height: 150px;
  }

  .spell-list-header-content {
    padding: 1.5rem 2rem;
  }

  .spell-list-header h1 {
    font-size: 2rem;
  }

  .spell-list-header p {
    font-size: 1rem;
  }

  .floating-buttons {
    width: 90%;
    max-width: 300px;
  }

  .floating-button {
    min-width: unset;
    width: 100%;
  }
}

/* Add media queries for responsive padding */
@media (max-width: 768px) {
  .spell-list {
    padding: 0 5px 20px;
  }
  
  .spell-table-container {
    margin: 0 -5px;
    padding: 0 5px;
  }
}

@media (max-width: 480px) {
  .spell-list {
    padding: 0 3px 20px;
  }
  
  .spell-table-container {
    margin: 0 -3px;
    padding: 0 3px;
  }
}

/* Update the add-to-spellbook-icon styles */
.add-to-spellbook-icon svg {
  width: 100%;
  height: 100%;
  fill: #EE6C4D; /* Bright orange color to match the theme */
}

.add-to-spellbook-icon:hover svg {
  fill: #e85a3a; /* Slightly darker orange on hover */
}

/* Update the select options (this will only work in Firefox) */
.filter-group select option {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.85em;
}

.create-custom-spell-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.create-custom-spell-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.1);
  border-color: #2c4a6e;
}

.create-custom-spell-button svg {
  width: 24px;
  height: 24px;
  color: #3d5a80;
}

.create-custom-spell-button:hover svg {
  color: #2c4a6e;
}

/* Update table controls to accommodate both buttons */
.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-controls-left {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.table-controls-right {
  display: flex;
  align-items: center;
}

.reset-columns-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.reset-columns-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.1);
  border-color: #2c4a6e;
}

.reset-columns-button svg {
  width: 24px;
  height: 24px;
  color: #3d5a80;
}

.reset-columns-button:hover svg {
  color: #2c4a6e;
}

/* Add responsive adjustments for the table */
@media (max-width: 768px) {
  .spell-list {
    margin: 0;
    padding: 0;
  }
  
  .spell-list table {
    font-size: 0.8rem; /* Slightly smaller font on mobile */
  }
}
