.spell-resource-manager {
  margin-bottom: 4px;
  max-width: 100%;
  overflow-x: hidden;
}

.system-toggle-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding: 8px;
}

.toggle-labels {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: #4b5563;
}

.toggle-labels span {
  cursor: pointer;
  transition: color 0.3s ease;
  color: #9ca3af;
}

.toggle-labels span.active {
  color: #3d5a80;
  font-weight: 600;
}

.toggle-switch {
  position: relative;
  width: 50px;
  height: 24px;
  background-color: #e5e7eb;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #98C1D9;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.toggle-switch:hover {
  background-color: #d1d5db;
}

.toggle-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: #3d5a80;
  border-radius: 50%;
  transition: transform 0.3s ease;
  border: 1px solid #2c4a6e;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.toggle-slider.points {
  transform: translateX(26px);
}

@media (max-width: 768px) {
  .toggle-labels {
    font-size: 0.8rem;
  }
} 