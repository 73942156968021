.spell-slot-manager {
  background-color: #f8fafc;
  border: 1px solid #98C1D9;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 16px;
  font-family: 'Poppins', sans-serif;
  max-width: 100%; /* Prevent overflow */
  box-sizing: border-box; /* Include padding in width calculation */
}

.spell-slot-manager h3 {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #3d5a80;
  margin: -4px 0 16px 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
}

.spell-slot-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Increased from 12px to 16px */
  justify-content: center;
  width: 100%;
  max-width: 100%; /* Ensure content doesn't overflow */
  box-sizing: border-box; /* Include padding in width calculation */
}

.spell-slot-level {
  background-color: white;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  padding: 6px;
  width: calc(9.5% - 16px); /* Adjusted to wider than 8% but narrower than 11.11% */
  min-width: 80px;
  box-sizing: border-box; /* Include padding in width calculation */
}

/* New style for containers with 0 spell slots */
.spell-slot-level.empty {
  border-color: #c0d6e4;
}

.spell-slot-level h4 {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  color: #3d5a80;
  margin: 0 0 2px 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.spell-slot-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 130px;
}

.spell-slot-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.spell-slot-usage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin-top: 10px; /* Increased from 4px to 8px */
  font-size: 0.65rem;
}

.spell-slot-usage span {
  font-weight: 600;
  color: #4b5563;
}

.spell-slot-usage button {
  width: 100%;
  padding: 2px 4px;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.65rem;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s ease;
}

.spell-slot-usage button:first-of-type {
  background-color: #3d5a80;
}

.spell-slot-usage button:first-of-type:hover {
  background-color: #2c4a6e;
}

.spell-slot-usage button:first-of-type:disabled {
  background-color: #a8c0d8; /* Lighter shade of #3d5a80 */
  cursor: not-allowed;
}

.spell-slot-usage button:nth-of-type(2) {
  background-color: #98C1D9;
  color: #2c4a6e; /* Added darker text color for better contrast */
}

.spell-slot-usage button:nth-of-type(2):hover {
  background-color: #7ba9c7;
}

.spell-slot-usage button:nth-of-type(2):disabled {
  background-color: #cce0ec;
  color: #5d7fa3; /* Added slightly lighter but still readable color when disabled */
  cursor: not-allowed;
}

.spell-slot-usage button:last-child {
  background-color: white;
  color: #EE6C4D;
  border: 1px solid #EE6C4D;
}

.spell-slot-usage button:last-child:hover {
  background-color: #fff3f0;
}

.spell-slot-usage button:last-child:disabled {
  color: #f7b4a3; /* Lighter shade of #EE6C4D */
  border-color: #f7b4a3;
  background-color: #fff;
  cursor: not-allowed;
}

input[type="number"] {
  width: 30px;
  height: 24px;
  padding: 2px 4px;
  text-align: center;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #d1d5db;
  border-radius: 3px;
  color: #4b5563;
  -moz-appearance: textfield;
}

/* Remove spinner for Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.spell-slot-visual {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 4px;
  margin-left: 6px;
  height: 100%;
}

.spell-slot-block {
  width: 16px;
  height: 10px;
  background-color: #e6f2ff;
  border: 1px solid #3d5a80;
  border-radius: 3px;
  box-sizing: border-box;
}

.spell-slot-block.used {
  background-color: #3d5a80;
  border-color: #3d5a80;
}

.spell-slot-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
  text-align: center;
  position: relative;
}

.reset-all-button {
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #3d5a80;
  color: white;
  border: none;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-all-button:hover {
  background-color: #2c4a6e;
}

.reset-all-button svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: white;
}

/* Update the spellbook name style */
.spell-slot-manager .spellbook-name {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #3d5a80;
  font-size: 1.1rem;
}

/* Update these styles */
.footer-left {
  justify-self: start;
}

.show-everywhere-checkbox {
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;
  color: #4b5563;
  cursor: pointer;
}

.show-everywhere-checkbox input[type="checkbox"] {
  margin: 0;
}

.spell-slot-header {
  display: grid;
  grid-template-columns: 200px 1fr 200px; /* Match toggle width on both sides */
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  max-width: 100%; /* Ensure content doesn't overflow */
}

.spell-slot-header h3 {
  margin: 0;
  grid-column: 2;
  text-align: center;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #3d5a80;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.manager-toggles {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 8px;
}

.system-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;
  color: #4b5563;
  cursor: pointer;
}

.system-toggle input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

@media (max-width: 768px) {
  .manager-toggles {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  
  .spell-slot-header {
    grid-template-columns: 1fr; /* Stack header elements on small screens */
    gap: 10px;
  }
  
  .spell-slot-header h3 {
    grid-column: 1;
    order: 1;
  }
  
  .system-toggle-container {
    grid-column: 1;
    order: 2;
    justify-self: center;
  }
}

.system-toggle-container {
  grid-column: 3;
  min-width: 200px;
  justify-self: end;
}

/* Add the pin button styles */
.pin-button {
  position: absolute;
  right: 12px;
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #4b5563;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.pin-button:hover {
  background-color: #f3f4f6;
  color: #3d5a80;
}

.pin-button.pinned {
  color: #3d5a80;
}

.pin-button svg {
  transition: transform 0.2s ease;
}

.pin-button:hover svg {
  transform: scale(1.1);
}

@media (max-width: 1200px) {
  .spell-slot-level {
    width: calc(10% - 16px); /* Adjusted back to original responsive widths */
  }
}

@media (max-width: 992px) {
  .spell-slot-level {
    width: calc(12.5% - 16px); /* Adjusted back to original responsive widths */
  }
}

@media (max-width: 768px) {
  .spell-slot-level {
    width: calc(16.66% - 16px); /* Keep this responsive width */
  }
}

@media (max-width: 576px) {
  .spell-slot-level {
    width: calc(20% - 16px); /* 5 per row */
  }
}

@media (max-width: 400px) {
  .spell-slot-level {
    width: calc(25% - 12px); /* 4 per row with smaller gap */
  }
  
  .spell-slot-container {
    gap: 12px; /* Smaller gap on very small screens */
  }
}
