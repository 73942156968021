/* CUSTOM SPELL FORM SPECIFIC */
.customspell-overlay {
  background-color: rgba(0, 0, 0, 0.75);  /* Just a semi-transparent dark background */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;
}

.custom-spell-form {
  background: white;
  border-radius: 12px;
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  z-index: 1001;
  pointer-events: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  animation: form-popup-appear 0.3s ease-out;
}

/* Add the animation keyframes */
@keyframes form-popup-appear {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.custom-spell-form .generator-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.25rem;
  background-color: #3d5a80;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}

.custom-spell-form form {
  max-width: 560px;
  margin: 0 auto;
  padding: 20px;
}

.custom-spell-form .generator-header h2 {
  color: white;
  margin: 0;
  font-family: 'Mondia Light', sans-serif;
  font-size: 1.6rem;
  text-align: left;
  padding-right: 30px;
}

.custom-spell-form .close-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-spell-form .close-button:hover {
  color: #f0f0f0;
}

.custom-spell-form .close-button svg {
  width: 20px;
  height: 20px;
}

.custom-spell-form h2 {
  color: #3d5a80;
  margin-bottom: 20px;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  text-align: center;
  font-size: 1.5rem;
}

.custom-spell-form .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.custom-spell-form .form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.custom-spell-form .form-row .form-group {
  margin-bottom: 0;
  flex: 1;
  min-width: 100px;
}

.custom-spell-form .form-group.name-group {
  max-width: 400px;
}

.custom-spell-form .form-group.short-input {
  max-width: 200px;
}

.custom-spell-form .form-row.short-inputs {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.custom-spell-form .form-row.short-inputs .form-group {
  flex: 1;
  min-width: 150px;
  max-width: 200px;
}

.custom-spell-form .form-group label {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 5px;
  color: #000000;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
}

.custom-spell-form .form-group input,
.custom-spell-form .form-group select {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85em;
  color: #3d5a80;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: 36px;
}

.custom-spell-form .form-group select {
  padding-right: 24px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 16px auto;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.custom-spell-form .form-group input:focus,
.custom-spell-form .form-group select:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

.custom-spell-form .components-group,
.custom-spell-form .classes-group,
.custom-spell-form .spell-properties {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
  padding: 10px;
  background-color: #f8fafc;
  border-radius: 4px;
  border: 1px solid #98c1d9;
}

.custom-spell-form .components-group label,
.custom-spell-form .classes-group label,
.custom-spell-form .spell-properties label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 15px;
  color: #000000;
  font-size: 0.9rem;
}

.custom-spell-form .material-input {
  margin-top: 10px;
}

.custom-spell-form .form-actions {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  justify-content: flex-end;
}

.custom-spell-form .form-actions button {
  padding: 8px 16px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-spell-form .form-actions button[type="submit"] {
  background-color: #3d5a80;
  color: white;
  border: none;
}

.custom-spell-form .form-actions button[type="submit"]:hover {
  background-color: #2c4a6e;
}

.custom-spell-form .form-actions button[type="button"] {
  background-color: #f8fafc;
  color: #3d5a80;
  border: 1px solid #3d5a80;
}

.custom-spell-form .form-actions button[type="button"]:hover {
  background-color: #e5e7eb;
}

.custom-spell-form .char-limit {
  font-size: 0.8em;
  color: #666;
  margin-left: 4px;
}

/* Add scrollbar styling */
.custom-spell-form::-webkit-scrollbar {
  width: 8px;
}

.custom-spell-form::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.custom-spell-form::-webkit-scrollbar-thumb {
  background: #98c1d9;
  border-radius: 4px;
}

.custom-spell-form::-webkit-scrollbar-thumb:hover {
  background: #3d5a80;
}

/* Add responsive styles */
@media (max-width: 640px) {
  .custom-spell-form {
    max-width: 95%;
    padding: 0;
    border-radius: 12px;
  }
  
  .custom-spell-form .generator-header {
    padding: 0.5rem 1rem;
    position: relative;
  }
  
  .custom-spell-form .generator-header h2 {
    font-size: 1.3rem;
    padding-right: 30px;
  }
  
  .custom-spell-form .close-button {
    right: 10px;
  }

  .custom-spell-form form {
    padding: 15px;
    margin: 0;
  }
  
  .custom-spell-form .form-actions {
    flex-direction: column;
    gap: 10px;
  }
  
  .custom-spell-form .form-actions button {
    width: 100%;
  }
  
  .custom-spell-form .form-row {
    flex-direction: column;
    gap: 15px;
  }
  
  .custom-spell-form .form-group.name-group {
    width: 100%;
  }
  
  .custom-spell-form .form-row .form-group.level-group {
    min-width: 100%;
    margin-right: 0;
  }
  
  .custom-spell-form .form-row .form-group.school-group {
    min-width: 100%;
  }
}

/* When approaching the limit */
.custom-spell-form .char-limit.near-limit {
  color: #f59e0b;
}

/* When at the limit */
.custom-spell-form .char-limit.at-limit {
  color: #dc2626;
}

.custom-spell-form .error-message {
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  color: #dc2626;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
}

.custom-spell-form .form-group input.readonly {
  background-color: #f3f4f6;
  cursor: not-allowed;
}

.login-required-message {
  text-align: center;
  padding: 30px 20px;
  color: #3d5a80;
}

.login-required-message p {
  margin-bottom: 20px;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.login-required-message button {
  background-color: #3d5a80;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  transition: all 0.2s ease;
}

.login-required-message button:hover {
  background-color: #2c4a6e;
}

.custom-spell-form .form-group textarea {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85em;
  color: #3d5a80;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  resize: vertical; /* Only allow vertical resizing */
  min-height: 120px; /* Increased height for description field */
}

.custom-spell-form .form-row .form-group.level-group {
  flex: 0;
  min-width: 90px; /* Just enough for "cantrip" */
  margin-right: 30px; /* Increased from 20px to 30px for more spacing */
}

.custom-spell-form .form-row .form-group.school-group {
  flex: 0;
  min-width: 120px; /* Enough for "transmutation" */
}

.custom-spell-form .components-group input[type="checkbox"],
.custom-spell-form .classes-group input[type="checkbox"],
.custom-spell-form .spell-properties input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  margin: 0;
  border: 1px solid #9ca3af;
  border-radius: 3px;
  background-color: white;
  position: relative;
  cursor: pointer;
  outline: none;
}

.custom-spell-form .components-group input[type="checkbox"]:checked,
.custom-spell-form .classes-group input[type="checkbox"]:checked,
.custom-spell-form .spell-properties input[type="checkbox"]:checked {
  background-color: white;
}

.custom-spell-form .components-group input[type="checkbox"]:checked::before,
.custom-spell-form .classes-group input[type="checkbox"]:checked::before,
.custom-spell-form .spell-properties input[type="checkbox"]:checked::before {
  content: '\2714'; /* Unicode character for a checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: black;
}

.custom-spell-form .components-group input[type="checkbox"]:hover,
.custom-spell-form .classes-group input[type="checkbox"]:hover,
.custom-spell-form .spell-properties input[type="checkbox"]:hover {
  border-color: #6b7280;
}

.custom-spell-form .form-group textarea:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

.custom-spell-form .form-group textarea.short-description {
  min-height: 60px; /* Increased from 36px to 60px for about 2 rows */
  height: 60px; /* Fixed height for 2 rows */
  resize: none; /* Prevent resizing */
}