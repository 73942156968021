@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* Add these new styles at the beginning of the file */
.login-container {
  position: relative;
}

.login-crow-image {
  position: absolute;
  top: -85px; /* Changed from -40px to -60px */
  left: -40px;
  width: 80px;
  height: auto;
  z-index: 1;
  filter: brightness(0) saturate(100%) invert(67%) sepia(16%) saturate(638%) hue-rotate(164deg) brightness(91%) contrast(85%);
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 70px);
  background-color: #f0f4f8;
  padding: 20px;  /* Add padding for mobile views */
}

.login-container {
  width: 100%;
  max-width: 350px;  /* Reduced from 400px */
  padding: 30px;  /* Reduced from 40px */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #3D5A80;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 24px;
  color: #1e3a5f;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  color: #4a5568;
  font-size: 14px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;  /* Ensure padding is included in width */
}

.input-group input:focus {
  outline: none;
  border-color: #3D5A80;
}

.submit-btn,
.switch-mode-btn {
  width: 100%;
  max-width: 250px;  /* Limit the width of buttons */
  margin: 0 auto;  /* Center the buttons */
  display: block;  /* Ensure buttons take full width up to max-width */
}

.submit-btn {
  padding: 12px;
  background-color: #3D5A80;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #2c4057;
}

.switch-mode {
  display: block;
  width: 100%;
  margin-top: 16px;
  background-color: transparent;
  border: none;
  color: #3D5A80;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.switch-mode:hover {
  color: #2c4057;
}

.error-message {
  background-color: #fed7d7;
  border: 1px solid #f56565;
  color: #c53030;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.password-strength {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
}

.password-strength.weak {
  color: #e53e3e;
}

.password-strength.medium {
  color: #dd6b20;
}

.password-strength.strong {
  color: #38a169;
}

.password-criteria {
  margin-top: 5px;
  font-size: 12px;
  color: #718096;
}

.auth-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.switch-mode-btn {
  background-color: #ffffff;
  color: #3D5A80;
  border: 1px solid #3D5A80;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 10px;
  width: 100%;
}

.switch-mode-btn:hover {
  background-color: #3D5A80;
  color: #ffffff;
}

.forgot-password-link {
  color: #3D5A80;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgot-password-link:hover {
  color: #EE6C4D;
  text-decoration: underline;
}

@media (max-width: 480px) {
  .login-container {
    padding: 20px;
  }

  .submit-btn,
  .switch-mode-btn {
    max-width: 100%;  /* Full width on very small screens */
  }
}

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 40px; /* Make room for the toggle button */
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.password-toggle svg {
  width: 20px;
  height: 20px;
  color: #3D5A80;
}

.resend-verification {
  margin-top: 10px;
}

.resend-btn {
  background: none;
  border: none;
  color: #3D5A80;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.resend-btn:disabled {
  color: #a0aec0;
  cursor: not-allowed;
}
