@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.spellinfo-overlay {
  background-color: rgba(0, 0, 0, 0.50);  /* Maintain 50% opacity */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;
  /* Explicitly remove blur */
  backdrop-filter: none !important;
  -webkit-backdrop-filter: none !important;
}

.spell-info-box {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  border: none;
  z-index: 1001;
  pointer-events: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.spell-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.25rem;
  background-color: #3d5a80;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.custom-spell-header {
  background-color: #5d4b90;
}

.spell-info-header h2 {
  margin: 0;
  font-family: 'Mondia Light', sans-serif;
  font-size: 1.6rem;
  color: white;
  padding-right: 30px;
  box-sizing: border-box;
}

.spell-info-header p {
  margin: 0;
  font-family: 'Mondia Light', sans-serif;
  font-size: 0.9rem;
  color: #f0f0f0;
  margin-top: 2px;
}

.spell-info-content {
  padding: 20px 30px 30px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
}

.spell-info-box__close-button {
  display: none;
}

.spell-info-box .close-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spell-info-box .close-button:hover {
  color: #f0f0f0;
}

.spell-info-box .close-button svg {
  width: 20px;
  height: 20px;
}

.spell-info-box__notes {
  margin-top: 1.5rem;
  padding-top: 0.75rem;
  border-top: 1px solid #e0e0e0;
  max-width: 450px;
}

.spell-info-box__notes h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
  font-size: 1rem;
  padding-bottom: 3px;
}

.spell-info-box__notes-content {
  padding: 0.75rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  min-height: 40px;
  white-space: pre-wrap;
  margin: 0.25rem 0;
  color: #333;
  font-size: 0.9rem;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .spell-info-box {
    width: 95%;
  }

  .spell-info-header {
    padding: 0.5rem 1rem;
  }

  .spell-info-header h2 {
    font-size: 1.3rem;
    padding-right: 30px;
  }

  .spell-info-content {
    padding: 15px;
  }

  .spell-info-box .close-button {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .spell-info-box > * {
    max-width: 100%;
    margin-right: 0;
  }

  .spell-info-box table {
    width: 100%;
    margin-right: 0;
  }
}
