.search-bar-container {
  position: relative;
  width: 200px; /* Set a fixed width to match the original size */
  margin-bottom: 10px;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  padding: 3px 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%; /* Make the search bar fill its container */
}

.search-bar input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9em;
  color: #3d5a80;
  background-color: transparent;
  width: 100%; /* Make the input fill the search bar */
  padding: 5px 0; /* Add some vertical padding */
}

.search-bar input::placeholder {
  color: #98c1d9;
}

.search-icon {
  width: 16px;
  height: 16px;
  fill: #3d5a80;
  margin-right: 5px;
  flex-shrink: 0; /* Prevent the icon from shrinking */
}

.clear-search {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
  color: #3d5a80; /* Match the border color */
}

.clear-search svg {
  width: 18px;
  height: 18px;
}

/* New style for the spell counter box */
.spell-counter-box {
  background-color: #98c1d9;
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 10px;
}
