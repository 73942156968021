.how-to-use-box {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: none;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  border: 1px solid #98C1D9;
  position: relative;
}

.how-to-use-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.how-to-use-question {
  padding: 0.6rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
}

.how-to-use-question h3 {
  color: #3D5A80;
  font-weight: 500;
  margin: 0;
  font-size: 1.1rem;
  padding-right: 2rem;
  font-family: 'Poppins', sans-serif;
  line-height: 1.2;
}

.how-to-use-icon {
  position: relative;
  width: 18px;
  height: 18px;
}

.how-to-use-icon::before,
.how-to-use-icon::after {
  content: '';
  position: absolute;
  background: #EE6C4D;
  transition: all 0.3s ease;
}

.how-to-use-icon::before {
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  transform: translateY(-50%);
}

.how-to-use-icon::after {
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  transform: translateX(-50%);
}

.how-to-use-box.active .how-to-use-icon::after {
  transform: translateX(-50%) rotate(90deg);
  opacity: 0;
}

.how-to-use-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.how-to-use-box.active .how-to-use-answer {
  max-height: 500px;
  padding: 0 1.5rem 1rem 1.5rem;
}

.how-to-use-answer ul {
  margin: 0;
  padding-left: 1.5rem;
  color: #293241;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  list-style-type: disc;
}

.how-to-use-answer li {
  margin-bottom: 0.6rem;
  line-height: 1.4;
  padding-left: 0.2rem;
  display: block;
  position: relative;
  word-wrap: break-word;
  hyphens: auto;
}

.how-to-use-answer li:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .how-to-use-question {
    padding: 0.6rem 1rem;
  }

  .how-to-use-question h3 {
    font-size: 1rem;
  }

  .how-to-use-box.active .how-to-use-answer {
    padding: 0 1rem 1rem 1rem;
  }
  
  .how-to-use-answer ul {
    padding-left: 1.2rem;
    font-size: 0.85rem;
  }
  
  .how-to-use-answer li {
    margin-bottom: 0.55rem;
    line-height: 1.35;
  }
}

/* Custom icon styling for all icons */
.instruction-with-icon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* Info icon styling */
.manual-info-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: 0 4px;
  vertical-align: text-bottom;
}

.manual-info-icon svg {
  width: 100%;
  height: 100%;
  color: #3D5A80; /* Blue */
}

/* Remove icon styling */
.manual-remove-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: 0 4px;
  vertical-align: text-bottom;
}

.manual-remove-icon svg {
  width: 100%;
  height: 100%;
  color: #D32F2F; /* Red */
}

/* Book icon styling for prepare spells */
.manual-book-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: 0 4px;
  vertical-align: text-bottom;
}

.manual-book-icon svg {
  width: 100%;
  height: 100%;
  color: #000000; /* Black */
}

/* Trash icon styling */
.manual-trash-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: 0 4px;
  vertical-align: text-bottom;
}

.manual-trash-icon svg {
  width: 100%;
  height: 100%;
  color: #D32F2F; /* Red */
}

/* Cast icon styling */
.manual-cast-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: 0 4px;
  vertical-align: text-bottom;
}

.manual-cast-icon svg {
  width: 100%;
  height: 100%;
  color: #FF6D00; /* Orange */
}

/* Original icon styling */
.icon-inline {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: 0 4px;
  vertical-align: text-bottom;
  position: relative;
}

.icon-inline svg {
  width: 100%;
  height: 100%;
}

/* Match specific icon colors to their button counterparts */
.info-icon svg {
  color: #3D5A80; /* Blue */
}

.add-icon svg {
  color: #2E7D32; /* Green */
}

.remove-icon svg {
  color: #D32F2F; /* Red */
}

.book-icon svg {
  color: #1565C0; /* Blue */
}

.trash-icon svg {
  color: #D32F2F; /* Red */
}

.cast-icon svg {
  color: #FF6D00; /* Orange */
}

/* Detailed how-to link styling */
.how-to-more-link {
  text-align: right;
  padding: 0.5rem 0 0 0;
  font-size: 0.85rem;
  border-top: 1px solid #e6eef2;
  margin-top: 0.5rem;
}

.how-to-more-link a {
  color: #3D5A80;
  text-decoration: none;
  font-style: italic;
  transition: color 0.2s ease;
}

.how-to-more-link a:hover {
  color: #EE6C4D;
  text-decoration: underline;
}

/* Hide any unwanted info icons */
.how-to-use-box > .info-icon,
.how-to-use-question > .info-icon,
.how-to-use-box > div > .info-icon:not(.icon-inline) {
  display: none !important;
}

/* Make sure inline icons in instructions are visible */
.how-to-use-answer .icon-inline.info-icon {
  display: inline-flex !important;
} 