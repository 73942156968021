/* Add these styles at the beginning of the file */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  background-color: #f7f9fc; /* Match the hero banner background color */
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 20px; /* Add some space below the NavBar */
  padding-bottom: 20px; /* Add some space above the Footer */
  /* Removed background color from here since it's now on .App */
  position: relative;
  z-index: 20; /* Higher than TabBar and PageHero but lower than modal overlays */
}

/* Remove the following .app-title style */
/*
.app-title {
  font-family: 'Metamorphous', cursive;
  margin-bottom: 0;
  padding: 10px 0;
  background-color: #f8f8f8;
  text-align: center;
  font-size: 2rem;
}
*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Remove or comment out any conflicting nav styles in App.css */
/*
nav {
  background-color: #f0f0f0;
  padding: 10px;
}
*/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none !important;
  backdrop-filter: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 5px 10px;
  cursor: pointer;
}

/* Remove the following styles from App.css */
.tab-navigation {
  /* ... */
}

.tab {
  /* ... */
}

.tab svg {
  /* ... */
}

.tab:hover {
  /* ... */
}

.tab.active {
  /* ... */
}

.tab.active svg {
  /* ... */
}

/* Style the table content area with a white background */
.route-content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  background-color: #ffffff; /* White background for the table area */
  padding: 10px; /* Reduced padding further */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(61, 90, 128, 0.2);
  box-sizing: border-box; /* Important to include border and padding in the width calculation */
}

.app-title {
  font-family: 'Poppins', sans-serif; /* Changed from 'Metamorphous' to match the navbar */
  margin: 0;
  padding: 15px 0;
  background-color: #ffffff; /* White background */
  text-align: center;
  font-size: 2rem;
  color: #1e3a5f; /* Dark blue color to match the navbar */
  /* Remove the following line */
  /* border-bottom: 1px solid #e0e0e0; */
}

/* Add this rule to apply consistent margins to the main content area */
.main-content {
  margin: 0 20px; /* Adjust the margin value as needed */
}

/* Ensure this rule is applied to the main container of Spell List and Prepared Spells pages */

/* Add these styles to App.css */
.spell-list-header {
  position: relative;
  width: 100%;
  height: 25vh;
  min-height: 180px;
  max-height: 250px;
  overflow: hidden;
}

.spell-list-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('./assets/images/dice-hero1.jpg') center/cover no-repeat;
  background-position: center 25%;
  opacity: 1;
  z-index: 0;
}

.spell-list-header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(30, 44, 64, 0.95) 0%,
    rgba(30, 44, 64, 0.8) 40%,
    rgba(30, 44, 64, 0.2) 100%
  );
  z-index: 1;
}

.spell-list-header-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.spell-list-header h1 {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
  text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.spell-list-header p {
  color: #E0FBFC;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

@media (max-width: 768px) {
  .spell-list-header {
    min-height: 150px;
  }

  .spell-list-header-content {
    padding: 1.5rem 2rem;
  }

  .spell-list-header h1 {
    font-size: 2rem;
  }

  .spell-list-header p {
    font-size: 1rem;
  }
}

/* Add these footer-specific styles to ensure proper display */
footer {
  position: relative;
  z-index: 10; /* Ensures footer appears above any background elements */
  width: 100%;
}

/* Responsive adjustment for the homepage */
@media (max-height: 800px) {
  .hero {
    height: 80vh; /* Reduce height on smaller screens */
    min-height: 500px;
  }
}

/* Responsive styles for the route-content */
@media (max-width: 768px) {
  .route-content {
    padding: 8px;
    border-radius: 6px;
  }
}

@media (max-width: 480px) {
  .route-content {
    padding: 5px;
    border-radius: 4px;
  }
}

/* Social Icons */
.social-icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
