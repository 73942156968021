@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.password-change-form {
  margin-top: 20px;
}

.password-change-form h3 {
  color: #3D5A80;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  color: #4a5568;
  font-size: 14px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.input-group input:focus {
  outline: none;
  border-color: #3D5A80;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #3D5A80;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #2c4057;
}

.error-message {
  background-color: #fed7d7;
  border: 1px solid #f56565;
  color: #c53030;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.success-message {
  background-color: #c6f6d5;
  border: 1px solid #48bb78;
  color: #2f855a;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 40px;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.password-toggle svg {
  width: 20px;
  height: 20px;
  color: #3D5A80;
}

.password-strength {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
}

.password-strength.weak {
  color: #e53e3e;
}

.password-strength.medium {
  color: #dd6b20;
}

.password-strength.strong {
  color: #38a169;
}

.password-criteria {
  margin-top: 5px;
  font-size: 12px;
  color: #718096;
}
