.how-to-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #4b5563;
  font-family: 'Poppins', sans-serif;
}

.how-to-container h1 {
  color: #3d5a80;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 2.5rem;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
}

/* New styles for the guide header */
.how-to-header {
  text-align: center;
  margin-bottom: 3rem;
}

.header-intro {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.1rem;
  line-height: 1.6;
}

/* Guide section styles */
.guide-section {
  margin-bottom: 3rem;
  background: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid #98c1d9;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.guide-section h2 {
  color: #3d5a80;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #98c1d9;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.8rem;
  text-align: center;
}

.guide-section h3 {
  color: #3d5a80;
  margin: 1.5rem 0 0.75rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.35rem;
}

.guide-section h4 {
  color: #3d5a80;
  margin: 1.2rem 0 0.6rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
}

.guide-intro {
  background-color: #f8fafc;
  padding: 1.25rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  border-left: 4px solid #3d5a80;
}

.guide-intro p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 0;
}

.guide-content {
  line-height: 1.6;
}

.guide-content p {
  margin-bottom: 1rem;
}

.guide-content ul {
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.guide-content li {
  margin-bottom: 0.5rem;
  line-height: 1.6;
}

.guide-content strong {
  color: #3d5a80;
}

.how-to-section {
  margin-bottom: 3rem;
  background: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid #98c1d9;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.how-to-section h2 {
  color: #3d5a80;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #3d5a80;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.8rem;
}

.feature-block {
  margin-bottom: 2rem;
}

.feature-block h3 {
  color: #3d5a80;
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.how-to-section ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.how-to-section li {
  margin-bottom: 0.5rem;
  line-height: 1.6;
  color: #4b5563;
}

.how-to-section p {
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #4b5563;
}

/* Coming Soon Styles */
.coming-soon-section {
  margin-bottom: 3rem;
  background: #ffffff;
  padding: 3rem 2rem;
  border-radius: 8px;
  border: 1px solid #98c1d9;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.coming-soon-content {
  max-width: 800px;
  margin: 0 auto;
}

.coming-soon-content h2 {
  color: #3d5a80;
  margin-bottom: 1rem;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.8rem;
}

.upcoming-guides {
  text-align: left;
  max-width: 600px;
  margin: 0 auto 2rem;
  padding-left: 1.5rem;
}

.upcoming-guides li {
  margin-bottom: 0.75rem;
  line-height: 1.5;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem auto;
  gap: 0.5rem;
}

.divider-dice {
  width: 10px;
  height: 10px;
  background-color: #3d5a80;
  display: inline-block;
  opacity: 0.7;
}

.divider-dice.d4 {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  opacity: 0.5;
}

.divider-dice.d6 {
  border-radius: 1px;
  opacity: 0.6;
}

.divider-dice.d8 {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  opacity: 0.7;
}

.divider-dice.d10 {
  clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
  opacity: 0.8;
}

.divider-dice.d12 {
  clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
  opacity: 0.9;
}

.divider-dice.d20 {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  opacity: 1;
}

.coming-soon-badge {
  display: inline-block;
  background-color: #3d5a80;
  color: white;
  font-weight: 600;
  padding: 0.5rem 2rem;
  border-radius: 20px;
  font-size: 1.2rem;
  margin: 0.5rem 0 2rem;
  box-shadow: 0 4px 10px rgba(61, 90, 128, 0.2);
  letter-spacing: 0.05em;
}

.coming-soon-message {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.coming-soon-details {
  background-color: #f8fafc;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  border: 1px solid #e2e8f0;
  text-align: left;
}

.coming-soon-details p {
  margin-bottom: 1rem;
  font-weight: 600;
  color: #3d5a80;
}

.feature-list {
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 0.5rem 1.5rem;
}

.feature-list li {
  margin-bottom: 0.5rem;
  line-height: 1.6;
  color: #4b5563;
}

.help-message {
  margin-bottom: 2rem;
}

.help-link {
  color: #3d5a80;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.2s;
}

.help-link:hover {
  color: #ee6c4d;
  text-decoration: underline;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.action-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s;
}

.action-button.primary {
  background-color: #3d5a80;
  color: white;
  border: 1px solid #3d5a80;
}

.action-button.primary:hover {
  background-color: #2c4057;
  transform: translateY(-2px);
}

.action-button.secondary {
  background-color: white;
  color: #3d5a80;
  border: 1px solid #3d5a80;
}

.action-button.secondary:hover {
  background-color: #f0f4f8;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .how-to-container {
    padding: 1rem;
  }

  .how-to-container h1 {
    font-size: 2rem;
  }

  .guide-section {
    padding: 1.5rem;
  }
  
  .guide-section h2 {
    font-size: 1.5rem;
  }
  
  .guide-section h3 {
    font-size: 1.2rem;
  }
  
  .guide-section h4 {
    font-size: 1rem;
    margin: 1rem 0 0.5rem;
  }
  
  .guide-intro {
    padding: 1rem;
  }

  .coming-soon-section {
    padding: 2rem 1.5rem;
  }

  .coming-soon-badge {
    font-size: 1rem;
    padding: 0.4rem 1.5rem;
  }

  .feature-list {
    grid-template-columns: 1fr;
  }

  .action-buttons {
    flex-direction: column;
    gap: 0.75rem;
  }

  .action-button {
    width: 100%;
    text-align: center;
  }
} 