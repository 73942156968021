.users-table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--card-bg);
  border-radius: 8px;
  overflow: hidden;
}

.users-table th {
  background-color: #2c3e50; /* Darker header background */
  color: white;
  padding: 14px 16px;
  text-align: left;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.users-table th:hover {
  background-color: #1a2533; /* Even darker on hover */
}

.users-table th:last-child {
  cursor: default;
}

.users-table th:last-child:hover {
  background-color: #2c3e50;
}

/* Add subtle column dividers */
.users-table th:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}

.users-table td {
  padding: 12px 16px;
  border-bottom: 1px solid var(--border-color);
  vertical-align: middle;
}

.users-table tbody tr {
  transition: background-color 0.2s;
  cursor: pointer;
}

.users-table tbody tr:hover {
  background-color: var(--hover-color);
}

.users-table tr.expanded {
  background-color: var(--hover-color);
  border-bottom: none;
}

.users-table .details-row {
  background-color: var(--hover-color);
  cursor: default;
}

.users-table .details-row:hover {
  background-color: var(--hover-color);
}

.users-table .details-row td {
  padding: 0;
  border-bottom: 1px solid var(--border-color);
}

.user-details-expanded {
  padding: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.user-details-grid {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(300px, 2fr);
  gap: 15px;
}

.user-info {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 0;
}

.user-info h3 {
  margin-top: 0;
  margin-bottom: 12px;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 8px;
  font-size: 1rem;
}

.user-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.user-info-column p {
  margin: 6px 0;
  line-height: 1.4;
  font-size: 0.9rem;
}

.user-id {
  font-family: monospace;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 0.85rem;
  word-break: break-all;
}

.user-collections {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.user-spellbooks, .user-custom-spells {
  flex: 1;
  min-width: 0;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 6px;
}

.user-spellbooks h4, .user-custom-spells h4 {
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 8px;
  font-size: 0.95rem;
}

.user-spellbooks ul, .user-custom-spells ul {
  max-height: 150px;
  overflow-y: auto;
  padding-left: 20px;
  margin: 0;
}

.user-spellbooks li, .user-custom-spells li {
  margin-bottom: 4px;
  padding: 3px 0;
  font-size: 0.9rem;
}

.admin-status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: center;
  min-width: 60px;
}

.admin-status.is-admin {
  background-color: #27ae60; /* Brighter green */
  color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.admin-status.not-admin {
  background-color: #7f8c8d; /* Darker gray */
  color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.user-actions {
  margin-top: 15px;
  padding-top: 12px;
  border-top: 1px solid var(--border-color);
}

.user-actions h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 0.95rem;
  color: var(--text-color);
}

.action-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.admin-button, .delete-button {
  padding: 7px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 500;
  transition: all 0.2s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: white;
}

.admin-button {
  background-color: #3498db; /* Brighter blue */
}

.admin-button:hover:not(:disabled) {
  background-color: #2980b9;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.admin-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
  opacity: 0.7;
  box-shadow: none;
}

.admin-button.revoke-admin {
  background-color: #f39c12; /* Brighter orange */
}

.admin-button.revoke-admin:hover:not(:disabled) {
  background-color: #d35400;
}

.delete-button {
  background-color: #e74c3c; /* Brighter red */
}

.delete-button:hover {
  background-color: #c0392b;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.admin-button.compact, 
.delete-button.compact {
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 3px;
}

.no-results {
  text-align: center;
  padding: 20px !important;
  color: var(--text-muted);
  font-style: italic;
}

/* Add zebra striping for better readability */
.users-table tbody tr:nth-child(odd):not(.details-row) {
  background-color: rgba(0, 0, 0, 0.02);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .user-details-grid {
    grid-template-columns: 1fr;
  }
  
  .user-collections {
    grid-template-columns: 1fr 1fr;
  }
  
  .action-buttons {
    flex-direction: row;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .user-info-grid {
    grid-template-columns: 1fr;
  }
  
  .user-collections {
    grid-template-columns: 1fr;
  }
  
  .users-table {
    font-size: 0.9rem;
  }
  
  .users-table th, .users-table td {
    padding: 8px 5px;
  }
  
  .admin-button, .delete-button {
    padding: 5px 8px;
    font-size: 0.8rem;
  }
}

.users-table th.active-sort {
  background-color: #1a2533; /* Darker background for active sort column */
}

.sort-indicator {
  display: inline-block;
  margin-left: 5px;
  font-size: 0.8rem;
  width: 12px;
  height: 12px;
  line-height: 1;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  padding: 2px;
}

.sort-indicator.ascending {
  color: #2ecc71;
}

.sort-indicator.descending {
  color: #e74c3c;
} 