.spell-points-manager {
  background-color: #f8fafc;
  border: 1px solid #98C1D9;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 16px;
  font-family: 'Poppins', sans-serif;
  max-width: 100%;
  box-sizing: border-box;
}

.spell-points-manager h3 {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #3d5a80;
  margin: -4px 0 16px 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
}

.spell-points-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
}

.level-control,
.points-control {
  display: flex;
  align-items: center;
  gap: 8px;
}

.level-control label,
.points-control label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.85rem;
  color: #4b5563;
}

.level-control input[type="number"],
.points-control input[type="number"] {
  width: 60px;
  height: 24px;
  padding: 2px 4px;
  text-align: center;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #d1d5db;
  border-radius: 3px;
  color: #4b5563;
  -moz-appearance: textfield;
}

.points-control input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

.points-display {
  margin-bottom: 16px;
}

.points-remaining {
  text-align: center;
  margin-bottom: 8px;
}

.points-remaining span {
  font-size: 1.1rem;
  font-weight: 600;
  color: #3d5a80;
}

.points-bar {
  height: 10px;
  background-color: #e6f2ff;
  border: none;
  border-radius: 6px;
  overflow: hidden;
  max-width: 850px;
  margin: 8px auto 0;
  box-shadow: inset 0 2px 4px rgba(61, 90, 128, 0.1);
}

.points-bar-fill {
  height: 100%;
  background: linear-gradient(90deg, #3d5a80 0%, #4d6a90 100%);
  transition: width 0.3s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.spell-level-costs {
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
  justify-content: center;
  max-width: 850px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.spell-level-cost {
  width: calc(11.11% - 23px);
  min-width: 60px;
  background-color: white;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  height: 120px;
  box-sizing: border-box;
}

.spell-level-cost h4 {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  color: #3d5a80;
  margin: 0 0 2px 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.cost-info {
  margin-bottom: 2px;
  font-size: 0.75rem;
  text-align: center;
}

.uses-left {
  display: block;
  font-size: 0.7rem;
  text-align: center;
  color: #EE6C4D;
}

.cost-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: auto;
}

.cost-controls button {
  width: 100%;
  padding: 3px;
  font-size: 0.7rem;
  font-family: 'Poppins', sans-serif;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cost-controls button:first-child {
  background-color: #3d5a80;
  color: white;
}

.cost-controls button:first-child:hover {
  background-color: #2c4a6e;
}

.cost-controls button:first-child:disabled {
  background-color: #a8c0d8;
  cursor: not-allowed;
}

.cost-controls button:last-child {
  background-color: #98C1D9;
  color: #2c4a6e;
}

.cost-controls button:last-child:hover {
  background-color: #7ba9c7;
}

.cost-controls button:last-child:disabled {
  background-color: #cce0ec;
  color: #5d7fa3;
  cursor: not-allowed;
}

.spell-points-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
  text-align: center;
  position: relative;
}

.reset-all-button {
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #3d5a80;
  color: white;
  border: none;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-all-button:hover {
  background-color: #2c4a6e;
}

.reset-all-button svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: white;
}

.pin-button {
  position: absolute;
  right: 12px;
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #4b5563;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.pin-button:hover {
  background-color: #f3f4f6;
  color: #3d5a80;
}

.pin-button:active {
  transform: translateY(3px);
}

.pin-button.pinned {
  color: #3d5a80;
}

.pin-button svg {
  width: 20px;
  height: 20px;
}

.spell-points-header {
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  max-width: 100%;
}

.spell-points-header h3 {
  margin: 0;
  grid-column: 2;
  text-align: center;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #3d5a80;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.system-toggle-container {
  grid-column: 3;
  min-width: 200px;
  justify-self: end;
}

.toggle-labels {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: #4b5563;
}

.toggle-labels span {
  cursor: pointer;
  transition: color 0.3s ease;
  color: #9ca3af;
}

.toggle-labels span.active {
  color: #3d5a80;
  font-weight: 600;
}

.toggle-switch {
  position: relative;
  width: 50px;
  height: 24px;
  background-color: #e5e7eb;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #98C1D9;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.toggle-switch:hover {
  background-color: #d1d5db;
}

.toggle-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: #3d5a80;
  border-radius: 50%;
  transition: transform 0.3s ease;
  border: 1px solid #2c4a6e;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.toggle-slider.points {
  transform: translateX(26px);
}

.spell-points-content {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.spell-points-main {
  flex: 1;
  min-width: 0;
  width: 100%;
}

.points-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.points-controls-row {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 12px;
}

.points-controls-row .spacer {
  visibility: hidden;
}

.total-points-input {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  white-space: nowrap;
}

.total-points-input label {
  font-size: 0.9rem;
  color: #4b5563;
  font-weight: 500;
}

.total-points-input input {
  width: 30px;
  height: 28px;
  padding: 4px 8px;
  border: 1px solid #98C1D9;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #3d5a80;
  text-align: center;
}

.total-points-input input:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.1);
}

.multiple-casts-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.multiple-casts-toggle label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.85rem;
  color: #4b5563;
  cursor: pointer;
}

.multiple-casts-toggle input[type="checkbox"] {
  margin: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .spell-level-cost {
    width: calc(12.5% - 23px);
  }
}

@media (max-width: 992px) {
  .spell-level-cost {
    width: calc(14.28% - 23px);
  }
}

@media (max-width: 768px) {
  .spell-level-cost {
    width: calc(16.66% - 23px);
  }
  
  .spell-points-header {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .spell-points-header h3 {
    grid-column: 1;
    order: 1;
  }
  
  .system-toggle-container {
    grid-column: 1;
    order: 2;
    justify-self: center;
    min-width: auto;
  }
  
  .multiple-casts-toggle {
    justify-content: center;
  }
  
  .multiple-casts-toggle label {
    font-size: 0.8rem;
  }
  
  .points-controls-row {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .points-controls-row .spacer {
    display: none;
  }

  .total-points-input {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .spell-level-cost {
    width: calc(20% - 23px);
  }
}

@media (max-width: 480px) {
  .spell-level-cost {
    width: calc(25% - 20px);
  }
  
  .spell-level-costs {
    gap: 20px;
  }
  
  .options-group > div {
    width: 100%;
  }
} 