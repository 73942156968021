@font-face {
  font-family: 'Metrophobic';
  src: url('../../assets/fonts/Metrophobic/Metrophobic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.search-and-options {
  margin-bottom: 20px;
  /* No background color needed here as it's set on the route-content container */
}

.search-and-checkboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.search-and-count {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  background-color: transparent;
}

.options-checkboxes {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 10px 0 12px;
}

.options-checkboxes label {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-family: 'Poppins', sans-serif;
  color: #4b5563;
  cursor: pointer;
  white-space: nowrap;
}

.options-checkboxes input[type="checkbox"] {
  margin-right: 5px;
}

/* You can remove or comment out the following style if it exists */
/* .ritual-checkbox {
  margin-left: 20px;
} */

.ritual-divider td {
  height: 20px;
  background-color: #f3f4f6;
}

tr.ritual {
  background-color: #f9fafb;
  font-style: italic;
  opacity: 0.7;
}

tr.ritual:hover {
  opacity: 1;
}

tr.ritual .prepare-spell-icon svg {
  fill: #9ca3af;
}

tr.ritual .prepare-spell-icon:hover svg {
  fill: #4b5563;
}

.separator-row td {
  height: 20px;
  background-color: white;
  border: none;
}

.floating-buttons {
  position: fixed;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.98);
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid #98c1d9;
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
}

.floating-close-button {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #3d5a80;
  color: white;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  z-index: 1001;
}

.floating-close-button:hover {
  background-color: #2c4a6e;
  transform: scale(1.1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.floating-button {
  background-color: #3d5a80;
  color: white;
  border: 1px solid #98c1d9;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  white-space: nowrap;
  text-align: center;
  min-width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.floating-button:hover {
  background-color: #2c4a6e;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.prepare-button {
  background-color: #3d5a80;
}

.prepare-button:hover {
  background-color: #2c4a6e;
}

.unprepare-button {
  background-color: #3d5a80;
}

.unprepare-button:hover {
  background-color: #2c4a6e;
}

.generate-cards-button {
  background-color: #edf3f8;
  border: 1px solid #3d5a80;
  color: #3d5a80;
}

.generate-cards-button:hover {
  background-color: #dde9f3;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e5e7eb;
  color: #4b5563;
  border: 1px solid #d1d5db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
}

.back-to-top:hover {
  background-color: #d1d5db;
}

.back-to-top svg {
  width: 28px;
  height: 28px;
}

.prepared-spells .spell-list th:nth-child(1),
.prepared-spells .spell-list td:nth-child(1) {
  width: 20px;
}

.prepared-spells .spell-list th:nth-child(2),
.prepared-spells .spell-list td:nth-child(2) {
  width: 230px;
}

.prepared-spells .spell-list tbody tr.spell-row.expanded {
  background-color: #f8fafc; /* Lighter background */
}

/* Update this style */
.prepared-spells .spell-list tbody tr.spell-row.expanded .short-description {
  text-align: left;
  padding: 10px;
  font-size: 0.95em;
  line-height: 1.3;
  color: #000000 !important;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.prepared-spells .spell-list tbody tr.spell-properties-row {
  background-color: #f8fafc;
  font-size: 0.8em;
  color: #4a4a4a;
}

.prepared-spells .spell-list tbody tr.spell-properties-row td {
  padding: 3px 8px 1px; /* Reduced bottom padding */
  opacity: 0.9;
  vertical-align: top; /* Align content to the top */
}

.prepared-spells .spell-list tbody tr.spell-row.ritual,
.prepared-spells .spell-list tbody tr.spell-properties-row.ritual {
  background-color: #fafbfc; /* Slightly different background for ritual spells */
  font-style: italic;
  opacity: 0.8;
}

.prepared-spells .spell-list tbody tr.spell-row.ritual:hover,
.prepared-spells .spell-list tbody tr.spell-properties-row.ritual:hover {
  opacity: 1;
}

.prepared-spells .info-icon {
  position: absolute;
  right: 25px; /* Moved closer to the prepare-spell icon */
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.prepared-spells .info-icon svg {
  width: 100%;
  height: 100%;
}

.prepared-spells .info-icon:hover svg {
  fill: #1f2937;
}

.prepared-spells .filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 6px;
}

.prepared-spells .filter-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 120px;
}

.prepared-spells .filter-group select {
  padding: 6px 10px;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;
  color: #3d5a80;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 16px auto;
}

.prepared-spells .filter-group select:hover {
  border-color: #2c4a6e;
}

.prepared-spells .filter-group select:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

/* Update the filter dropdown options (Firefox only) */
.prepared-spells .filter-group select option {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.85em;
}

/* Add this new style for the table header */
.prepared-spells .spell-list thead {
  background-color: #f8fafc;
}

/* Update this existing style */
.prepared-spells .spell-list table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #98C1D9;
  border-radius: 4px;
  overflow: hidden;
  empty-cells: show; /* Ensure empty cells are rendered */
}

/* Update this existing style */
.prepared-spells .spell-list th {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: #3d5a80;
  font-weight: bold;
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 10px 8px;
  background-color: #f8fafc;
  border-bottom: none; /* Remove individual bottom borders from th elements */
}

.prepared-spells .spell-list th:hover {
  background-color: #e2eef9;
}

/* Add this new style to ensure consistent appearance when table is empty */
.prepared-spells .spell-list tbody:empty {
  display: none; /* Hide empty tbody */
}

.prepared-spells .spell-list tbody:empty + tfoot::before {
  content: '';
  display: table-row;
  height: 1px; /* Minimal height to prevent collapsing */
}

.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3); /* Changed from 0.4 to 0.3 for even less darkness */
  backdrop-filter: blur(5px);
  z-index: 999;
}

.spell-info-box {
  z-index: 1000;
}

/* Add or update this style in PreparedSpells.css */
.prepared-spells .empty-table-row td {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #6b7280;
  background-color: #ffffff;
}

/* Add this new style for table cells */
.prepared-spells .spell-list td {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.8rem; /* Reduced from 0.85rem */
  padding: 5px 8px; /* Increased from 4px to 5px */
}

/* Spell name cell specific styling - override the font */
.prepared-spells .spell-list td:nth-child(2) {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

.spell-name-cell {
  position: relative;
  padding-right: 55px;
}

.info-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.info-icon svg {
  width: 100%;
  height: 100%;
}

.prepare-spell-icon {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Ensure the SVGs fill their container */
.info-icon svg,
.prepare-spell-icon svg {
  width: 100%;
  height: 100%;
}

/* Make the prepare spell icon yellow in dark mode when prepared */
html[data-darkreader-scheme="dark"] .prepare-spell-icon.prepared svg {
  fill: #ffd700 !important; /* Bright gold/yellow color */
  filter: drop-shadow(0 0 3px rgba(255, 215, 0, 0.7)) !important;
}

/* Add these new styles */
.prepared-spells .spell-list td:nth-child(3),  /* Level column */
.prepared-spells .spell-list td:nth-child(9),  /* Concentration column */
.prepared-spells .spell-list td:nth-child(10) { /* Ritual column */
  text-align: center;
}

/* Center the headers for these columns as well */
.prepared-spells .spell-list th:nth-child(3),
.prepared-spells .spell-list th:nth-child(9),
.prepared-spells .spell-list th:nth-child(10) {
  text-align: center;
}

/* Add this new style for the expanded row */
.prepared-spells .spell-list tbody tr.spell-row.expanded td {
  padding-bottom: 1px; /* Reduce bottom padding */
}

.prepared-spells .spell-list tbody tr.spell-row.expanded + tr.spell-properties-row td {
  padding-top: 1px; /* Reduce top padding */
}

/* Update the cast spell icon styles */
.cast-spell-icon {
  position: absolute;
  right: 42px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cast-spell-icon svg {
  width: 100%;
  height: 100%;
  fill: #2c4a6e; /* Changed to match info icon color */
}

.cast-spell-icon:hover svg {
  fill: #1f2937; /* Darker shade on hover */
}

/* Update animation for when spell is cast */
.cast-spell-icon.casting svg {
  animation: castGlow 0.8s ease-out;
}

@keyframes castGlow {
  0% {
    fill: #2c4a6e;
    filter: drop-shadow(0 0 0 rgba(138, 79, 255, 0));
    transform: scale(1);
  }
  15% {
    fill: #9B6FFF;
    filter: drop-shadow(0 0 12px rgba(155, 111, 255, 0.9));
    transform: scale(1.3);
  }
  40% {
    fill: #8A4FFF;
    filter: drop-shadow(0 0 16px rgba(138, 79, 255, 0.8));
    transform: scale(1.2);
  }
  60% {
    fill: #9B6FFF;
    filter: drop-shadow(0 0 10px rgba(155, 111, 255, 0.7));
    transform: scale(1.1);
  }
  100% {
    fill: #2c4a6e;
    filter: drop-shadow(0 0 0 rgba(138, 79, 255, 0));
    transform: scale(1);
  }
}

/* Update this existing style to accommodate the new icon */
.prepared-spells .info-icon {
  right: 25px;
}

/* Update this existing style */
.spell-name-cell {
  position: relative;
  padding-right: 70px; /* Slightly reduced from 75px to 70px */
}

/* Add media queries to hide columns based on screen width, excluding the level column */
@media (max-width: 1200px) {
  .prepared-spells .spell-list th:nth-child(10),
  .prepared-spells .spell-list td:nth-child(10) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .prepared-spells .spell-list th:nth-child(9),
  .prepared-spells .spell-list td:nth-child(9) {
    display: none;
  }
}

@media (max-width: 1000px) {
  .prepared-spells .spell-list th:nth-child(8),
  .prepared-spells .spell-list td:nth-child(8) {
    display: none;
  }
}

@media (max-width: 900px) {
  .prepared-spells .spell-list th:nth-child(7),
  .prepared-spells .spell-list td:nth-child(7) {
    display: none;
  }
}

@media (max-width: 800px) {
  .prepared-spells .spell-list th:nth-child(6),
  .prepared-spells .spell-list td:nth-child(6) {
    display: none;
  }
}

@media (max-width: 700px) {
  .prepared-spells .spell-list th:nth-child(5),
  .prepared-spells .spell-list td:nth-child(5) {
    display: none;
  }
}

@media (max-width: 600px) {
  .prepared-spells .spell-list th:nth-child(4),
  .prepared-spells .spell-list td:nth-child(4) {
    display: none;
  }
}

/* Ensure the level column (nth-child(3)) is not hidden */

.spell-counter {
  background-color: transparent !important;
}

/* Add these styles */
.active-spellbook-header {
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.active-spellbook-header h2 {
  color: #3d5a80;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
}

/* Add these new styles */
.spellbook-selector {
  width: 300px;
  max-width: 300px;
  padding: 8px 12px;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  color: #3d5a80;
  background-color: white;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 20px;
  padding-right: 32px;
}

.spellbook-selector:hover {
  border-color: #3d5a80;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.spellbook-selector:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

/* Add responsive styles */
@media (max-width: 768px) {
  .active-spellbook-header {
    flex-direction: column;
    align-items: center;
    padding: 12px 15px;
    gap: 8px;
  }

  .spellbook-selector {
    width: 100%;
    max-width: none;
  }

  .options-checkboxes {
    flex-direction: column;
    gap: 8px;
    margin: 8px 0 10px;
  }
}

/* Add these styles */
.spell-slots-everywhere-option {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;
  color: #4b5563;
}

.spell-slots-everywhere-option input[type="checkbox"] {
  margin: 0;
}

/* Update this style */
.clear-filters {
  margin-left: auto;
  padding: 5px 10px;
  background-color: #3d5a80;
  color: #ffffff;
  border: none; /* Removed the border */
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 0.9em;
  transition: all 0.3s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Stack checkboxes on smaller screens with adjusted spacing */
@media (max-width: 768px) {
  .options-checkboxes {
    flex-direction: column;
    gap: 8px;
    margin: 8px 0 10px;
  }
}

@media (max-width: 768px) {
  .floating-buttons {
    width: 90%;
    max-width: 300px;
  }

  .floating-button {
    min-width: unset;
    width: 100%;
  }
}

/* Add responsive adjustments for PreparedSpells */
@media (max-width: 768px) {
  .prepared-spells .spell-list {
    overflow-x: auto; /* Allow horizontal scrolling on mobile */
    margin: 0;
    padding: 0;
  }
  
  .prepared-spells .spell-list table {
    margin: 0; /* Ensure no margin is added */
    font-size: 0.8rem; /* Slightly smaller font on mobile */
  }
  
  .search-and-options {
    margin-bottom: 15px;
  }
}

/* Add these styles to ensure proper table width */
.prepared-spells .spell-list {
  width: 100%;
  padding: 0; /* No padding */
  box-sizing: border-box;
}

.prepared-spells .spell-list table {
  width: 100%; /* Full width */
  margin: 0; /* No margin */
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
}

/* Simple Confirmation Popup Styles */
.simple-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.simple-confirmation-popup {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  width: 90%;
  max-width: 400px;
  overflow: hidden;
  animation: popup-appear 0.3s ease-out;
}

.simple-confirmation-header {
  background-color: #3d5a80;
  color: white;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #98c1d9;
}

.simple-confirmation-header h2 {
  margin: 0;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}

.simple-close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.simple-close-button svg {
  fill: white;
  width: 20px;
  height: 20px;
}

.simple-close-button:hover svg {
  fill: #e2e8f0;
}

@keyframes popup-appear {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.simple-confirmation-content {
  padding: 20px;
  text-align: left;
}

.simple-confirmation-content p {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: #333;
  margin: 0;
  line-height: 1.5;
}

.simple-confirmation-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding: 0 20px 20px;
}

.simple-confirm-button, 
.simple-cancel-button {
  padding: 10px 20px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.simple-confirm-button {
  background-color: #3d5a80;
  color: white;
}

.simple-confirm-button:hover {
  background-color: #2c4a6e;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.simple-cancel-button {
  background-color: #f1f5f9;
  color: #3d5a80;
  border: 1px solid #98c1d9;
}

.simple-cancel-button:hover {
  background-color: #e2e8f0;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* End Simple Confirmation Popup Styles */
