@font-face {
  font-family: 'Metamorphous';
  src: url('../../assets/fonts/Metamorphous-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-primary: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --font-secondary: 'Metamorphous', cursive;
  --font-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  --background-color: #ffffff;
}

html {
  min-height: 100vh;
  background: url('../../assets/images/bgtexture.jpg') repeat fixed;
}

body {
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.95);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-secondary);
}

code {
  font-family: var(--font-code);
}

/* Add any other global styles here */

/* No problematic backdrop styles found */
/* The html background is a texture but doesn't affect modals */
