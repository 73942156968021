@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* Main navbar styles for normal view */
.main-nav {
  font-family: 'Poppins', sans-serif;
  background: #293241;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1000;
}

.main-nav .wrapper {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0px 50px;
  height: 70px;
  line-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wrapper .logo a {
  color: #E0FBFC;
  font-size: 24px;
  font-weight: 400;
  text-decoration: none;
}

.wrapper .logo a:first-child {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  transition: transform 0.2s ease;
}

.wrapper .logo a:first-child:hover {
  transform: scale(1.05);
}

.wrapper .logo a:last-child {
  margin-left: 10px;
}

.wrapper .nav-links {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 40px;
}

.nav-links li {
  list-style: none;
  position: relative;
}

.nav-links li a {
  color: #E0FBFC;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-links li a:hover {
  color: #EE6C4D;
}

.nav-links .mobile-item {
  display: none;
}

.nav-links .drop-menu {
  background: #293241;
  position: absolute;
  width: 150px;
  line-height: 45px;
  top: calc(100% - 5px);
  left: 0;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
  transition: all 0.3s ease;
  overflow: hidden;
  padding: 0;
  z-index: 1001;
  pointer-events: none;
}

.nav-links li:hover .drop-menu {
  top: calc(100% - 17px);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.drop-menu li {
  width: 100%;
  padding: 0;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 15px;
  font-weight: 400;
  border-radius: 0;
  font-size: 15px;
  color: #E0FBFC;
  transition: all 0.3s ease;
  text-align: left;
  line-height: 45px;
}

.drop-menu li:not(.theme-toggle-item) a:hover {
  background: #3D5A80;
  padding-left: 20px;
  color: #EE6C4D;
}

.nav-logo {
  width: 50px;
  height: auto;
  object-fit: contain;
}

/* Account styles for normal view */
.account-item {
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
}

.account-item .desktop-item {
  display: flex;
  align-items: center;
  color: #E0FBFC;
  padding: 9px 15px;
  height: 100%;
}

.account-item:hover .desktop-item {
  color: #EE6C4D;
}

.account-item .mobile-item {
  display: none;
}

.account-item .drop-menu {
  position: absolute;
  width: 150px;
  line-height: 45px;
  top: calc(100% - 5px);
  right: 0;
  left: auto;
  background: #293241;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 1001;
}

.account-item:hover .drop-menu {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.account-item .drop-menu li {
  display: block;
  margin: 0;
  padding: 0;
}

.account-item .drop-menu li a,
.account-item .drop-menu li button {
  display: block;
  width: 100%;
  padding: 0 15px;
  font-weight: 400;
  border-radius: 0;
  font-size: 15px;
  color: #E0FBFC;
  text-align: left;
  line-height: 45px;
  background: none;
  border: none;
  cursor: pointer;
}

.account-item .drop-menu li a:hover,
.account-item .drop-menu li button:hover {
  background: #3D5A80;
  color: #EE6C4D;
}

/* Login item styles */
.nav-links .login-item {
  margin-left: auto;
}

.nav-links .login-item a {
  background-color: #3D5A80;
  color: #E0FBFC;
  padding: 5px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
  font-size: 14px;
}

.nav-links .login-item a:hover {
  background-color: #2c4057;
  color: #E0FBFC;
}

/* Hide close button in desktop view */
.close-menu-btn {
  display: none;
}

/* Remove the hover area after element */
.account-item::after {
  display: none;
}
