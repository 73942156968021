.pagination-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #98C1D9;
  border-radius: 4px;
}

.pagination-info {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 0.9rem;
  color: #4b5563;
}

.items-per-page-select {
  padding: 5px 8px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85em;
  color: #3d5a80;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 16px auto;
  min-width: 120px;
}

.pagination-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination-button {
  padding: 5px 10px;
  background-color: #3d5a80;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85em;
  transition: all 0.3s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #2c4a6e;
}

.pagination-button:disabled {
  background-color: #e5e7eb;
  border-color: #d1d5db;
  color: #9ca3af;
  cursor: not-allowed;
}

.page-info {
  font-size: 0.9rem;
  color: #4b5563;
  min-width: 100px;
  text-align: center;
}

@media (max-width: 600px) {
  .pagination-controls {
    padding: 5px;
  }

  .pagination-info {
    flex-direction: column;
    gap: 5px;
  }

  .pagination-buttons {
    gap: 5px;
  }

  .pagination-button {
    padding: 3px 6px;
    font-size: 0.8em;
  }
} 