/* Dice-themed hero bar styling - optimized for performance */
@keyframes pageHero-float {
  0%, 100% { translate: 0 0; }
  50% { translate: 0 -10px; }
}

@keyframes pageHero-floatSlow {
  0%, 100% { translate: 0 0; }
  50% { translate: 5px -8px; }
}

@keyframes pageHero-pulse {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}

/* Hero wrapper to contain everything including the pause button */
.dice-hero-wrapper {
  position: relative;
}

.dice-hero {
  position: relative;
  padding: 2rem 0 5.5rem 0;
  margin-bottom: 0;
  background: #f7f9fc;
  overflow: hidden;
  border-top: none;
  border-bottom: none;
  min-height: 200px;
  box-sizing: border-box;
  z-index: 2;
}

/* Subtle backdrop for dice elements - removed the gradient */
.dice-hero .dice-hero-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 1;
}

/* Background dice that float around - performance optimized */
.dice-hero .background-dice {
  position: absolute;
  filter: invert(28%) sepia(20%) saturate(1100%) hue-rotate(188deg) brightness(90%) contrast(87%);
  animation: pageHero-floatSlow 30s ease-in-out infinite;
  z-index: 1;
  transform-origin: center center;
  backface-visibility: hidden; /* Reduce flickering */
  perspective: 1000; /* Helps with rendering */
}

/* Foreground dice - performance optimized */
.dice-hero .foreground-dice {
  position: absolute;
  filter: invert(28%) sepia(20%) saturate(1100%) hue-rotate(188deg) brightness(90%) contrast(87%);
  animation: pageHero-float 12s ease-in-out infinite;
  z-index: 3;
  transform-origin: center center;
  backface-visibility: hidden; /* Reduce flickering */
  perspective: 1000; /* Helps with rendering */
}

/* Paused animation state */
.dice-hero .background-dice.paused,
.dice-hero .foreground-dice.paused,
.dice-hero .divider-dice.paused {
  animation-play-state: paused;
}

/* Animation pause button */
#animation-pause-btn.animation-pause-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 0;
  background-color: transparent;
  border: none;
  color: rgba(61, 90, 128, 0.7);
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
  z-index: 9999;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  pointer-events: all !important;
}

#animation-pause-btn.animation-pause-button:hover {
  color: rgba(61, 90, 128, 1);
}

#animation-pause-btn.animation-pause-button svg {
  display: block;
  width: 14px;
  height: 14px;
  pointer-events: none;
}

.dice-hero * {
  pointer-events: auto;
}

/* Main content container */
.dice-hero .dice-hero-content {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  min-height: 160px;
}

/* Text content */
.dice-hero .hero-text {
  text-align: center;
  padding: 0 1rem;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.dice-hero .hero-text h1 {
  color: #3d5a80;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 2rem;
  font-weight: 500;
  margin: 0 0 0.8rem;
  letter-spacing: 0.02em;
}

.dice-hero .hero-text p {
  color: #616e7c;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  margin: 0.8rem 0 0;
}

/* Divider with tiny dice shapes */
.dice-hero .hero-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  gap: 0.5rem;
}

.dice-hero .divider-dice {
  width: 8px;
  height: 8px;
  background-color: #3d5a80; /* Darker blue */
  display: inline-block;
  opacity: 0.7;
  animation: pageHero-pulse 3s infinite;
  box-shadow: 0 0 4px rgba(61, 90, 128, 0.2);
}

.dice-hero .divider-dice.d4 {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  animation-delay: 0s;
  opacity: 0.4;
}

.dice-hero .divider-dice.d6 {
  border-radius: 1px;
  animation-delay: 0.5s;
  opacity: 0.5;
}

.dice-hero .divider-dice.d8 {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  animation-delay: 1s;
  opacity: 0.6;
}

.dice-hero .divider-dice.d10 {
  clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
  animation-delay: 1.5s;
  opacity: 0.7;
}

.dice-hero .divider-dice.d12 {
  clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
  animation-delay: 2s;
  opacity: 0.8;
}

.dice-hero .divider-dice.d20 {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  animation-delay: 2.5s;
  opacity: 0.9;
}

/* Hover effects */
.dice-hero:hover .foreground-dice {
  filter: invert(28%) sepia(20%) saturate(1200%) hue-rotate(188deg) brightness(95%) contrast(95%);
}

/* Media queries */
@media screen and (max-width: 768px) {
  .dice-hero {
    padding: 1.5rem 0 3.8rem 0; /* Adjusted padding for tablet view */
  }

  .dice-hero .dice-hero-content {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  
  .dice-hero .hero-text {
    flex: 0 0 100%;
    margin-bottom: 0.5rem;
  }
  
  .dice-hero .hero-text h1 {
    font-size: 1.6rem;
  }
  
  .dice-hero .foreground-dice {
    display: none;
  }
  
  /* Reposition pause button to top right on smaller screens */
  #animation-pause-btn.animation-pause-button {
    bottom: auto;
    top: 10px;
    right: 10px;
  }
}

@media screen and (max-width: 480px) {
  .dice-hero {
    padding: 1.25rem 0 3.5rem 0; /* Adjusted padding for mobile view */
  }
  
  .dice-hero .hero-text h1 {
    font-size: 1.4rem;
  }
  
  .dice-hero .hero-text p {
    font-size: 0.85rem;
  }
  
  .dice-hero .divider-dice {
    width: 6px;
    height: 6px;
  }
  
  /* Ensure pause button is visible on very small screens */
  #animation-pause-btn.animation-pause-button {
    top: 8px;
    right: 8px;
  }
} 