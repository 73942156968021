.notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.notification-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  color: #2c4a6e; /* Changed text color to dark blue */
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid #2c4a6e; /* Thin dark blue border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  max-width: 250px;
  width: 80%;
  text-align: center;
  animation: fadeInOut 3s ease-in-out;
  z-index: 1000;
  font-family: 'Poppins', sans-serif;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; transform: translate(-50%, -60%); }
  10%, 90% { opacity: 1; transform: translate(-50%, -50%); }
}

.notification-popup p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}
