/* Main spell counter (above the clear filters button) */
.spell-counter {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  padding: 3px 10px;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  background: none !important;
}

.spell-counter .spell-count {
  font-size: 1em;
  font-weight: bold;
  color: #3d5a80;
}

.spell-counter .spell-count-separator {
  color: #3d5a80;
  font-size: 0.9em;
  margin: 0 1px;
}

.spell-counter .total-count {
  font-weight: normal;
}

.spell-counter .spell-count-label {
  font-size: 0.8em;
  color: #3d5a80;
  margin-left: 2px;
}

/* Spellbook tab counters */
.spellbook-select .spell-count {
  font-size: 12px;
  color: #4b5563;
  margin-left: 5px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background: none !important;
}

.desktop-only {
  display: inline;
}

@media (max-width: 480px) {
  .desktop-only {
    display: none;
  }
}