@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

/* Global Styles */
.home-page {
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #293241;
  position: relative;
  z-index: 1;
}

.home-page * {
  font-family: 'Poppins', sans-serif;
}

.home-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
  margin-top: 0; /* Changed from -1px to 0 to prevent gap/overlap */
}

.button {
  display: inline-block;
  padding: 0.85rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.3s ease;
  text-align: center;
}

.button.primary {
  background-color: #EE6C4D;
  color: white;
  border: none;
}

.button.primary:hover {
  background-color: #e34f2d;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(238, 108, 77, 0.3);
}

.button.secondary {
  background-color: transparent;
  color: #3d5a80;
  border: 2px solid #3d5a80;
}

.button.secondary:hover {
  background-color: rgba(61, 90, 128, 0.1);
  transform: translateY(-2px);
}

/* Hero Section */
.hero {
  position: relative;
  width: 100%;
  min-height: 600px;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: -20px;
  margin-bottom: 0;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../../assets/images/dice-hero1.webp') center/cover no-repeat;
  opacity: 1;
  z-index: 0;
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(30, 44, 64, 0.85) 0%,
    rgba(30, 44, 64, 0.6) 30%,
    rgba(30, 44, 64, 0.1) 60%,
    rgba(30, 44, 64, 0) 100%
  );
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.hero-text {
  max-width: 550px;
}

.hero h1 {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  line-height: 1.1;
  font-family: 'Mondia-Light', 'Metamorphous', serif;
  letter-spacing: 2px;
  position: relative;
  display: inline-block;
}

.hero h1.title-glow {
  text-shadow: 0 0 7px rgba(255, 255, 255, 0.7), 
               0 0 10px rgba(255, 255, 255, 0.5), 
               0 0 21px rgba(255, 255, 255, 0.2),
               2px 4px 8px rgba(0, 0, 0, 0.3) !important;
}

.hero h1::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #EE6C4D, rgba(238, 108, 77, 0));
  border-radius: 3px;
}

.title-accent {
  color: #EE6C4D;
  text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
}

.hero p {
  font-size: 1.5rem;
  font-weight: 400;
  color: #E0FBFC;
  margin-bottom: 2.5rem;
  line-height: 1.4;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}

.hero-subtext {
  font-size: 1.2rem !important;
  margin-top: -1.5rem !important;
  margin-bottom: 2.5rem !important;
  opacity: 0.9;
  font-style: italic;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.hero-buttons .button {
  padding: 1rem 2rem;
  min-width: 140px;
}

.hero-buttons .button.primary {
  background-color: #EE6C4D;
}

.hero-buttons .button.secondary {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.hero-buttons .button.secondary:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Testimonial Carousel in Hero */
.testimonial-carousel {
  position: absolute;
  bottom: 30px;
  right: 2rem;
  width: 400px;
  max-width: calc(100% - 4rem);
  height: auto;
  min-height: 180px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);
  border-radius: 10px;
  padding: 1.5rem;
  color: #fff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial-carousel:hover {
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.4);
}

.testimonial-slide {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.testimonial-quote {
  margin-bottom: 1rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.testimonial-quote p {
  font-size: 1.1rem;
  font-style: italic;
  line-height: 1.5;
  margin: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.testimonial-attribution {
  text-align: right;
  margin-bottom: 0.5rem;
}

.testimonial-name {
  font-size: 1.1rem;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  font-family: 'Mondia-Light', 'Metamorphous', serif;
}

.testimonial-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.testimonial-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.testimonial-dot.active {
  background: #EE6C4D;
  transform: scale(1.2);
}

.testimonial-dot:hover {
  background: rgba(255, 255, 255, 0.7);
}

/* Content Sections */
.home-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
  margin-top: 0; /* Changed from -1px to 0 to prevent gap/overlap */
}

/* Intro Section */
.home-intro {
  text-align: center;
  margin-bottom: 4rem;
  padding: 1rem;
}

.intro-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.divider-line {
  height: 2px;
  width: 80px;
  background-color: #3d5a80;
  display: inline-block;
}

.intro-heading h2 {
  font-family: 'Mondia-Light', 'Metamorphous', serif;
  font-size: 2.2rem;
  color: #3d5a80;
  margin: 0 1.5rem;
}

.home-intro p {
  max-width: 700px;
  margin: 0 auto;
  font-size: 1.1rem;
  color: #4A5568;
  line-height: 1.6;
}

/* Feature Cards */
.feature-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-bottom: 4rem;
}

.feature-card {
  background-color: #fff;
  border: 1px solid #98C1D9;
  border-radius: 8px;
  padding: 2rem 1.5rem;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 280px;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  border-color: #3d5a80;
}

.feature-card-icon {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.feature-card-icon svg {
  width: 50px;
  height: 50px;
  fill: #3d5a80;
}

/* Ensure the SpellLibraryIcon displays properly */
.feature-card:first-child .feature-card-icon svg {
  width: 50px;
  height: 50px;
  fill: #3d5a80;
}

/* Ensure the SpellbooksIcon displays properly */
.feature-card:nth-child(2) .feature-card-icon svg {
  width: 50px;
  height: 50px;
  fill: #3d5a80;
}

.feature-card h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: #3d5a80;
  font-weight: 600;
}

.feature-card p {
  font-size: 0.95rem;
  color: #4A5568;
  margin-bottom: 1.5rem;
  line-height: 1.5;
  flex-grow: 1;
}

.card-link {
  color: #EE6C4D;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.95rem;
  transition: color 0.3s ease;
  display: inline-block;
}

.card-link:hover {
  color: #e34f2d;
  text-decoration: underline;
}

/* Showcase Section */
.spell-showcase {
  margin: 5rem 0;
  background-color: #f8fafc;
  border-radius: 10px;
  overflow: hidden;
}

.showcase-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
  padding: 3rem;
}

.showcase-text h2 {
  font-size: 2rem;
  color: #3d5a80;
  margin-bottom: 2rem;
  font-family: 'Mondia-Light', 'Metamorphous', serif;
}

.showcase-features {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
}

.showcase-features li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.05rem;
  color: #4A5568;
}

.feature-icon {
  display: inline-flex;
  margin-right: 1rem;
  color: #3d5a80;
}

.feature-icon svg {
  width: 24px;
  height: 24px;
  fill: #3d5a80;
}

/* Override any hard-coded fill colors in SVGs */
.feature-icon svg path,
.feature-icon svg polygon,
.feature-icon svg rect,
.feature-icon svg circle,
.feature-icon svg g {
  fill: #3d5a80;
}

.showcase-button {
  margin-top: 1rem;
}

.showcase-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-card {
  width: 100%;
  max-width: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid #3d5a80;
  overflow: hidden;
}

.cardstack-image {
  width: 100%;
  max-width: 400px;
}

.card-header {
  background-color: #3d5a80;
  color: white;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.card-header h3 {
  margin: 0 0 0.3rem 0;
  font-size: 1.6rem;
  font-family: 'Mondia-Light', 'Metamorphous', serif;
}

.spell-level {
  font-size: 0.9rem;
  opacity: 0.9;
  font-style: italic;
}

.card-body {
  padding: 1.5rem;
}

.spell-property {
  margin-bottom: 0.8rem;
  font-size: 0.95rem;
}

.property-label {
  font-weight: 600;
  color: #3d5a80;
  display: inline-block;
  width: 120px;
}

.property-value {
  color: #4A5568;
}

/* CTA Section */
.cta-section {
  background: linear-gradient(135deg, #3d5a80 0%, #293241 100%);
  padding: 4rem 2rem;
  border-radius: 10px;
  margin: 4rem 0;
  text-align: center;
  box-shadow: 0 10px 30px rgba(41, 50, 65, 0.1);
}

.cta-content {
  max-width: 700px;
  margin: 0 auto;
}

.cta-content h2 {
  color: white;
  font-size: 2.2rem;
  margin-bottom: 1rem;
  font-family: 'Mondia-Light', 'Metamorphous', serif;
}

.cta-content p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.cta-buttons .button.secondary {
  color: white;
  border-color: white;
}

.cta-buttons .button.secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Workflow Section */
.workflow-section {
  padding: 3rem 0;
  text-align: center;
}

.workflow-section h2 {
  font-size: 2.2rem;
  color: #3d5a80;
  margin-bottom: 3rem;
  font-family: 'Mondia-Light', 'Metamorphous', serif;
}

.workflow-steps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.workflow-step {
  text-align: center;
  position: relative;
}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #3d5a80;
  color: white;
  border-radius: 50%;
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0 auto 1.5rem;
}

.workflow-step h3 {
  font-size: 1.3rem;
  color: #3d5a80;
  margin-bottom: 1rem;
  font-family: 'Mondia-Light', 'Metamorphous', serif;
}

.workflow-step p {
  font-size: 0.95rem;
  color: #4A5568;
  line-height: 1.5;
}

/* Testimonials Section */
.testimonials-section {
  padding: 4rem 0;
  text-align: center;
  background-color: #f8fafc;
  margin: 4rem 0;
  border-radius: 10px;
}

.testimonials-section h2 {
  font-size: 2.2rem;
  color: #3d5a80;
  margin-bottom: 3rem;
  font-family: 'Mondia-Light', 'Metamorphous', serif;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.testimonial-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  text-align: left;
  border: 1px solid #e1e8ed;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.12);
}

.testimonial-content {
  flex-grow: 1;
  margin-bottom: 1.5rem;
}

.testimonial-content p {
  font-size: 1rem;
  color: #4A5568;
  line-height: 1.6;
  font-style: italic;
  position: relative;
}

.testimonial-content p::before,
.testimonial-content p::after {
  content: '"';
  font-size: 1.5rem;
  color: #EE6C4D;
  font-family: serif;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-top: 1px solid #e1e8ed;
  padding-top: 1rem;
}

.author-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #3d5a80;
  position: relative;
  overflow: hidden;
}

.author-avatar::after {
  content: '';
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.author-avatar.bigby {
  background-color: #7c4dff;
}

.author-avatar.tasha {
  background-color: #ff4d9d;
}

.author-avatar.evard {
  background-color: #4d4d4d;
}

.author-avatar.otiluke {
  background-color: #4dcdff;
}

.author-avatar.mordenkainen {
  background-color: #ffd700;
}

.author-info {
  display: flex;
  flex-direction: column;
}

.author-info h4 {
  margin: 0;
  font-size: 1.1rem;
  color: #3d5a80;
  font-weight: 600;
  font-family: 'Mondia-Light', 'Metamorphous', serif;
}

.author-info span {
  font-size: 0.85rem;
  color: #718096;
}

/* Responsive layout for testimonials */
@media (max-width: 1024px) {
  .feature-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
  }
  
  .workflow-steps {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .testimonial-carousel {
    right: 2rem;
    width: 400px;
    max-width: calc(100% - 4rem);
  }
  
  .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .testimonial-card:last-child {
    grid-column: span 2;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 900px) {
  .testimonial-carousel {
    position: relative;
    bottom: auto;
    right: auto;
    transform: none;
    margin-top: 2rem;
    width: 100%;
    max-width: 400px;
  }
  
  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .hero-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 100%;
  }
  
  .hero-text .hero-buttons {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .hero {
    height: auto;
    min-height: 550px;
    padding: 5rem 0 6rem;
  }
  
  .hero h1 {
    font-size: 3rem;
  }
  
  .hero h1.title-glow {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.6), 
                0 0 10px rgba(255, 255, 255, 0.3),
                2px 4px 6px rgba(0, 0, 0, 0.3) !important;
  }
  
  .hero h1::after {
    bottom: -8px;
    height: 2px;
  }
  
  .hero p {
    font-size: 1.2rem;
  }
  
  .hero-subtext {
    font-size: 1rem !important;
    margin-top: -1rem !important;
  }
  
  .showcase-content {
    grid-template-columns: 1fr;
  }
  
  .showcase-image {
    order: -1;
    margin-bottom: 2rem;
  }
  
  .showcase-text {
    text-align: center;
  }
  
  .workflow-section h2 {
    margin-bottom: 2rem;
  }
  
  .testimonial-quote p {
    font-size: 1rem;
  }
  
  .testimonial-name {
    font-size: 1rem;
  }
  
  .testimonials-grid {
    grid-template-columns: 1fr;
  }
  
  .testimonial-card:last-child {
    grid-column: span 1;
  }
}

@media (max-width: 480px) {
  .testimonial-carousel {
    height: auto;
    min-height: 190px;
    padding: 1rem;
  }
  
  .hero {
    padding: 4rem 0 2rem;
  }
  
  .hero-buttons {
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 0.75rem;
  }
  
  .hero-buttons .button {
    width: auto;
    padding: 0.75rem 1.25rem;
    font-size: 0.9rem;
    min-width: auto;
  }

  .feature-cards {
    gap: 3rem; /* Even more space for mobile view */
  }
  
  .feature-card {
    min-height: 250px; /* Slightly shorter for mobile view */
  }
}

html {
  scroll-behavior: smooth;
}

footer {
  position: relative;
  z-index: 2;
}
