/* Add this @font-face declaration at the top of the file */
@font-face {
  font-family: 'OPTIBerling-Agency';
  src: url('../../assets/fonts/OPTIBerling-Agency/OPTIBerling-Agency.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Add the new font-face declaration */
@font-face {
  font-family: 'Mondia-Light';
  src: url('../../assets/fonts/Mondia Light/Mondia Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Base table styles */
table {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #98C1D9;
  border-radius: 4px;
  overflow: hidden;
  table-layout: fixed;
  font-size: 0.85rem;
  empty-cells: show;
}

/* Header styles */
thead {
  background-color: #f8fafc;
  border-bottom: 1px solid #98C1D9;
}

th {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: #3d5a80;
  font-weight: bold;
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 12px 8px;
  background-color: #f8fafc;
  border-bottom: 2px solid #e2e8f0;
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: sticky;
  top: 0;
  z-index: 10;
}

th:hover {
  background-color: #e2eef9;
}

/* Cell styles */
td {
  padding: 8px;
  border-bottom: 1px solid #e2e8f0;
  vertical-align: middle;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
}

/* Spell name cell specific styling - override the font */
td:nth-child(2) {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  padding: 3px 55px 3px 8px;
  position: relative;
}

/* Row styles */
tr:nth-child(even) {
  background-color: #f8fafc;
}

tr:hover {
  background-color: #d9ebff;
}

/* Spell name cell styles */
.spell-name-cell {
  position: relative;
  padding: 3px 55px 3px 8px;
}

/* Styles for the spell name with notes container */
.spell-name-with-notes {
  display: flex;
  align-items: center;
}

/* Update the spell name link styles */
.spell-name-cell a {
  color: #1a4b8c;
  text-decoration: none;
  font-weight: normal;
  font-size: 1rem;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}

.spell-name-cell a:hover {
  text-decoration: underline;
}

/* Inline notes icon styles */
.notes-icon-inline {
  display: inline-flex;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  color: #3d5a80;
}

.notes-icon-inline svg {
  width: 100%;
  height: 100%;
  fill: #3d5a80;
}

/* Icon container styles */
.icon-container {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 5px;
  align-items: center;
}

/* Checkbox styles */
input[type="checkbox"] {
  cursor: pointer;
  width: 14px;
  height: 14px;
  vertical-align: middle;
}

/* Center checkboxes in cells */
th:first-child input[type="checkbox"],
td:first-child input[type="checkbox"] {
  margin: 0 auto;
  display: block;
}

/* Expanded row styles */
.spell-row.expanded {
  background-color: #f8fafc;
}

/* Update the description row styles */
.spell-description-row {
  background-color: #f8fafc;
}

.spell-description-row td.short-description {
  text-align: left;
  padding: 3px 8px 3px 48px;
  font-size: 0.9rem;
  line-height: 1.4;
  color: #000000;
  font-weight: normal;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  white-space: normal;
  background-color: #f8fafc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 31px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e7eb;
}

/* Remove the old spell-properties-row styles as they're no longer needed */
.spell-properties-row {
  display: none;
}

/* Empty table message */
.empty-table-row td {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #6b7280;
  background-color: #ffffff;
  font-family: 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

/* Center specific columns */
td:nth-child(3),  /* Level column */
td:nth-child(9),  /* Concentration column */
td:nth-child(10), /* Ritual column */
th:nth-child(3),  /* Level header */
th:nth-child(9),  /* Concentration header */
th:nth-child(10) { /* Ritual header */
  text-align: center;
}

/* Media queries for responsive design */
@media (max-width: 1200px) {
  th:nth-child(10), td:nth-child(10) { display: none; }
}

@media (max-width: 1100px) {
  th:nth-child(9), td:nth-child(9) { display: none; }
}

@media (max-width: 1000px) {
  th:nth-child(8), td:nth-child(8) { display: none; }
}

@media (max-width: 900px) {
  th:nth-child(7), td:nth-child(7) { display: none; }
}

@media (max-width: 800px) {
  th:nth-child(6), td:nth-child(6) { display: none; }
}

@media (max-width: 700px) {
  th:nth-child(5), td:nth-child(5) { display: none; }
}

@media (max-width: 600px) {
  th:nth-child(4), td:nth-child(4) { display: none; }
}

@media (max-width: 500px) {
  /* Switch to auto table layout for mobile */
  .spell-table-container table {
    table-layout: auto !important;
  }

  th:nth-child(3), 
  td:nth-child(3),
  th:nth-child(n+4), 
  td:nth-child(n+4) { 
    display: none; 
  }

  /* Name column styling */
  th:nth-child(2),
  td:nth-child(2) {
    width: auto !important;
    max-width: none !important;
    min-width: 0;
    padding-right: 55px;
  }

  /* Checkbox column locking */
  th:nth-child(1),
  td:nth-child(1) {
    width: 20px !important;
    min-width: 20px;
    max-width: 20px;
  }
}

/* Separator row styles */
.separator-row td {
  height: 20px;
  background-color: #f3f4f6;
  border: none;
  font-family: 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

/* Ritual spell styles */
tr.ritual {
  background-color: #f9fafb;
  font-style: italic;
  opacity: 0.8;
}

tr.ritual:hover {
  opacity: 1;
}

/* Cast spell icon styles */
.cast-spell-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cast-spell-icon svg {
  width: 100%;
  height: 100%;
  fill: #8A4FFF;
}

.cast-spell-icon:hover svg {
  fill: #7B3FEF;
}

/* Keep these styles for fixed column widths */
th:nth-child(1),
td:nth-child(1) {
  width: 20px;
}

th:nth-child(2),
td:nth-child(2) {
  width: 325px;
}

th:nth-child(3),
td:nth-child(3) {
  width: 45px;
}

th:nth-child(4),
td:nth-child(4) {
  width: 50px;
}

th:nth-child(5),
td:nth-child(5) {
  width: 90px;
}

th:nth-child(6),
td:nth-child(6) {
  width: 110px;
}

th:nth-child(7),
td:nth-child(7) {
  width: 100px;
}

th:nth-child(8),
td:nth-child(8) {
  width: 110px;
}

th:nth-child(9),
td:nth-child(9) {
  width: 50px;
}

th:nth-child(10),
td:nth-child(10) {
  width: 50px;
}

/* Base icon styles */
.spell-name-cell {
  position: relative;
  padding-right: 55px;
}

/* Default icon positions */
.info-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Specific positioning for SpellBook context */
.spellbook-table .spell-name-cell {
  padding-right: 75px;
}

.spellbook-table .info-icon {
  right: 47px;
  width: 22px;
  height: 22px;
}

.spellbook-table .remove-spell-icon {
  right: 26px;
}

.spellbook-table .prepare-spell-icon {
  right: 3px;
}

/* Ensure icons don't overlap */
.spellbook-table .info-icon,
.spellbook-table .remove-spell-icon,
.spellbook-table .prepare-spell-icon {
  width: 20px;
  height: 20px;
}

/* Ensure the SVGs fill their containers */
.info-icon svg,
.remove-spell-icon svg,
.prepare-spell-icon svg {
  width: 100%;
  height: 100%;
}

/* Update the specific positioning for SpellBook context */
.spellbook-table .info-icon {
  right: 47px;
  width: 22px;
  height: 22px;
}

.spellbook-table .remove-spell-icon,
.spellbook-table .prepare-spell-icon {
  width: 20px;
  height: 20px;
}

/* Add specific styling for PreparedSpells context */
.prepared-spells .info-icon {
  right: 47px;
  width: 24px;
  height: 24px;
}

.prepared-spells .remove-spell-icon,
.prepared-spells .prepare-spell-icon,
.prepared-spells .cast-spell-icon {
  width: 20px;
  height: 20px;
}

/* Update specific styling for PreparedSpells context */
.prepared-spells .info-icon {
  right: 47px;
  width: 24px;
  height: 24px;
}

.prepared-spells .cast-spell-icon {
  width: 24px;
  height: 24px;
  right: 42px;
}

.prepared-spells .remove-spell-icon,
.prepared-spells .prepare-spell-icon {
  width: 20px;
  height: 20px;
}

/* Update padding for prepared spells name cell to accommodate larger icons */
.prepared-spells .spell-name-cell {
  padding-right: 80px;
}

/* Update specific styling for PreparedSpells context with more specific selectors */
.prepared-spells.spell-list .info-icon {
  right: 26px !important;
  width: 22px !important;
  height: 22px !important;
}

.prepared-spells.spell-list .cast-spell-icon {
  width: 22px;
  height: 22px;
  right: 47px;
}

.prepared-spells.spell-list .prepare-spell-icon {
  right: 3px;
  width: 20px;
  height: 20px;
}

/* Update padding for prepared spells name cell to accommodate icons */
.prepared-spells.spell-list .spell-name-cell {
  padding-right: 85px;
}

/* Ensure SVGs fill their containers in prepared spells context */
.prepared-spells.spell-list .info-icon svg,
.prepared-spells.spell-list .cast-spell-icon svg {
  width: 100%;
  height: 100%;
}

/* Update the table controls styles */
.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8fafc;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  padding: 6px 15px;
  margin-bottom: 8px;
  height: 52px; /* Fixed height */
  min-height: 52px;
  width: 100%;
  box-sizing: border-box;
}

.table-controls-left {
  display: flex;
  align-items: center;
  gap: 30px;
  flex: 1;
}

.table-controls-right {
  display: flex;
  align-items: center;
}

/* Update search bar container in table controls */
.table-controls .search-bar {
  margin-top: 6px;
  position: relative;
  flex: 1;
  height: 24px;
  display: flex;
  align-items: center;
  min-width: 0; /* Add this to allow search bar to shrink */
}

.table-controls .search-bar input {
  height: 24px;
  padding: 0 8px;
  line-height: 24px;
  box-sizing: border-box;
  width: 100%; /* Ensure input takes full width of container */
  min-width: 0; /* Allow input to shrink */
}

/* Update spell counter styles */
.table-controls .spell-counter {
  background-color: white;
  padding: 4px 10px;
  border: 1px solid #3d5a80;
  border-radius: 3px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;
  color: #3d5a80;
  height: 32px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  white-space: nowrap; /* Prevent counter from wrapping */
}

/* Update responsive styles for table controls */
@media (max-width: 768px) {
  .table-controls {
    padding: 6px 12px; /* Reduced vertical padding */
    gap: 8px;
    margin: 0 0 8px 0;
    height: 52px; /* Keep fixed height */
    min-height: 52px;
  }

  .table-controls-left {
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: auto;
    flex: 1;
    height: 100%; /* Ensure full height */
  }

  .table-controls .search-bar {
    margin-top: 0;
    min-width: 120px;
    height: 32px; /* Match parent height */
    display: flex;
    align-items: center;
  }

  .table-controls .spell-counter {
    margin-left: auto;
    height: 32px; /* Match parent height */
  }
}

@media (max-width: 480px) {
  .table-controls {
    padding: 6px 8px;
    height: 52px; /* Keep fixed height */
  }

  .table-controls-left {
    gap: 8px;
  }
}

/* Update table container styles */
.spell-table-container {
  overflow-x: auto;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}

th, td {
  transition: width 0.1s ease;
}

.check-mark {
  color: #4CAF50;
  font-weight: bold;
  font-family: 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.x-mark {
  color: #f44336;
  font-weight: bold;
  font-family: 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.school-badge {
  padding: 1px 4px;
  border-radius: 10px;
  font-size: 0.85em;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  width: 35px;
  min-width: 35px;
  box-sizing: border-box;
  border: 1.5px solid;
  background-color: transparent;
  line-height: 1.2;
}

.school-abjuration {
  color: #3498db;
  border-color: #3498db;
}

.school-conjuration {
  color: #f1c40f;
  border-color: #f1c40f;
}

.school-divination {
  color: #95a5a6;
  border-color: #95a5a6;
}

.school-enchantment {
  color: #e91e63;
  border-color: #e91e63;
}

.school-evocation {
  color: #e74c3c;
  border-color: #e74c3c;
}

.school-illusion {
  color: #9b59b6;
  border-color: #9b59b6;
}

.school-necromancy {
  color: #2ecc71;
  border-color: #2ecc71;
}

.school-transmutation {
  color: #e67e22;
  border-color: #e67e22;
}

.header-badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: transparent;
  border: 1.5px solid #3d5a80;
  color: #3d5a80;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

th:nth-child(9):hover .header-badge,
th:nth-child(10):hover .header-badge {
  background-color: #3d5a80;
  color: white;
}

.remaining-spells-row td {
  text-align: center;
  padding: 8px;
  background-color: #f8fafc;
  border-top: 1px solid #e5e7eb;
  color: #6b7280;
  font-style: italic;
  font-size: 0.85em;
  font-family: 'Poppins', sans-serif;
}

.remaining-spells-row:hover {
  background-color: #f8fafc;
  cursor: default;
}

.spell-list th,
.spell-content__description th,
.spell-content__description table.spell-table th {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.header-badge {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.reset-columns-button {
  padding: 2px 6px;
  font-size: 0.75rem;
  background-color: white;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Poppins', sans-serif;
  line-height: 1.2;
}

.reset-columns-button:hover {
  background-color: #f8fafc;
  border-color: #2c4a6e;
  color: #2c4a6e;
}

/* Add margin to the search input container */
.table-controls .search-bar {
  margin-top: 10px; /* Increased from 2px to 4px */
}

/* Update responsive styles for table controls */
@media (max-width: 768px) {
  .table-controls {
    flex-direction: row;
    padding: 8px 12px;
    gap: 8px;
    margin: 0 0 8px 0;
  }

  .table-controls-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    width: 100%;
  }

  .table-controls .search-bar {
    flex: 1;
    min-width: 0; /* Allow search to shrink if needed */
  }

  .table-controls-right {
    display: none;
  }
}

@media (max-width: 480px) {
  .table-controls {
    padding: 6px 8px;
  }

  .table-controls-left {
    gap: 10px; /* Reduce gap on very small screens */
  }

  .table-controls .spell-counter {
    min-width: auto; /* Allow counter to shrink to content */
  }
}

/* Update the checkbox container spacing */
.checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 10px 0 12px !important; /* Added !important to override any other styles */
}

.checkbox-container label {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-family: 'Poppins', sans-serif;
  color: #4b5563;
  cursor: pointer;
  white-space: nowrap;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 5px;
}

/* Stack checkboxes on smaller screens with adjusted spacing */
@media (max-width: 768px) {
  .checkbox-container {
    flex-direction: column;
    gap: 8px;
    margin: 8px 0 10px !important; /* Added !important to override any other styles */
  }
}

/* Add these styles for custom spells */
tr.custom-spell {
  background-color: rgba(147, 51, 234, 0.05) !important; /* Light purple background */
}

tr.custom-spell:hover {
  background-color: rgba(147, 51, 234, 0.1) !important;
}

tr.custom-spell td,
tr.custom-spell .spell-name-cell a { /* Added this line to target the spell name link */
  color: #7e22ce; /* Purple text */
}

/* For the expanded row of custom spells */
tr.spell-properties-row.custom-spell {
  background-color: rgba(147, 51, 234, 0.03) !important;
}

tr.spell-properties-row.custom-spell:hover {
  background-color: rgba(147, 51, 234, 0.08) !important;
}

.no-sort {
  cursor: default !important;
}

.no-sort:hover {
  background-color: inherit !important;
}

.notes-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
  color: #3d5a80;
}

.notes-icon svg {
  width: 100%;
  height: 100%;
  fill: #3d5a80;
}

/* Make the prepare spell icon yellow in dark mode when prepared */
html[data-darkreader-scheme="dark"] .prepare-spell-icon.prepared svg,
html[data-darkreader-scheme="dark"] .prepared-spells .prepare-spell-icon.prepared svg,
html[data-darkreader-scheme="dark"] .spellbook-table .prepare-spell-icon.prepared svg,
html[data-darkreader-scheme="dark"] .spell-book .prepare-spell-icon.prepared svg {
  fill: #ffd700 !important; /* Bright gold/yellow color */
  filter: drop-shadow(0 0 3px rgba(255, 215, 0, 0.7)) !important;
}

html[data-darkreader-scheme="dark"] .prepare-spell-icon.prepared:hover svg,
html[data-darkreader-scheme="dark"] .prepared-spells .prepare-spell-icon.prepared:hover svg,
html[data-darkreader-scheme="dark"] .spellbook-table .prepare-spell-icon.prepared:hover svg,
html[data-darkreader-scheme="dark"] .spell-book .prepare-spell-icon.prepared:hover svg {
  fill: #ffcc00 !important; /* Slightly darker gold on hover */
}

/* Make the remove spell icon red in dark mode */
html[data-darkreader-scheme="dark"] .remove-spell-icon svg,
html[data-darkreader-scheme="dark"] .prepared-spells .remove-spell-icon svg,
html[data-darkreader-scheme="dark"] .spellbook-table .remove-spell-icon svg,
html[data-darkreader-scheme="dark"] .spell-book .remove-spell-icon svg {
  fill: #ff6b6b !important; /* Bright red color */
}

html[data-darkreader-scheme="dark"] .remove-spell-icon:hover svg,
html[data-darkreader-scheme="dark"] .prepared-spells .remove-spell-icon:hover svg,
html[data-darkreader-scheme="dark"] .spellbook-table .remove-spell-icon:hover svg,
html[data-darkreader-scheme="dark"] .spell-book .remove-spell-icon:hover svg {
  fill: #ff4040 !important; /* Darker red on hover */
  filter: drop-shadow(0 0 2px rgba(255, 64, 64, 0.5)) !important;
}