@font-face {
  font-family: 'Mondia Light';
  src: url('../../assets/fonts/Mondia Light/Mondia Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.printables-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
}

.printables-header {
  text-align: center;
  margin-bottom: 3rem;
}

.printables-header h1 {
  font-size: 2.5rem;
  color: #3d5a80;
  margin-bottom: 1rem;
  font-family: 'Mondia Light', sans-serif;
}

.printables-header p {
  font-size: 1.1rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
}

.printables-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.printable-card {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.printable-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.printable-info {
  flex: 1;
  padding: 2rem;
}

.printable-info h2 {
  font-size: 1.8rem;
  color: #3d5a80;
  margin-bottom: 1rem;
  font-family: 'Mondia Light', sans-serif;
}

.printable-info p {
  color: #555;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.printing-instructions {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-left: 4px solid #98c1d9;
}

.printing-instructions h3 {
  color: #3d5a80;
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-size: 1.3rem;
  font-family: 'Mondia Light', sans-serif;
}

.printing-instructions p {
  margin-bottom: 0.75rem;
}

.printable-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
}

.printable-features li {
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 0.75rem;
  color: #555;
}

.printable-features li::before {
  content: "•";
  color: #98c1d9;
  font-weight: bold;
  position: absolute;
  left: 0;
}

.printable-actions {
  margin-top: 1.5rem;
}

.download-button {
  display: inline-block;
  background-color: #3d5a80;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #2c3e50;
}

.printable-preview {
  flex: 1;
  min-height: 400px;
  background-color: #f8f9fa;
  border-left: 1px solid #eee;
}

.pdf-preview {
  width: 100%;
  height: 100%;
  min-height: 400px;
}

.printables-footer {
  margin-top: 3rem;
  text-align: center;
  color: #666;
}

.printables-footer a {
  color: #3d5a80;
  text-decoration: none;
}

.printables-footer a:hover {
  text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 992px) {
  .printable-card {
    flex-direction: column;
  }
  
  .printable-preview {
    border-left: none;
    border-top: 1px solid #eee;
  }
}

@media (max-width: 768px) {
  .printables-page {
    padding: 1.5rem;
  }
  
  .printables-header h1 {
    font-size: 2rem;
  }
  
  .printable-info {
    padding: 1.5rem;
  }
  
  .printing-instructions {
    padding: 1.25rem;
  }
}

@media (max-width: 480px) {
  .printables-header h1 {
    font-size: 1.8rem;
  }
  
  .printable-info h2 {
    font-size: 1.5rem;
  }
  
  .printing-instructions h3 {
    font-size: 1.2rem;
  }
} 