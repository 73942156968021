.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 15px;
  width: 100%;
}

.table-controls-left {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.table-controls-right {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.create-custom-spell-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 20px;
  width: auto;
  padding: 0 10px 0 12px;
  gap: 6px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0 !important;
}

.create-custom-spell-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.05);
  border-color: #2c4a6e;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.create-custom-spell-button svg {
  width: 24px;
  height: 24px;
  color: #3d5a80;
}

.create-custom-spell-button:hover svg {
  color: #2c4a6e;
}

.create-custom-spell-button .button-text {
  font-size: 14px;
  font-weight: 500;
  color: #3d5a80;
  white-space: nowrap;
}

.reset-columns-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  font-size: 1.2rem;
  flex-shrink: 0 !important;
}

.reset-columns-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.1);
  border-color: #2c4a6e;
}

@media (max-width: 1024px) {
  .table-controls {
    gap: 15px;
    padding: 10px 12px;
  }
  
  .search-counter-group {
    min-width: 380px;
    gap: 30px;
  }

  .table-controls-right {
    padding-right: 4px;
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .table-controls {
    display: flex;
    flex-wrap: nowrap;
    padding: 12px 12px;
    margin: 0 0 15px;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
  }

  .search-counter-group {
    flex: 0 1 auto;
    min-width: unset;
    margin-right: 15px;
  }

  .search-bar {
    flex: 0 0 240px !important;
    min-width: 180px !important;
    max-width: 240px !important;
    display: flex;
    align-items: center;
  }

  .search-bar input {
    height: 32px;
    min-height: 32px;
    max-height: 32px;
  }

  .table-controls-right {
    flex: 0 0 auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding-right: 4px;
  }

  .create-custom-spell-button {
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    padding: 0 !important;
    border-radius: 50% !important;
    margin: 0 !important;
    position: relative;
    top: 0;
  }

  .create-custom-spell-button .button-text {
    display: none;
  }

  .reset-columns-button {
    display: none;
  }

  .metric-toggle-button {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
  }

  .short-description-button {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
  }

  .toggle-buttons-group {
    margin-left: 10px;
    gap: 6px;
  }

  .ritual-spells-button {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
  }

  .advanced-filters-button {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    margin-right: 8px;
  }

  .desktop-only {
    display: none;
  }
  
  .mobile-only {
    display: flex;
  }
  
  .desktop-only-buttons {
    display: none;
  }
  
  .more-options-dropdown {
    right: auto;
    left: -175px;
  }
  
  .ritual-spells-button svg {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 480px) {
  .table-controls {
    padding: 12px;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }

  .search-counter-group {
    flex: 1;
    margin-right: 0;
    gap: 25px;
  }

  .table-controls-right {
    margin-left: 0;
  }

  .create-custom-spell-button {
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    padding: 0 !important;
    border-radius: 50% !important;
    flex: 0 0 36px !important;
  }

  .toggle-buttons-group {
    margin-left: 8px;
    gap: 5px;
  }

  .advanced-filters-button {
    margin-right: 6px;
  }

  .more-options-dropdown {
    width: 200px;
  }
}

@media (max-width: 360px) {
  .reset-columns-button {
    display: none;
  }
}

.search-counter-group {
  display: flex;
  align-items: center;
  gap: 30px !important;
  flex: 0 0 auto;
}

.search-bar {
  flex: 0 0 240px;
  min-width: 180px;
  display: flex;
  align-items: center;
}

.search-bar input {
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.table-controls-left {
  gap: 10px;
}

.metric-toggle-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  flex-shrink: 0 !important;
}

.metric-toggle-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.1);
  border-color: #2c4a6e;
}

.metric-toggle-button svg {
  width: 20px;
  height: 20px;
}

.metric-toggle-button.active {
  background-color: #e8f0f8;
  color: #3d5a80;
  border-color: #3d5a80;
}

.metric-toggle-button.active:hover {
  background-color: #d8e6f2;
}

.short-description-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  flex-shrink: 0 !important;
}

.short-description-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.1);
  border-color: #2c4a6e;
}

.short-description-button svg {
  width: 20px;
  height: 20px;
}

.short-description-button.active {
  background-color: #e8f0f8;
  color: #3d5a80;
  border-color: #3d5a80;
}

.short-description-button.active:hover {
  background-color: #d8e6f2;
}

.toggle-buttons-group {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 15px;
}

.ritual-spells-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  flex-shrink: 0 !important;
}

.ritual-spells-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.1);
  border-color: #2c4a6e;
}

.ritual-spells-button svg {
  width: 28px;
  height: 28px;
}

.ritual-spells-button.active {
  background-color: #e8f0f8;
  color: #3d5a80;
  border-color: #3d5a80;
}

.ritual-spells-button.active:hover {
  background-color: #d8e6f2;
}

.advanced-filters-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin-right: 0px;
  flex-shrink: 0 !important;
}

.advanced-filters-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.1);
  border-color: #2c4a6e;
}

.advanced-filters-button svg {
  width: 20px;
  height: 20px;
}

.advanced-filters-button.active {
  background-color: #e8f0f8;
  color: #3d5a80;
  border-color: #3d5a80;
}

.advanced-filters-button.active:hover {
  background-color: #d8e6f2;
}

.school-tags-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  flex-shrink: 0 !important;
}

.school-tags-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.1);
  border-color: #2c4a6e;
}

.school-tags-button svg {
  width: 24px;
  height: 24px;
}

.school-tags-button.active {
  background-color: #e8f0f8;
  color: #3d5a80;
  border-color: #3d5a80;
}

.school-tags-button.active:hover {
  background-color: #d8e6f2;
}

/* Desktop/Mobile visibility classes */
.desktop-only {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mobile-only {
  display: none;
}

/* More options dropdown styling */
.more-options-container {
  position: relative;
}

.more-options-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  flex-shrink: 0 !important;
}

.more-options-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.05);
  border-color: #2c4a6e;
}

.more-options-button svg {
  width: 20px;
  height: 20px;
}

.more-options-dropdown {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 220px;
  z-index: 100;
  padding: 8px 0;
}

.dropdown-option {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-option:hover {
  background-color: #f0f4f8;
}

.dropdown-option svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #3d5a80;
}

.dropdown-option span {
  font-size: 14px;
  color: #2c3e50;
}

/* Responsiveness for mobile */
@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }
  
  .mobile-only {
    display: flex;
  }
  
  .more-options-dropdown {
    right: auto;
    left: -175px;
  }
}

@media (max-width: 480px) {
  .more-options-dropdown {
    width: 200px;
  }
}

.dropdown-divider {
  height: 1px;
  background-color: #e1e4e8;
  margin: 6px 15px;
}

.desktop-only-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
} 