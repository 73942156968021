@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.footer {
  background-color: #293241; /* Changed to the new color */
  color: #f2f2f2;
  padding: 2rem 1rem;
  font-family: 'Poppins', sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  margin-bottom: 1rem;
  min-width: 200px;
}

.footer-section h4 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: #EE6C4D; /* Changed from #61dafb to #EE6C4D */
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: #f2f2f2;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #EE6C4D; /* Changed from #61dafb to #EE6C4D for consistency */
}

.social-links {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.social-link i {
  width: 1.2rem;
  color: #EE6C4D;
}

.social-link svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #EE6C4D;
  color: #EE6C4D;
  min-width: 1.2rem;
}

.footer-bottom {
  width: 100%;
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid var(--border-color);
  margin-top: 20px;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.wizards-disclaimer {
  margin-top: 8px !important;
  font-size: 0.75rem !important;
  color: var(--text-tertiary) !important;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4;
  text-align: center;
}

.wizards-disclaimer a {
  color: #EE6C4D;
  text-decoration: none;
  transition: color 0.3s ease;
}

.wizards-disclaimer a:hover {
  color: #f7a496;
  text-decoration: underline;
}