.spell-notes {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e0e0e0;
}

/* Create a container for the notes content */
.spell-notes__container {
  max-width: 450px;
  margin: 0;
}

.spell-notes__header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  max-width: 450px;
}

.spell-notes h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
  font-size: 1.2rem;
  padding-bottom: 5px;
}

.spell-notes__button {
  padding: 4px 10px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  background-color: white;
  color: #3d5a80;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  height: fit-content;
}

.spell-notes__button:hover {
  background-color: #3d5a80;
  color: white;
}

.spell-notes__button--save {
  background-color: #3d5a80;
  color: white;
  margin-right: 8px;
}

.spell-notes__button--save:hover {
  background-color: #2d4a70;
}

.spell-notes__button--cancel {
  background-color: white;
  color: #666;
  border-color: #666;
}

.spell-notes__button--cancel:hover {
  background-color: #f0f0f0;
}

.spell-notes__button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.spell-notes__textarea {
  width: 450px;
  min-width: 200px;
  max-width: none;
  min-height: 150px;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  resize: both;
  margin: 0;
  overflow: auto;
}

.spell-notes__textarea:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

.spell-notes__content {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  min-height: 100px;
  white-space: pre-wrap;
  width: 450px;
  margin: 0;
}

.spell-notes__login-message {
  color: #666;
  font-style: italic;
}

.spell-notes__button-group {
  display: flex;
  gap: 8px;
}

@media (max-width: 768px) {
  .spell-notes__textarea,
  .spell-notes__content,
  .spell-notes__header {
    width: calc(100% - 1rem);
  }
} 