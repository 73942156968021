.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #4b5563;
  font-family: 'Poppins', sans-serif;
}

.about-container h1 {
  color: #3d5a80;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.5rem;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.about-section {
  margin-bottom: 3rem;
  background: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid #98c1d9;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.about-section h2 {
  color: #3d5a80;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #3d5a80;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.8rem;
}

.about-section p {
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #4b5563;
}

.about-section ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.about-section li {
  margin-bottom: 0.5rem;
  line-height: 1.6;
  color: #4b5563;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.feature-card {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  height: auto;
}

.feature-card:hover {
  transform: translateY(-3px);
  border-color: #3d5a80;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.feature-card h3 {
  color: #3d5a80;
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #edf2f7;
}

.feature-card p {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #4b5563;
  margin: 0;
}

@media (max-width: 768px) {
  .about-container {
    padding: 1rem;
  }

  .about-container h1 {
    font-size: 2rem;
  }

  .about-section {
    padding: 1.5rem;
  }

  .feature-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }
}

@media (max-width: 1024px) {
  .feature-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media (max-width: 640px) {
  .feature-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .feature-card {
    padding: 1.25rem;
  }
} 