/* Add this import at the top of the file */
@font-face {
  font-family: 'Metrophobic';
  src: url('../../assets/fonts/Metrophobic/Metrophobic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Remove the .spell-book class styles as they're causing the conflict */
/* Instead, use the same container structure as other components */

.spellbook-management {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0; /* Reduced padding since padding is now in route-content */
  box-sizing: border-box;
  position: relative;
  /* No background color needed here as it's set on the route-content container */
}

.create-spellbook-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto 20px;
  padding: 0 20px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}

.create-section {
  display: flex;
  gap: 10px;
  width: 100%;
}

.create-section input {
  flex-grow: 1;
  padding: 7px 10px;
  font-size: 16px;
  border: 1px solid #3d5a80;
  border-radius: 3px;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  background-color: white;
  color: #000000;
  max-width: 180px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-section input::placeholder {
  color: #98c1d9;
  opacity: 0.8;
}

.create-section button {
  padding: 7px 12px;
  font-size: 16px;
  background-color: #3d5a80;
  color: white;
  border: 1px solid #98c1d9;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 52px;
  text-align: center;
  line-height: 1.2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-section button:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.create-section button::after {
  content: 'Spellbook';
  display: block;
}

.spellbook-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: visible;
  align-self: stretch;
  margin: 0 0 30px 0;
  padding: 0;
  box-sizing: border-box;
  z-index: 1;
  border-bottom: 1px solid #98c1d9;
}

.spellbook-item {
  display: flex;
  align-items: stretch;
  transition: all 0.3s ease;
  cursor: move;
  margin-left: 4px;
}

.spellbook-item:first-child {
  margin-left: 0;
}

.spellbook-item.dragging {
  opacity: 0.5;
  background: #f0f0f0;
}

/* Optional: Add a visual indicator for the drop zone */
.spellbook-item.drag-over {
  border: 2px dashed #3d5a80;
}

/* Ensure the buttons inside don't trigger drag */
.spellbook-select,
.spellbook-remove {
  cursor: pointer;
}

.spellbook-select {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 8px;
  font-size: 16px;
  background-color: #f9fafb;
  border: 1px solid #98c1d9;
  border-bottom: 1px solid #98c1d9;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: #3d5a80;
  white-space: nowrap;
  position: relative;
  font-weight: normal;
  margin-bottom: -1px;
  height: 26px;
  top: 4px;
}

.spellbook-select.active {
  background-color: #fff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-bottom: 1px solid white;
  position: relative;
  z-index: 3;
  font-weight: bold;
  padding: 8px 12px;
  font-size: 18px;
  margin-top: -2px;
  margin-bottom: -1px;
  height: 32px;
  top: 0;
}

.spellbook-select svg {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.spellbook-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  font-size: 13px;
  background-color: #f9fafb;
  color: #98c1d9;
  border: 1px solid #98c1d9;
  border-left: none;
  border-bottom: 1px solid #98c1d9;
  border-radius: 0 4px 0 0;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 26px;
  margin-bottom: -1px;
  position: relative;
  top: 4px;
}

.spellbook-remove:hover {
  background-color: #fee2e2;
  color: #ef4444;
}

.spellbook-remove svg {
  width: 16px;
  height: 16px;
}

/* Add this new style to create a line under the tabs */
.spellbook-list::after {
  display: none;
}

.spell-list {
  width: 100%;
  overflow-x: auto; /* Allow horizontal scrolling */
  padding: 0; /* No padding */
}

.spell-list table {
  width: 100%; /* Full width */
  margin: 0; /* No margin */
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
}

.spell-list table select {
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: #f9fafb;
  color: #4b5563;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Metamorphous', cursive;
}

.spell-list table select:hover {
  background-color: #e5e7eb;
}

.spell-list table select:focus {
  outline: none;
  border-color: #9ca3af;
  box-shadow: 0 0 0 2px rgba(156, 163, 175, 0.3);
}

.spell-list table td {
  position: relative;
}

.prepare-spell-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.prepare-spell-icon svg {
  width: 100%;
  height: 100%;
  fill: #4b5563;
  transition: all 0.3s ease;
}

.prepare-spell-icon:hover svg {
  fill: #1f2937;
}

.prepare-spell-icon.prepared svg {
  fill: #fbbf24; /* Shining yellow/orange color */
  filter: drop-shadow(0 0 2px rgba(251, 191, 36, 0.5));
}

.prepare-spell-icon.prepared:hover svg {
  fill: #f59e0b; /* Darker orange on hover */
}

/* Make the prepare spell icon yellow in dark mode when prepared */
html[data-darkreader-scheme="dark"] .prepare-spell-icon.prepared svg {
  fill: #ffd700 !important; /* Bright gold/yellow color */
  filter: drop-shadow(0 0 3px rgba(255, 215, 0, 0.7)) !important;
}

html[data-darkreader-scheme="dark"] .prepare-spell-icon.prepared:hover svg {
  fill: #ffcc00 !important; /* Slightly darker gold on hover */
}

/* Add this new style to create a line under the tabs */
.unprepare-spell-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
}

.unprepare-spell-icon svg {
  width: 100%;
  height: 100%;
  fill: #4b5563;
}

.unprepare-spell-icon:hover svg {
  fill: #1f2937;
}

/* Updated styles for the checkboxes */
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #9ca3af; /* Darker border color */
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: white;
}

input[type="checkbox"]:checked::before {
  content: '\2714'; /* Unicode character for a checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: black;
}

input[type="checkbox"]:hover {
  border-color: #6b7280; /* Even darker on hover */
}

/* Adjust the existing styles for checkbox cells */
.spell-list .checkbox-cell {
  width: 30px;
  text-align: center;
  vertical-align: middle;
}

.spell-list .checkbox-cell input[type="checkbox"] {
  margin: 0;
  vertical-align: middle;
}

/* Add this new style to align the spellbook list with the table */
.spell-list {
  width: 100%;
  overflow-x: auto; /* Allow horizontal scrolling on mobile */
}

.spell-list table {
  width: 100%;
  margin: 0; /* Ensure no margin is added */
}

/* Adjust the existing styles for the spellbook list to match the table width */
.spellbook-list::after {
  width: 100%;
}

.floating-buttons {
  position: fixed;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.98);
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid #98c1d9;
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
}

.floating-close-button {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #3d5a80;
  color: white;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  z-index: 1001;
}

.floating-close-button:hover {
  background-color: #2c4a6e;
  transform: scale(1.1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.floating-button {
  background-color: #3d5a80;
  border: 1px solid #98c1d9;
  color: white;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  white-space: nowrap;
  text-align: center;
  min-width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.floating-button:hover {
  background-color: #2c4a6e;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.remove-button {
  background-color: #3d5a80;
}

.remove-button:hover {
  background-color: #2c4a6e;
}

.generate-cards-button {
  background-color: #edf3f8;
  border: 1px solid #3d5a80;
  color: #3d5a80;
}

.generate-cards-button:hover {
  background-color: #dde9f3;
}

@media (max-width: 768px) {
  .floating-buttons {
    width: 90%;
    max-width: 300px;
  }

  .floating-button {
    min-width: unset;
    width: 100%;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e5e7eb;
  color: #4b5563;
  border: 1px solid #d1d5db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
}

.back-to-top:hover {
  background-color: #d1d5db;
}

.back-to-top svg {
  width: 28px;
  height: 28px;
}

.spell-name-cell {
  position: relative;
  padding-right: 75px; /* Increased padding to accommodate three icons */
}

.info-icon {
  position: absolute;
  right: 47px; /* Adjusted position for three icons */
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.remove-spell-icon {
  position: absolute;
  right: 26px; /* Moved 1px to the left */
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.prepare-spell-icon {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Ensure the SVGs fill their container */
.info-icon svg,
.remove-spell-icon svg,
.prepare-spell-icon svg {
  width: 100%;
  height: 100%;
}

.remove-spell-icon:hover svg {
  fill: #B80000; /* Darker shade on hover */
}

/* Make the remove spell icon red in dark mode */
html[data-darkreader-scheme="dark"] .remove-spell-icon svg {
  fill: #ff6b6b !important; /* Bright red color */
}

html[data-darkreader-scheme="dark"] .remove-spell-icon:hover svg {
  fill: #ff4040 !important; /* Darker red on hover */
  filter: drop-shadow(0 0 2px rgba(255, 64, 64, 0.5)) !important;
}

.prepare-spell-icon.prepared svg {
  fill: #fbbf24;
  filter: drop-shadow(0 0 2px rgba(251, 191, 36, 0.5));
}

.prepare-spell-icon.prepared:hover svg {
  fill: #f59e0b;
}

/* Add these styles to the existing CSS file */

.spell-book .spell-list th:nth-child(1),
.spell-book .spell-list td:nth-child(1) {
  width: 20px;
}

.spell-book .spell-list th:nth-child(2),
.spell-book .spell-list td:nth-child(2) {
  width: 230px;
}

/* Update the existing style */
.spell-book .spell-list tbody tr.description-row td {
  padding: 5px 10px;
  border-top: none;
  color: #333;
  font-size: 0.95em;
  line-height: 1.4;
  white-space: normal;
}

/* Add this new style */
.spell-book .spell-list tbody tr.spell-row.expanded {
  background-color: #f8fafc; /* Lighter background */
}

.spell-book .spell-list tbody tr.spell-row.expanded .short-description {
  text-align: left;
  padding: 10px;
  font-size: 0.95em;
  line-height: 1.3;
  color: #000000 !important;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.spell-book .spell-list tbody tr.spell-properties-row {
  background-color: #f8fafc;
  font-size: 0.8em;
  color: #4a4a4a; /* Darker color for less faded look */
}

.spell-book .spell-list tbody tr.spell-properties-row td {
  padding: 3px 8px;
  opacity: 0.9; /* Increased opacity for less faded effect */
}

.remove-spell-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px; /* Adjusted from 25px to 30px to move it left */
  width: 16px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.remove-spell-icon svg {
  width: 100%;
  height: 100%;
  fill: #EB0100; /* Use the original color from the SVG */
}

.remove-spell-icon:hover svg {
  fill: #B80000; /* Darker shade on hover */
}

/* Make the remove spell icon red in dark mode */
html[data-darkreader-scheme="dark"] .remove-spell-icon svg {
  fill: #ff6b6b !important; /* Bright red color */
}

html[data-darkreader-scheme="dark"] .remove-spell-icon:hover svg {
  fill: #ff4040 !important; /* Darker red on hover */
  filter: drop-shadow(0 0 2px rgba(255, 64, 64, 0.5)) !important;
}

.spell-book .info-icon {
  position: absolute;
  right: 47px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.spell-book .info-icon svg {
  width: 100%;
  height: 100%;
}

.search-and-conversion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 10px;
}

.search-and-count {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  background-color: transparent; /* Add this line */
}

/* Add these styles to the end of the file */

.spell-book .filter-group select {
  width: auto;
  min-width: 80px;
  padding: 5px 8px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85em;
  color: #3d5a80;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 16px auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.spell-book .filter-group select[name="class"] {
  min-width: 75px; /* Increased from 70px to 75px */
  width: 75px; /* Increased from 70px to 75px */
}

.spell-book .filter-group select[name="level"] {
  min-width: 65px;
  width: 65px;
}

/* Ensure other dropdowns maintain their size */
.spell-book .filter-group select {
  min-width: 80px;
}

.spell-book .filter-group select[name="concentration"] {
  min-width: 140px; /* Increased from 120px to 140px */
  width: 140px; /* Increased from 120px to 140px */
}

.spell-book .filter-group select[name="ritual"] {
  min-width: 80px;
  width: 80px;
}

/* Update the filter dropdown options (Firefox only) */
.spell-book .filter-group select option {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.85em;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 10px 0 12px !important;
  justify-content: flex-start;
  align-items: center;
}

.checkbox-group {
  display: flex;
  gap: 20px;
}

.create-custom-spell-button {
  margin-left: auto;
}

/* Stack checkboxes on smaller screens with adjusted spacing */
@media (max-width: 768px) {
  .checkbox-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .checkbox-group {
    flex-direction: column;
    gap: 8px;
  }

  .create-custom-spell-button {
    margin-top: 8px;
    width: 100%;
  }
}

/* Add this new style for table headers */
.spell-book .spell-list th {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: #3d5a80;
  font-weight: bold;
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 10px 8px;
  background-color: #f8fafc;
  border-bottom: none;
}

.spell-book .spell-list th:hover {
  background-color: #e2eef9;
}

.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3); /* Changed from 0.4 to 0.3 for even less darkness */
  backdrop-filter: blur(5px);
  z-index: 999;
  cursor: pointer;
}

.spell-info-box {
  z-index: 1000;
}

/* Add this new style for the table header */
.spell-book .spell-list thead {
  border: 1px solid #3D5A80; /* Changed from #98C1D9 to #3D5A80 */
  border-bottom: none; /* Remove bottom border as it will be added to th elements */
  border-radius: 4px 4px 0 0; /* Add rounded corners to the top */
  overflow: hidden; /* Ensure the rounded corners are visible */
}

.spell-book .spell-list th:first-child {
  border-top-left-radius: 4px; /* Round the top-left corner of the first header cell */
}

.spell-book .spell-list th:last-child {
  border-top-right-radius: 4px; /* Round the top-right corner of the last header cell */
}

.spell-book .spell-list th {
  /* ... existing styles ... */
  border-bottom: 1px solid #3D5A80; /* Changed from #98C1D9 to #3D5A80 */
}

/* Update this existing style */
.spell-book .spell-list table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #3D5A80; /* Changed from #98C1D9 to #3D5A80 */
  border-radius: 4px;
  overflow: hidden;
  empty-cells: show; /* Ensure empty cells are rendered */
}

.spell-book .spell-list thead {
  border-bottom: 1px solid #3D5A80; /* Add a bottom border to the thead */
}

.spell-book .spell-list th {
  border-bottom: none; /* Remove individual bottom borders from th elements */
}

/* Add this new style to ensure consistent appearance when table is empty */
.spell-book .spell-list tbody:empty {
  display: none; /* Hide empty tbody */
}

.spell-book .spell-list tbody:empty + tfoot::before {
  content: '';
  display: table-row;
  height: 1px; /* Minimal height to prevent collapsing */
}

/* Update this existing style */
.spell-book .spell-list table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #3D5A80; /* Changed from #98C1D9 to #3D5A80 */
  border-radius: 4px;
  overflow: hidden;
  empty-cells: show; /* Ensure empty cells are rendered */
}

/* Update the table header styles */
.spell-book .spell-list thead {
  background-color: #f8fafc;
}

.spell-book .spell-list th {
  padding: 10px 8px;
  font-family: 'Poppins', sans-serif;
  color: #3d5a80;
  font-weight: 600;
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background-color: #f8fafc;
  border-bottom: 1px solid #3D5A80; /* Add this line to ensure the border */
}

/* Add this new style to create a solid border under the entire header row */
.spell-book .spell-list thead::after {
  content: '';
  display: block;
  height: 1px;
  background-color: #3D5A80; /* Changed from #98C1D9 to #3D5A80 */
  position: relative;
  top: -1px; /* Adjust this value if needed to fine-tune the position */
}

/* Ensure the table has the correct border and spacing */
.spell-book .spell-list table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #3D5A80; /* Changed from #98C1D9 to #3D5A80 */
  border-radius: 4px;
  overflow: hidden;
}

/* Add or update this style in SpellBook.css */
.spell-book .empty-table-row td {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #6b7280;
  background-color: #ffffff;
}

/* Add this new style for table cells */
.spell-book .spell-list td {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  padding: 5px 8px;
}

/* Spell name cell specific styling - override the font */
.spell-book .spell-list td:nth-child(2) {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

/* Remove or comment out this style if it exists */
/*
.spell-book .spell-list td {
  font-family: 'Metrophobic', sans-serif;
  font-size: 0.9rem;
}
*/

.spell-name-cell {
  position: relative;
  padding-right: 75px; /* Increased padding to accommodate three icons */
}

.info-icon {
  position: absolute;
  right: 47px; /* Adjusted position for three icons */
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.remove-spell-icon {
  position: absolute;
  right: 26px; /* Moved 1px to the left */
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.prepare-spell-icon {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Ensure the SVGs fill their container */
.info-icon svg,
.remove-spell-icon svg,
.prepare-spell-icon svg {
  width: 100%;
  height: 100%;
}

/* Add these new styles */
.spell-book .spell-list td:nth-child(3),  /* Level column */
.spell-book .spell-list td:nth-child(9),  /* Concentration column */
.spell-book .spell-list td:nth-child(10) { /* Ritual column */
  text-align: center;
}

/* Center the headers for these columns as well */
.spell-book .spell-list th:nth-child(3),
.spell-book .spell-list th:nth-child(9),
.spell-book .spell-list th:nth-child(10) {
  text-align: center;
}

/* Update this existing style */
.spell-book .spell-list tbody tr.spell-properties-row {
  background-color: #f8fafc;
  font-size: 0.8em;
  color: #4a4a4a;
}

.spell-book .spell-list tbody tr.spell-properties-row td {
  padding: 3px 8px 1px; /* Reduced bottom padding */
  opacity: 0.9;
  vertical-align: top; /* Align content to the top */
}

/* Add this new style for the expanded row */
.spell-book .spell-list tbody tr.spell-row.expanded td {
  padding-bottom: 1px; /* Reduce bottom padding */
}

.spell-book .spell-list tbody tr.spell-row.expanded + tr.spell-properties-row td {
  padding-top: 1px; /* Reduce top padding */
}

/* Add this new style for the login warning */
.login-warning {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 0.9rem;
  line-height: 1.4;
}

/* ... (rest of the existing styles) */

/* Add media queries to hide columns based on screen width, excluding the level column */
@media (max-width: 1200px) {
  .spell-book .spell-list th:nth-child(10),
  .spell-book .spell-list td:nth-child(10) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .spell-book .spell-list th:nth-child(9),
  .spell-book .spell-list td:nth-child(9) {
    display: none;
  }
}

@media (max-width: 1000px) {
  .spell-book .spell-list th:nth-child(8),
  .spell-book .spell-list td:nth-child(8) {
    display: none;
  }
}

@media (max-width: 900px) {
  .spell-book .spell-list th:nth-child(7),
  .spell-book .spell-list td:nth-child(7) {
    display: none;
  }
}

@media (max-width: 800px) {
  .spell-book .spell-list th:nth-child(6),
  .spell-book .spell-list td:nth-child(6) {
    display: none;
  }
}

@media (max-width: 700px) {
  .spell-book .spell-list th:nth-child(5),
  .spell-book .spell-list td:nth-child(5) {
    display: none;
  }
}

@media (max-width: 600px) {
  .spell-book .spell-list th:nth-child(4),
  .spell-book .spell-list td:nth-child(4) {
    display: none;
  }
}

/* Ensure the level column (nth-child(3)) is not hidden */

/* Update this existing style */
.spell-list {
  width: 100%;
  overflow-x: auto;
}

/* Update this existing style */
.spell-list table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #3D5A80; /* Changed from #98C1D9 to #3D5A80 */
  border-radius: 4px;
  overflow: hidden;
  table-layout: fixed;
  font-size: 0.85rem;
  empty-cells: show;
}

/* Add these media queries at the end of the file */
@media (max-width: 1200px) {
  .spell-book {
    padding: 0 10px; /* Reduce padding on smaller screens */
  }
}

@media (max-width: 768px) {
  .spell-book {
    padding: 0;
    margin: 0;
  }
  
  .spellbook-list {
    margin-bottom: 20px; /* Slightly reduced margin on smaller screens */
  }
}

@media (max-width: 480px) {
  .spellbook-list {
    margin-bottom: 15px; /* Further reduced margin on mobile */
  }
}

.create-spellbook-form .generate-button {
  padding: 7px 12px;
  font-size: 16px;
  background-color: #80a1bb;
  color: white;
  border: 1px solid #3d5a80;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 52px;
  text-align: center;
  line-height: 1.2;
  align-self: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-spellbook-form .generate-button::after {
  content: 'Generator';
  display: block;
}

.create-spellbook-form .generate-button:hover {
  background-color: #80a1bb;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

@media (max-width: 640px) {
  .create-spellbook-form .generate-button {
    width: 100%;
  }
}

.generator-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 90%;
  max-width: 500px;
}

/* Update the existing blur-overlay style */
.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  z-index: 999;
  cursor: pointer;
}

/* Add responsive styles */
@media (max-width: 640px) {
  .create-spellbook-form {
    padding: 0 10px;
  }

  .create-section {
    flex-direction: column;
    align-items: center;
  }

  .create-section input,
  .create-section button,
  .create-spellbook-form .generate-button {
    width: 100%;
    max-width: none;
  }

  .create-spellbook-form .generate-button {
    width: 100%;
    padding: 10px 16px;
  }
}

/* Add media queries for responsive padding */
@media (max-width: 768px) {
  .spellbook-management {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .spellbook-management {
    padding: 0 3px;
  }
}

/* Add these styles */
.book-icon {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.book-icon svg {
  width: 16px;
  height: 16px;
  fill: #3d5a80; /* Use the exact same dark blue as the text */
  transition: all 0.3s ease;
}

.book-icon.active svg {
  fill: #fbbf24; /* Restore the shining yellow color for active tabs */
  filter: drop-shadow(0 0 2px rgba(251, 191, 36, 0.5)); /* Add subtle glow effect */
}

/* Make the active book icon yellow in dark mode */
html[data-darkreader-scheme="dark"] .book-icon.active svg {
  fill: #ffd700 !important; /* Bright gold/yellow color */
  filter: drop-shadow(0 0 3px rgba(255, 215, 0, 0.7)) !important;
}

/* Update the existing spellbook-select style to ensure proper icon alignment */
.spellbook-select {
  display: flex;
  align-items: center;
  gap: 5px;
  /* ... rest of the existing styles ... */
}

/* Add these styles */
.spellbook-controls-header {
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  padding: 15px 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.spellbook-controls-header h2 {
  color: #3d5a80;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
}

.spellbook-creation {
  display: flex;
  align-items: center;
  gap: 15px;
}

.spellbook-input-group {
  display: flex;
  gap: 10px;
}

.spellbook-name-input {
  width: 200px;
  flex: none;
  padding: 8px 12px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  color: #3d5a80;
  background-color: white;
}

/* Add placeholder styles */
.spellbook-name-input::placeholder {
  color: #98c1d9;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
}

/* Add hover state to match other inputs */
.spellbook-name-input:hover {
  border-color: #2c4a6e;
}

/* Add focus styles to match other inputs */
.spellbook-name-input:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

.create-spellbook-button,
.generator-button {
  padding: 8px 16px;
  background-color: #3d5a80;
  color: white;
  border: none;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-spellbook-button:hover,
.generator-button:hover {
  background-color: #2c4a6e;
}

.create-spellbook-button:disabled,
.generator-button:disabled {
  background-color: #cbd5e1;
  cursor: not-allowed;
}

/* Add responsive styles */
@media (max-width: 768px) {
  .spellbook-controls-header {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }

  .spellbook-creation {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .spellbook-input-group {
    width: 100%;
  }

  .spellbook-name-input {
    flex: 1;
    width: auto;
  }

  .generator-button {
    width: 100%;
  }
}

/* Update container styles */
.spellbook-controls-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 30px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0;
}

.spellbook-controls-box {
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  text-align: center;
  min-width: 0;
  width: 100%;
  box-sizing: border-box;
}

.spellbook-controls-box h2 {
  color: #3d5a80;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.spellbook-input-group {
  display: flex;
  gap: 10px;
  width: auto;
  justify-content: center;
}

.spellbook-name-input {
  width: 200px;
  flex: none;
}

.generator-button {
  width: auto;
  min-width: 200px;
}

/* Update responsive styles */
@media (max-width: 768px) {
  .spellbook-controls-container {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 0;
    margin: 0 auto 20px auto;
  }

  .spellbook-controls-box {
    max-width: 100%;
    margin: 0 auto;
    padding: 15px 10px;
    display: flex;
    align-items: center;
  }

  .spellbook-input-group {
    max-width: 100%;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  /* Add a shared width for all interactive elements */
  .spellbook-name-input,
  .create-spellbook-button,
  .generator-button {
    width: 100%;
    max-width: 200px; /* Reduce from 300px to 200px */
    min-width: 0;
    box-sizing: border-box;
    margin: 0 auto; /* Center the elements */
  }
}

@media (max-width: 480px) {
  .spellbook-controls-box {
    padding: 12px 8px;
  }
}

/* Add these styles if not present */
.active-spellbook-header {
  background-color: #f8fafc;
  border: 1px solid #98C1D9;
  border-radius: 4px;
  padding: 15px 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.system-toggle-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Add responsive styles */
@media (max-width: 768px) {
  .active-spellbook-header {
    gap: 10px;
    padding: 12px 15px;
  }
  
  .system-toggle-container {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
  
  .spellbook-selector {
    flex-grow: 1;
  }
}

.spellbook-selector {
  width: 300px;
  padding: 8px 12px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  color: #3d5a80;
  background-color: white;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 20px;
}

@media (max-width: 768px) {
  .active-spellbook-header {
    flex-direction: column;
    padding: 12px 15px;
    gap: 8px;
  }

  .spellbook-selector {
    width: 100%;
  }
}

/* Update the input group and button styles */
.spellbook-controls-box .spellbook-input-group {
  display: flex;
  gap: 10px;
  width: auto;
  justify-content: center;
}

.spellbook-controls-box .spellbook-name-input {
  width: 200px;
  flex: none;
}

.spellbook-controls-box .generator-button {
  width: auto;
  min-width: 200px;
}

/* Update mobile styles */
@media (max-width: 768px) {
  .spellbook-controls-box .spellbook-name-input,
  .spellbook-controls-box .generator-button {
    width: 100%;
  }
}

/* Update this style */
.clear-filters {
  margin-left: auto;
  padding: 5px 10px;
  background-color: #3d5a80;
  color: #ffffff;
  border: none; /* Removed the border */
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9em;
  transition: all 0.3s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Add this to the existing styles */
.generate-cards-button {
  background-color: #3d5a80;
}

.generate-cards-button:hover {
  background-color: #2c4a6e;
}
