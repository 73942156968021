/* Add these at the top of the file, outside media query */
html, body {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 768px) {
  /* Remove these as they're now handled globally */
  /* body {
    overflow-x: hidden;
  } */

  .main-nav {
    position: relative;
    width: 100%;
    overflow: visible; /* Change from hidden to visible */
  }

  .wrapper {
    position: relative;
    width: 100%;
    overflow: visible; /* Change from hidden to visible */
  }

  /* Update the nav-links to use viewport units */
  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100vw; /* Use viewport width */
    max-width: 350px;
    top: 0;
    left: 0;
    background: #293241;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1),
                opacity 0.3s ease;
    z-index: 1002;
    opacity: 0;
    transform: translateX(-100%);
    pointer-events: none;
  }

  .wrapper .nav-links.active {
    opacity: 1;
    transform: translateX(0);
    pointer-events: all;
  }

  .nav-links li {
    margin: 15px 10px;
    position: relative;
  }

  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }

  .nav-links .desktop-item {
    display: none;
  }

  .nav-links .mobile-item {
    display: block;
    color: #E0FBFC;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
    position: relative;
    padding-right: 30px;
  }

  .mobile-item::after {
    content: '+';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all 0.3s ease;
    line-height: 1;
  }

  input:checked ~ .mobile-item::after {
    transform: translateY(-50%) rotate(45deg);
  }

  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    box-shadow: none;
    transition: max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
    opacity: 0;
  }

  #showSpells:checked ~ .drop-menu,
  #showTools:checked ~ .drop-menu,
  #showMore:checked ~ .drop-menu,
  #showAccount:checked ~ .drop-menu {
    max-height: 300px;
    opacity: 1;
  }

  .nav-links .mobile-item:hover {
    background: #3D5A80;
  }

  /* Mobile Account styles */
  .nav-links .account-item {
    width: 100%;
    height: auto;
    margin: 15px 10px;
    display: block;
  }

  .account-item .desktop-item {
    display: none;
  }

  .account-item .mobile-item {
    display: block;
    color: #E0FBFC;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 20px;
    padding-right: 30px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
    position: relative;
  }

  .account-item .mobile-item::after {
    content: '+';
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all 0.3s ease;
  }

  .account-item .drop-menu {
    position: static;
    width: 100%;
    opacity: 0;
    visibility: visible;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
    padding-left: 20px;
    background: transparent;
    box-shadow: none;
    line-height: normal;
  }

  #showAccount:checked ~ .drop-menu {
    max-height: 300px;
    opacity: 1;
  }

  /* Mobile Login styles */
  .nav-links .login-item {
    margin-left: 0;
  }

  .nav-links .login-item a {
    background-color: transparent;
    color: #E0FBFC;
    padding: 0 20px;
    font-size: 18px;
  }

  .nav-links .login-item a:hover {
    background-color: #3D5A80;
    color: #EE6C4D;
  }

  /* Hamburger menu styles */
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger div {
    width: 25px;
    height: 3px;
    background-color: #E0FBFC;
    margin: 5px 0;
    transition: all 0.3s ease;
  }

  /* Close button styles */
  .close-menu-btn {
    display: block;
    background: none;
    border: none;
    color: #E0FBFC;
    font-size: 32px;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    padding: 5px 10px;
  }

  .close-icon {
    display: block;
    line-height: 1;
  }

  .wrapper .nav-links {
    padding-top: 60px;
  }

  /* Update account dropdown items to match others */
  .account-item .drop-menu li a,
  .account-item .drop-menu li button {
    padding: 0 20px;  /* Match other dropdown items */
    font-size: 20px;  /* Increase from 18px to match other items */
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    color: #E0FBFC;
    line-height: 50px;  /* Match other dropdown items */
  }
}

/* Hide checkboxes */
.nav-links input[type="checkbox"] {
  display: none;
}

/* Add this at the top of the file */
.resize-animation-stopper * {
  transition: none !important;
  animation: none !important;
}

/* Add mobile styles for theme toggle */
.theme-toggle-item {
  padding: 0;
  margin: 5px 0;
  background: none !important;
}

.theme-toggle-item .theme-toggle {
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background: none !important;
}

.theme-toggle-item:hover,
.theme-toggle-item:hover .theme-toggle,
.theme-toggle-item .theme-toggle:hover {
  background: none !important;
}

.theme-toggle-item:hover .theme-toggle {
  color: #EE6C4D;
}

/* Ensure no background on any hover state for mobile */
.nav-links .drop-menu li.theme-toggle-item,
.nav-links .drop-menu li.theme-toggle-item:hover,
.nav-links .drop-menu li.theme-toggle-item *,
.nav-links .drop-menu li.theme-toggle-item *:hover {
  background: none !important;
} 