.theme-toggle-container {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.theme-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  font-weight: 400;
  font-size: 15px;
  color: #E0FBFC;
  text-align: left;
  line-height: 45px;
  transition: all 0.3s ease;
}

.theme-toggle-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.theme-toggle-button:hover {
  background-color: #3D5A80;
  padding-left: 20px;
  color: #EE6C4D;
}

.theme-toggle-button svg {
  transition: transform 0.3s ease;
  margin-left: auto;
}

.theme-toggle-button:hover svg {
  transform: rotate(15deg);
}

.theme-label {
  font-family: 'Poppins', sans-serif;
}

/* Mobile styles */
@media (max-width: 768px) {
  .theme-toggle-button {
    padding: 0 20px;
    font-size: 20px;
    line-height: 50px;
  }
  
  .theme-toggle-button:hover {
    background-color: #3D5A80;
    padding-left: 25px;
  }
} 