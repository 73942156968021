.generator-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.spellbook-generator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white !important;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  border: none;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  transform-style: flat;
  -webkit-font-smoothing: auto;
  cursor: default; /* Normal arrow cursor */
  z-index: 1001;
  pointer-events: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  animation: generator-popup-appear 0.3s ease-out;
}

/* Add the animation keyframes */
@keyframes generator-popup-appear {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.spellbook-generator h2 {
  color: #3d5a80;
  margin-bottom: 20px;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  text-align: center;
  font-size: 1.5rem;
}

.form-header {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}

.form-header-top {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group label {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 5px;
  color: #3d5a80;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
}

.form-group .small-input {
  width: 100%;
  min-width: 100px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 5px 8px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85em;
  color: #3d5a80;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  height: auto;
  min-height: initial;
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}

.form-group .small-input::placeholder {
  color: #98c1d9;
}

.form-group .level-select,
.form-group .spells-per-level-select {
  width: auto;
  min-width: 80px;
  padding: 5px 8px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85em;
  color: #3d5a80;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 16px auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  height: auto;
  min-height: initial;
}

.form-group .level-select {
  width: 65px;
  min-width: 65px;
}

.form-group .spells-per-level-select {
  width: 100px;
  min-width: 100px;
}

.manual-mode-toggle {
  margin: 15px 0;
  padding: 10px;
  background-color: #f8fafc;
  border-radius: 4px;
  border: 1px solid #98c1d9;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.manual-mode-toggle label {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  color: #3d5a80;
  min-width: 150px;
}

.distribution-container {
  display: flex;
  gap: 20px;
  margin: 15px 0;
  width: 100%;
  box-sizing: border-box;
}

.spell-distribution {
  flex: 1;
  background-color: #f8fafc;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #98c1d9;
  box-sizing: border-box;
  width: 100%;
}

.spell-distribution h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
}

.spell-distribution p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
}

.manual-distribution {
  flex: 1;
  margin: 0;
  padding: 15px;
  background-color: #f8fafc;
  border-radius: 4px;
  border: 1px solid #98c1d9;
  box-sizing: border-box;
  width: 100%;
}

.manual-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.manual-input-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.manual-input-group label {
  flex: 1;
  min-width: 70px;
  font-size: 0.9rem;
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
}

.manual-input-group input {
  width: 60px;
  padding: 4px 8px;
  border: 1px solid #3d5a80;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #3d5a80;
}

.generated-spells {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #98c1d9;
  width: 100%;
  max-width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
}

.generated-spells h3 {
  margin-bottom: 15px;
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
}

.spell-list-preview {
  max-height: 300px;
  overflow-y: auto;
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  padding: 15px;
  width: auto;
}

.spell-list-preview h4 {
  margin: 10px 0 5px;
  color: #3d5a80;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
}

.spell-list-preview ul {
  list-style: none;
  padding-left: 15px;
  margin: 0;
}

.spell-list-preview li {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  font-size: 0.9rem;
  color: #3d5a80;
}

.spell-list-preview li > span:first-child,
.spell-list-preview li::before {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.spell-list-preview .school-badge {
  padding: 1px 4px;
  border-radius: 10px;
  font-size: 0.75em;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  width: 35px;
  min-width: 35px;
  box-sizing: border-box;
  border: 1.5px solid;
  background-color: transparent;
  line-height: 1.2;
  margin-left: auto;
}

/* School colors - same as SpellTable.css */
.spell-list-preview .school-abjuration {
  color: #3498db;
  border-color: #3498db;
}

.spell-list-preview .school-conjuration {
  color: #f1c40f;
  border-color: #f1c40f;
}

.spell-list-preview .school-divination {
  color: #95a5a6;
  border-color: #95a5a6;
}

.spell-list-preview .school-enchantment {
  color: #e91e63;
  border-color: #e91e63;
}

.spell-list-preview .school-evocation {
  color: #e74c3c;
  border-color: #e74c3c;
}

.spell-list-preview .school-illusion {
  color: #9b59b6;
  border-color: #9b59b6;
}

.spell-list-preview .school-necromancy {
  color: #2ecc71;
  border-color: #2ecc71;
}

.spell-list-preview .school-transmutation {
  color: #e67e22;
  border-color: #e67e22;
}

.generator-buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  justify-content: space-between;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.preview-button,
.generate-button,
.cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.preview-button:focus,
.generate-button:focus,
.cancel-button:focus {
  outline: none;
}

.preview-button,
.generate-button {
  background-color: #3d5a80;
  color: white;
}

.preview-button:hover,
.generate-button:hover {
  background-color: #2c4a6e;
}

.generator-buttons .cancel-button {
  background-color: #f8fafc;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  padding: 8px 16px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generator-buttons .cancel-button:hover {
  background-color: #e5e7eb;
}

@media (max-width: 640px) {
  .generator-modal {
    width: 95%;
    max-width: none;
  }
  
  .spellbook-generator {
    max-width: 95%;
    padding: 0;
    border-radius: 12px;
  }

  .generator-header {
    padding: 0.5rem 1rem;
    position: relative;
  }
  
  .generator-header h2 {
    font-size: 1.3rem;
    padding-right: 30px;
  }
  
  .spellbook-generator .close-button {
    right: 10px;
  }
  
  .distribution-container {
    flex-direction: column;
  }
  
  .spell-distribution,
  .manual-distribution {
    width: 100%;
  }
  
  .form-header {
    flex-direction: column;
  }
  
  .form-header-top {
    flex-direction: column;
  }
  
  .form-group.name-group {
    flex: 1;
    margin-bottom: 10px;
  }
  
  .form-group.level-group {
    margin-bottom: 10px;
  }
  
  .form-group .level-select {
    flex: 1;
  }
  
  .form-group .spells-per-level-select {
    flex: 1;
  }
  
  .form-group.subclass-group {
    margin-bottom: 10px;
  }
  
  .manual-inputs {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .manual-input-group {
    margin-bottom: 8px;
  }
  
  .manual-input-group label {
    font-size: 12px;
  }
  
  .generator-buttons {
    flex-direction: column;
  }
  
  .preview-button,
  .generate-button {
    width: 100%;
    margin: 5px 0;
  }
  
  .generator-buttons .cancel-button {
    margin-top: 5px;
  }
  
  .generated-spells {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
  }
  
  .spell-list-preview {
    margin-left: 0;
    padding-left: 15px;
    width: auto;
  }

  form {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }
}

.generator-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.25rem;
  background-color: #3d5a80;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
  position: relative;
}

.generator-header h2 {
  margin: 0;
  font-family: 'Mondia Light', sans-serif;
  font-size: 1.6rem;
  color: white;
  text-align: left;
  padding-right: 30px;
}

.spellbook-generator .close-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spellbook-generator .close-button:hover {
  color: #f0f0f0;
}

.spellbook-generator .close-button svg {
  width: 20px;
  height: 20px;
}

.required {
  color: #ef4444;
  margin-left: 2px;
}

.error-message {
  color: #ef4444;
  font-size: 0.8rem;
  margin-top: 4px;
  font-family: 'Poppins', sans-serif;
}

.small-input.error {
  border-color: #ef4444;
}

.small-input.error:focus {
  outline-color: #ef4444;
  box-shadow: 0 0 0 1px #ef4444;
}

@media (max-width: 768px) {
  .distribution-container {
    flex-direction: column;
  }
  
  .spell-distribution,
  .manual-distribution {
    width: 100%;
  }
}

/* Add styles for the scrollbar */
.spellbook-generator::-webkit-scrollbar {
  width: 8px;
}

.spellbook-generator::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.spellbook-generator::-webkit-scrollbar-thumb {
  background: #98c1d9;
  border-radius: 4px;
}

.spellbook-generator::-webkit-scrollbar-thumb:hover {
  background: #3d5a80;
}

/* Add these styles */
.total-spells {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #98c1d9;
  color: #3d5a80;
  font-weight: 600;
  font-size: 0.95rem;
}

/* Update hover and focus states */
.form-group .level-select:hover,
.form-group .spells-per-level-select:hover,
.form-group .small-input:hover {
  background-color: white;
}

.form-group .level-select:focus,
.form-group .spells-per-level-select:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

/* Update error state to match the new style */
.small-input.error {
  border-color: #ef4444;
}

.small-input.error:focus {
  outline: none;
  border-color: #ef4444;
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}

.form-group.name-group {
  flex: 1;
  max-width: 250px;
}

/* Add these styles */
.form-group.subclass-group {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  max-width: 140px;
}

.form-group .subclass-select {
  width: 100%;
  min-width: 120px;
  padding: 5px 8px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85em;
  color: #3d5a80;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 16px auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.form-group .subclass-select:hover {
  border-color: #2c4a6e;
}

.form-group .subclass-select:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

/* Remove any bottom margin from form groups in the header */
.form-header .form-group {
  margin-bottom: 0;
}

.info-tooltip-icon {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  cursor: help;
}

.info-tooltip-icon svg {
  width: 14px;
  height: 14px;
  fill: #3d5a80;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.info-tooltip-icon:hover svg {
  opacity: 1;
}

/* Add this media query for Chrome specifically */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .spellbook-generator {
    transform: translate(-50%, -50%);
  }
}

/* SPELLBOOK GENERATOR SPECIFIC */
.spellbook-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;
}

form {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}