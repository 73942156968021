@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'OPTIBerling-Agency';
  src: url('../../assets/fonts/OPTIBerling-Agency/OPTIBerling-Agency.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mondia-Light';
  src: url('../../assets/fonts/Mondia Light/Mondia Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.spell-content {
  position: relative;
  padding: 5px 0;
  width: 100%;
  box-sizing: border-box;
}

.spell-content.header-hidden {
  padding-top: 0;
}

.spell-content__title {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 2px;
  color: #2c3e50;
  padding-bottom: 2px;
}

.spell-content__subtitle {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 0.9em;
  color: #666;
  margin-top: 0;
  margin-bottom: 25px;
  line-height: 1;
}

.spell-content__properties {
  width: 280px;
  background-color: #f8f9fa;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  padding: 12px;
  margin-left: 15px;
  margin-bottom: 12px;
  float: right;
}

.property-group {
  margin: 3px 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: baseline;
}

.property-group strong {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #34495e;
  text-align: right;
  padding-right: 4px;
  font-size: 0.9rem;
}

.property-group span:nth-child(2) {
  padding: 0 4px;
}

.property-value {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  text-align: left;
  color: #333;
  font-style: italic;
  font-size: 0.9rem;
}

.materials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #ddd;
  text-align: center;
}

.materials svg {
  margin-right: 6px;
  flex-shrink: 0;
  transform: scale(0.9);
}

.material-description {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.85em;
  color: #333;
  flex: 1;
  text-align: center;
}

.spell-content__description {
  margin-top: 0;
}

.spell-content__description p {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  margin-bottom: 1em;
  line-height: 1.6;
  color: #333;
}

.spell-content__description strong {
  font-weight: 600;
  color: #2c3e50;
}

.higher-levels,
.cantrip-upgrade {
  margin-top: 15px;
}

.higher-levels h3,
.cantrip-upgrade h3 {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-style: italic;
  color: #34495e;
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 1em;
}

.higher-levels p,
.cantrip-upgrade p {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
}

/* Table styles */
.spell-content__description table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
  font-size: 0.9em;
}

.spell-content__description th,
.spell-content__description td {
  border: 1px solid #98C1D9;
  padding: 8px;
  text-align: left;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.spell-content__description th {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  background-color: #f8fafc;
  font-weight: bold;
  color: #3d5a80;
}

.spell-content__description table.spell-table {
  border: 1px solid #98C1D9;
  width: 250px;
  min-width: 50%;
  border-collapse: collapse;
  margin: 1em 0;
  font-size: 0.9em;
  background-color: #ffffff;
  table-layout: auto;
}

.spell-content__description table.spell-table th,
.spell-content__description table.spell-table td {
  border: none;
  border-bottom: 1px solid #98C1D9;
  padding: 4px 8px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  line-height: 1.2;
  vertical-align: middle;
  height: auto;
  width: 50%;
}

.spell-content__description table.spell-table th:nth-child(1),
.spell-content__description table.spell-table td:nth-child(1),
.spell-content__description table.spell-table th:nth-child(2),
.spell-content__description table.spell-table td:nth-child(2),
.spell-content__description table.spell-table th:nth-child(3),
.spell-content__description table.spell-table td:nth-child(3),
.spell-content__description table.spell-table th:nth-child(4),
.spell-content__description table.spell-table td:nth-child(4),
.spell-content__description table.spell-table th:nth-child(5),
.spell-content__description table.spell-table td:nth-child(5) {
  width: auto;
}

.spell-content__description table.spell-table th {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  background-color: #f8fafc;
  font-weight: bold;
  color: #3d5a80;
  border-bottom: 2px solid #3d5a80;
  white-space: normal;
  hyphens: auto;
  word-wrap: break-word;
  padding: 4px 8px;
  line-height: 1.2;
}

.spell-content__description table.spell-table tr:nth-child(even) {
  background-color: #f8fafc;
}

.spell-content__description table.spell-table tr:hover {
  background-color: #f0f7ff;
}

/* Special handling for consecutive tables */
.spell-content__description table.spell-table {
  width: 250px;
}

.spell-content__description table.spell-table th,
.spell-content__description table.spell-table td {
  width: 50%;
}

/* Remove any previous consecutive table styles */
.spell-content__description p + table.spell-table,
.spell-content__description table.spell-table + table.spell-table {
  width: 250px;
}

.spell-content__description p + table.spell-table th,
.spell-content__description p + table.spell-table td,
.spell-content__description table.spell-table + table.spell-table th,
.spell-content__description table.spell-table + table.spell-table td {
  width: 50%;
}

/* Special handling for weather tables */
.spell-content__description table.weather-table {
  width: 250px !important;
  margin: 0.5em 0;
}

.spell-content__description table.weather-table th,
.spell-content__description table.weather-table td {
  width: auto !important;
}

.spell-content__description table.weather-table th:first-child,
.spell-content__description table.weather-table td:first-child {
  width: 60px !important;
}

.spell-source {
  margin-top: 30px;
}

.spell-source p {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.9em;
  color: #666;
  font-style: italic;
}

@media (max-width: 768px) {
  .spell-content__properties {
    float: none;
    width: 100%;
    margin: 0 0 20px 0;
    box-sizing: border-box;
  }
  
  .property-group {
    grid-template-columns: auto 1fr;
  }
  
  .property-group span:nth-child(2) {
    display: none;
  }

  .spell-content__description {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  .spell-content__description table {
    min-width: 100%;
    margin-bottom: 15px;
  }

  .spell-content__description table.spell-table {
    min-width: auto;
    width: 100%;
    border: 1px solid #98C1D9;
    table-layout: auto;
  }

  .spell-content__description table.spell-table th,
  .spell-content__description table.spell-table td {
    display: table-cell;
    min-width: auto;
    padding: 4px 6px;
    font-size: 0.9em;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    overflow: visible;
    line-height: 1.2;
  }

  .spell-content__description table.spell-table th:nth-child(1),
  .spell-content__description table.spell-table td:nth-child(1) {
    min-width: auto;
    max-width: none;
  }

  /* Maintain table consistency on mobile */
  .spell-content__description p + table.spell-table,
  .spell-content__description table.spell-table + table.spell-table {
    width: 100% !important;
  }

  .spell-content__description table.spell-table {
    width: 100%;
  }

  .spell-content__description table.weather-table {
    width: 100% !important;
  }

  .spell-source {
    margin-top: 25px;
  }

  .spell-content__srd-restricted {
    width: auto;
    margin: 10px 0;
  }
}

.spell-properties {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 15px 20px;
  margin-bottom: 20px;
  background-color: #f9fafb;
  position: relative;
}

.spell-properties-conversion {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
}

/* Update metric toggle button to show only the icon with no background or border */
.spell-content .metric-toggle-button {
  background: none;
  color: #3d5a80;
  border: none;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
}

.spell-content .metric-toggle-button:hover {
  color: #2c4a6e;
  transform: scale(1.1);
  background: none;
  border: none;
}

.spell-content .metric-toggle-button svg {
  width: 18px;
  height: 18px;
}

.spell-content .metric-toggle-button.active {
  color: #3d5a80;
  font-weight: bold;
  background: none;
  border: none;
  box-shadow: none;
}

.spell-content .metric-toggle-button.active:hover {
  color: #2c4a6e;
  background: none;
  border: none;
}

.spell-content .metric-toggle-button.active svg {
  width: 22px;
  height: 22px;
}

.spell-content__srd-restricted {
  margin: 0;
  padding: 0;
  text-align: left;
  width: auto;
  display: block;
  overflow: hidden;
}

.srd-restricted-message {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: #3d5a80;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 1.1em;
}

.srd-restricted-note {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: #6c757d;
  font-size: 0.9em;
  margin-top: 0;
}

@media (max-width: 768px) {
  .spell-content__srd-restricted {
    width: 100%;
  }
} 