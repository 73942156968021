.faq-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #293241;
  font-family: 'Poppins', sans-serif;
}

.faq-container h1 {
  color: #3d5a80;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.5rem;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #98c1d9;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.faq-item:hover {
  border-color: #3d5a80;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.faq-question {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
}

.faq-question h3 {
  color: #3d5a80;
  font-weight: normal;
  margin: 0;
  font-size: 1.4rem;
  padding-right: 2rem;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.faq-icon {
  position: relative;
  width: 20px;
  height: 20px;
}

.faq-icon::before,
.faq-icon::after {
  content: '';
  position: absolute;
  background: #EE6C4D;
  transition: all 0.3s ease;
}

.faq-icon::before {
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  transform: translateY(-50%);
}

.faq-icon::after {
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  transform: translateX(-50%);
}

.faq-item.active .faq-icon::after {
  transform: translateX(-50%) rotate(90deg);
  opacity: 0;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.faq-item.active .faq-answer {
  max-height: 500px;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.faq-answer p {
  margin: 0;
  line-height: 1.6;
  color: #4b5563;
  font-family: 'Poppins', sans-serif;
  font-size: 0.95rem;
}

@media (max-width: 768px) {
  .faq-container {
    padding: 1rem;
  }

  .faq-container h1 {
    font-size: 2rem;
  }

  .faq-question h3 {
    font-size: 1.2rem;
  }
} 