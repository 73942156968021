.spell-comments {
  margin: 30px 0;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.comments-title {
  font-size: 1.2rem;
  margin: 0 0 20px 0;
  color: #3d5a80;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
}

.comment-form {
  margin-bottom: 25px;
}

.comment-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  font-family: inherit;
  font-size: 0.95rem;
  resize: vertical;
  margin-bottom: 10px;
  transition: border-color 0.2s ease;
}

.comment-input:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

.comment-submit-btn {
  background-color: #3d5a80;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.comment-submit-btn:hover {
  background-color: #2c4162;
}

.comment-submit-btn:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.login-prompt {
  margin-bottom: 20px;
  padding: 12px;
  background-color: #e9ecef;
  border-radius: 6px;
  color: #6c757d;
  font-size: 0.9rem;
  text-align: center;
}

.comments-error {
  color: #dc3545;
  font-size: 0.85rem;
  margin-bottom: 15px;
  padding: 8px 12px;
  background-color: #f8d7da;
  border-radius: 4px;
  text-align: center;
}

.comments-loading, .no-comments {
  text-align: center;
  color: #6c757d;
  padding: 20px;
  font-size: 0.95rem;
}

.comments-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.comment-item {
  padding: 15px;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e9ecef;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.comment-author {
  font-weight: 600;
  color: #3d5a80;
  font-size: 0.95rem;
}

.comment-date {
  color: #6c757d;
  font-size: 0.8rem;
}

.comment-text {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #343a40;
  margin-bottom: 12px;
  word-break: break-word;
  white-space: pre-wrap;
}

.comment-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.like-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  color: #6c757d;
  font-size: 0.85rem;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.like-button:hover {
  background-color: #f1f3f5;
}

.like-button.liked {
  color: #3d5a80;
  font-weight: 500;
}

.like-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.like-icon {
  font-size: 0.9rem;
}

.delete-button {
  background: none;
  border: none;
  color: #dc3545;
  font-size: 0.85rem;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.delete-button:hover {
  background-color: #f8d7da;
}

@media (max-width: 768px) {
  .spell-comments {
    padding: 15px;
    margin: 20px 0;
  }
  
  .comments-title {
    font-size: 1.1rem;
  }
  
  .comment-input {
    padding: 10px;
  }
  
  .comment-item {
    padding: 12px;
  }
} 