.admin-dashboard {
  padding: 1.5rem 2rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1.5rem;
}

.admin-dashboard > * {
  grid-column: span 12;
}

/* Admin action confirmation modal and messages span full width */
.admin-confirmation-modal,
.admin-action-message {
  grid-column: 1 / -1;
}

/* Dashboard Sections */
.dashboard-section {
  background-color: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(61, 90, 128, 0.1);
  overflow: hidden;
}

/* Key metrics and registration stats sections side by side */
.dashboard-section:nth-of-type(1) {
  grid-column: span 7;
}

.dashboard-section:nth-of-type(2) {
  grid-column: span 5;
}

/* Chart section */
.dashboard-section:nth-of-type(3) {
  grid-column: span 12;
}

/* User management section */
.dashboard-section:nth-of-type(4) {
  grid-column: span 12;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.section-header h2 {
  margin: 0;
}

.dashboard-date {
  font-size: 0.9rem;
  color: #6b7280;
  font-style: italic;
}

.admin-dashboard h1 {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', serif;
  color: #3d5a80;
  margin: 0;
  font-size: 2.5rem;
}

.admin-dashboard h2 {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', serif;
  color: #3d5a80;
  margin: 0;
  font-size: 1.8rem;
}

.admin-dashboard h3 {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', serif;
  color: #3d5a80;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.admin-dashboard h4 {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', serif;
  color: #3d5a80;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

/* Metrics */
.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.dashboard-section:nth-of-type(2) .metrics-grid {
  grid-template-columns: repeat(2, 1fr);
}

.metric-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.metric-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.metric-card.primary {
  background-color: #3d5a80;
  color: white;
  border: none;
}

.metric-content {
  display: flex;
  flex-direction: column;
}

.metric-value {
  font-size: 1.8rem;
  font-weight: bold;
  color: #3d5a80;
  line-height: 1.1;
  margin-bottom: 0.25rem;
}

.dashboard-section:nth-of-type(2) .metric-value {
  font-size: 1.5rem;
}

.metric-card.primary .metric-value {
  color: white;
}

.metric-label {
  font-size: 0.9rem;
  color: #6b7280;
  font-weight: 500;
}

.metric-card.primary .metric-label {
  color: rgba(255, 255, 255, 0.8);
}

.metric-trend {
  font-size: 0.8rem;
  color: #6b7280;
  margin-top: 0.5rem;
}

.metric-card.primary .metric-trend {
  color: rgba(255, 255, 255, 0.7);
}

.trend-value {
  font-weight: 500;
}

.metric-trend.positive {
  color: #10b981;
}

.metric-trend.negative {
  color: #ef4444;
}

.metric-card.primary .metric-trend.positive {
  color: #a7f3d0;
}

.metric-card.primary .metric-trend.negative {
  color: #fca5a5;
}

/* Charts */
.chart-container {
  width: 100%;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.chart-controls {
  display: flex;
  gap: 0.5rem;
}

.chart-control-btn {
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
}

.chart-control-btn:hover {
  background-color: #e2e8f0;
}

.chart-control-btn.active {
  background-color: #3d5a80;
  color: white;
  border-color: #3d5a80;
}

.chart-content {
  height: 100%;
  width: 100%;
  min-height: 300px;
  position: relative;
}

.chart-wrapper {
  width: 100%;
  height: 300px;
  min-width: 300px;
  min-height: 200px;
  display: block;
  position: relative;
}

.chart-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  color: #6c757d;
  font-size: 16px;
  border-radius: 6px;
  border: 1px dashed #dee2e6;
}

/* Search */
.search-container {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 400px;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 0.95rem;
  font-family: 'Poppins', sans-serif;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  background-color: #fff;
}

.search-input:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 3px rgba(61, 90, 128, 0.1);
}

.clear-search-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #6b7280;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.clear-search-button:hover {
  background-color: #f3f4f6;
  color: #3d5a80;
}

.search-results-info {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #6b7280;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e5e7eb;
}

/* User Table */
.users-table-container {
  overflow-x: auto;
  max-height: 800px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
  background-color: #fff;
}

.users-table th {
  background-color: #3d5a80;
  color: white;
  text-align: left;
  padding: 0.75rem 1rem;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 10;
}

.users-table td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e9ecef;
}

.users-table tr {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.users-table tr:hover {
  background-color: #f8f9fa;
}

.users-table tr.expanded {
  background-color: #e2eef9;
}

.users-table tr.expanded:hover {
  background-color: #d5e6f7;
}

.users-table tr.details-row {
  background-color: #f8f9fa;
  cursor: default;
}

.users-table tr.details-row:hover {
  background-color: #f8f9fa;
}

.users-table tr.details-row td {
  padding: 0;
  border-top: none;
}

.user-details-expanded {
  padding: 1.5rem;
  border-bottom: 1px solid #e9ecef;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.no-results {
  text-align: center;
  padding: 2rem 0;
  color: #6b7280;
  font-style: italic;
}

/* Admin status indicators */
.admin-status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.85rem;
}

.admin-status.is-admin {
  background-color: #4caf50;
  color: white;
}

.admin-status.not-admin {
  background-color: #f5f5f5;
  color: #666;
}

/* Admin action buttons */
.action-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
}

.admin-button {
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.2s;
}

.admin-button.grant-admin {
  background-color: #2196f3;
  color: white;
}

.admin-button.grant-admin:hover {
  background-color: #0d8bf2;
}

.admin-button.revoke-admin {
  background-color: #ff9800;
  color: white;
}

.admin-button.revoke-admin:hover {
  background-color: #f08f00;
}

.admin-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.delete-button {
  background-color: #ee6c4d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.2s ease;
}

.delete-button:hover {
  background-color: #e34c26;
}

/* Admin confirmation modal */
.admin-confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.admin-confirmation-content {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
}

.admin-confirmation-content h3 {
  margin-top: 0;
  color: #3d5a80;
}

.admin-code-input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.admin-confirmation-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.confirm-button {
  background-color: #3d5a80;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
}

.confirm-button:hover {
  background-color: #2c4057;
}

.cancel-button {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  padding: 10px 16px;
  border-radius: 6px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #e5e5e5;
}

.admin-error {
  color: #f44336;
  margin: 10px 0;
  font-size: 0.9rem;
}

/* Admin action message */
.admin-action-message {
  background-color: #e8f5e9;
  border-left: 4px solid #4caf50;
  padding: 12px 16px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 6px 6px 0;
}

.close-message {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
}

.close-message:hover {
  color: #333;
}

/* Loading and Error States */
.admin-dashboard.loading, .admin-dashboard.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  font-size: 1.2rem;
  color: #3d5a80;
}

.admin-dashboard.error {
  color: #ee6c4d;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .dashboard-section:nth-of-type(1),
  .dashboard-section:nth-of-type(2) {
    grid-column: span 12;
  }
}

@media (max-width: 992px) {
  .admin-dashboard {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
  
  .dashboard-section {
    grid-column: span 1 !important;
  }
  
  .metrics-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
  
  .chart-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .chart-controls {
    margin-top: 0.5rem;
  }
}

@media (max-width: 768px) {
  .admin-dashboard {
    padding: 1rem;
  }
  
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .section-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .search-container {
    max-width: 100%;
  }
  
  .metric-card {
    padding: 1.25rem;
  }
  
  .metric-icon {
    font-size: 2rem;
    margin-right: 1rem;
  }
  
  .metric-value {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .metrics-grid {
    grid-template-columns: 1fr;
  }
  
  .chart-control-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
  
  .users-table th, .users-table td {
    padding: 0.5rem;
  }
}

/* Search form styles */
.search-form {
  width: 100%;
  margin-bottom: 1rem;
}

.search-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.search-input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  font-size: 1rem;
}

.search-button {
  padding: 0.75rem 1.5rem;
  background-color: #3d5a80;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.search-button:hover {
  background-color: #2c4361;
}

.clear-search-button {
  background: none;
  border: none;
  color: #666;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 0.5rem;
  margin-left: -2.5rem;
  z-index: 2;
}

.search-prompt {
  text-align: center;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.search-prompt p {
  margin: 0.5rem 0;
  color: #555;
}

.search-note {
  font-size: 0.9rem;
  color: #888;
  font-style: italic;
}

.search-loading {
  text-align: center;
  padding: 1rem;
  color: #3d5a80;
  font-weight: bold;
}

.search-results-info {
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  color: #555;
  font-size: 0.95rem;
}

/* Add these styles to the existing CSS file */

.refresh-button {
  background-color: #3d5a80;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.refresh-button:hover {
  background-color: #2c3e50;
}

.refresh-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.last-updated {
  font-size: 0.8rem;
  color: #7f8c8d;
  margin-bottom: 20px;
  text-align: right;
}

/* Feature Flags */
.feature-flags-container {
  background-color: var(--color-card-bg);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-flags-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feature-flag-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: var(--color-bg-secondary);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-left: 5px solid #ccc;
  transition: all 0.3s ease;
}

.feature-flag-item.active {
  border-left-color: #3d5a80;
  background-color: rgba(61, 90, 128, 0.05);
}

.feature-flag-name {
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-primary);
  margin-bottom: 4px;
}

.feature-flag-description {
  color: var(--color-text-secondary);
  font-size: 14px;
  margin-right: auto;
  margin-left: 15px;
  flex: 1;
}

.feature-flag-status-indicator {
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
  text-transform: uppercase;
}

.feature-flag-status-indicator.enabled {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
  border: 1px solid rgba(40, 167, 69, 0.2);
}

.feature-flag-status-indicator.disabled {
  background-color: rgba(108, 117, 125, 0.1);
  color: #6c757d;
  border: 1px solid rgba(108, 117, 125, 0.2);
}

/* Enhanced Feature Flags */
.feature-flag-toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.feature-flag-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.feature-flag-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.feature-flag-switch:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.feature-flag-toggle input:checked + .feature-flag-switch {
  background-color: #3d5a80;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4), 0 0 8px rgba(61, 90, 128, 0.6);
}

.feature-flag-toggle input:focus + .feature-flag-switch {
  box-shadow: 0 0 2px #3d5a80, inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.feature-flag-toggle input:checked + .feature-flag-switch:before {
  transform: translateX(26px);
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.2);
}

.feature-flags-status {
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
}

.feature-flags-status.success {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
  border: 1px solid rgba(40, 167, 69, 0.2);
}

.feature-flags-status.error {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
  border: 1px solid rgba(220, 53, 69, 0.2);
}

.feature-flags-metadata {
  font-size: 12px;
  color: var(--color-text-secondary);
  border-top: 1px solid var(--color-border);
  padding-top: 10px;
}

.feature-flags-metadata p {
  margin: 5px 0;
}

.feature-flag-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

/* User Limits Section */
.user-limits-container {
  background-color: #f5f7f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.user-limit-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.user-limit-item label {
  flex: 0 0 180px;
  font-weight: 600;
  color: #3d5a80;
}

.user-limit-item input {
  flex: 0 0 80px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  font-size: 1rem;
}

.user-limits-update-button {
  background-color: #3d5a80;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.2s ease;
}

.user-limits-update-button:hover {
  background-color: #2c3e50;
}

.user-limits-status {
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 4px;
  font-weight: 500;
}

.user-limits-status.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.user-limits-status.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
} 