.spell-detail-page {
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #3d5a80;
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.spell-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: #3d5a80;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}

.spell-header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.spell-detail-header h1 {
  margin: 0;
  font-family: 'Mondia Light', sans-serif;
  font-size: 1.8rem;
  color: white;
  width: 100%;
}

.spell-detail-header p {
  margin: 0;
  font-family: 'Mondia Light', sans-serif;
  font-size: 0.9rem;
  color: #f0f0f0;
  margin-top: 2px;
}

.spell-detail-content {
  padding: 20px 30px 30px;
  position: relative;
}

.spell-detail-page__conversion {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
}

/* Metric Toggle Button styling */
.spell-detail-page .metric-toggle-button {
  background-color: #ffffff;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.spell-detail-page .metric-toggle-button:hover {
  background-color: #f0f4f8;
  transform: scale(1.1);
  border-color: #2c4a6e;
}

.spell-detail-page .metric-toggle-button svg {
  width: 14px;
  height: 14px;
}

.spell-detail-page .metric-toggle-button.active {
  background-color: #3d5a80;
  color: white;
}

.spell-detail-page .metric-toggle-button.active:hover {
  background-color: #2c4a6e;
}

.back-link {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  color: #4a4a4a;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
  clear: both;
}

.back-link:hover {
  color: #0066cc;
}

@media (max-width: 768px) {
  .spell-detail-page {
    width: 95%;
  }

  .spell-detail-header {
    padding: 0.5rem 1rem;
  }

  .spell-detail-header h1 {
    font-size: 1.4rem;
    padding-right: 30px;
  }

  .spell-detail-content {
    padding: 15px;
  }

  .spell-detail-page__conversion {
    top: 4px;
    right: 4px;
  }
  
  .spell-detail-page .metric-toggle-button {
    width: 20px;
    height: 20px;
  }
  
  .spell-detail-page .metric-toggle-button svg {
    width: 12px;
    height: 12px;
  }
}

.spell-not-found {
  text-align: center;
  padding: 40px 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 600px;
}

.spell-not-found h2 {
  color: #3d5a80;
  margin-bottom: 16px;
  font-family: 'Mondia Light', 'Metamorphous', sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
}

.spell-not-found p {
  color: #4a5568;
  margin-bottom: 24px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
}

.loading-spinner {
  text-align: center;
  padding: 40px 20px;
}

.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3d5a80;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

.loading-spinner p {
  color: #4a5568;
  font-family: 'Poppins', sans-serif;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spell-actions {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  padding-top: 15px;
  border-top: 1px solid #e5e7eb;
}

.edit-spell-button,
.delete-spell-button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.edit-spell-button {
  background-color: #3d5a80;
  color: white;
  border: none;
}

.edit-spell-button:hover {
  background-color: #2c4a6e;
}

.delete-spell-button {
  background-color: transparent;
  color: #dc2626;
  border: 1px solid #dc2626;
}

.delete-spell-button:hover {
  background-color: #fee2e2;
}

.spell-detail-actions {
  margin: 20px 0;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.add-to-spellbook-button {
  background-color: #3d5a80;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  transition: all 0.2s ease;
}

.add-to-spellbook-button:hover {
  background-color: #2c4a6e;
}

.containing-spellbooks {
  margin-top: 15px;
  padding-top: 15px;
}

.containing-spellbooks h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: #4b5563;
  margin: 0 0 8px 0;
}

.containing-spellbooks ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.containing-spellbooks li {
  background-color: #edf3f8;
  color: #3d5a80;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 768px) {
  .spell-detail-actions {
    margin: 15px 0;
    padding: 12px;
  }

  .containing-spellbooks ul {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
  }

  .containing-spellbooks li {
    max-width: fit-content;
  }
}

.custom-spell-header {
  background-color: #5d4b90;
}
