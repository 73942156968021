.addtospellbook-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;
}

.addtospellbook-content {
  background-color: white !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  z-index: 1001;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0;
  width: 90%;
  max-width: 400px;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  animation: addspellbook-popup-appear 0.3s ease-out;
}

/* Add the animation keyframes */
@keyframes addspellbook-popup-appear {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.25rem;
  background-color: #3d5a80;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  margin-bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

.modal-header h2 {
  margin: 0;
  color: white;
  font-family: 'Mondia Light', sans-serif;
  font-size: 1.6rem;
  text-align: left;
  padding-right: 30px;
}

.close-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  color: #f0f0f0;
}

.close-button svg {
  width: 20px;
  height: 20px;
}

.modal-body {
  padding: 20px;
}

.modal-body .selection-container {
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 5px;
}

.modal-body p {
  color: #000000;
  font-size: 0.95rem;
  margin-bottom: 10px;
  margin-top: 0;
  font-family: 'Poppins', sans-serif;
}

.modal-body select {
  width: 100%;
  padding: 8px 30px 8px 12px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #3d5a80;
  background-color: white;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 16px auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.modal-body select:hover {
  border-color: #2c4a6e;
}

.modal-body select:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

@media (max-width: 640px) {
  .addtospellbook-content {
    width: 95%;
  }
  
  .modal-body {
    padding: 15px;
  }
}
