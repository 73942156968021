.active-spellbook-header {
  background-color: #f8fafc;
  border: 1px solid #98C1D9;
  border-radius: 4px;
  padding: 15px 20px;
  margin-bottom: 30px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.active-spellbook-header h2 {
  color: #3d5a80;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
}

.system-toggle-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.toggle-labels {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.85rem;
  color: #4b5563;
}

.toggle-labels span {
  cursor: pointer;
  transition: color 0.2s ease;
  color: #9ca3af;
  font-size: 0.8rem;
}

.toggle-labels span.active {
  color: #3d5a80;
  font-weight: 600;
}

.toggle-switch {
  position: relative;
  width: 36px;
  height: 18px;
  background-color: #e5e7eb;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #98C1D9;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.toggle-switch:hover {
  background-color: #d1d5db;
}

.toggle-slider {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 14px;
  height: 14px;
  background-color: #3d5a80;
  border-radius: 50%;
  transition: transform 0.3s ease;
  border: 1px solid #2c4a6e;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.toggle-slider.points {
  transform: translateX(18px);
}

@media (max-width: 768px) {
  .active-spellbook-header {
    gap: 10px;
    padding: 12px 15px;
  }
  
  .system-toggle-container {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
  
  .spellbook-selector {
    flex-grow: 1;
  }
  
  .toggle-labels {
    font-size: 0.8rem;
  }
} 