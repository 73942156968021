.account-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 70px);
  background-color: #f0f4f8;
  padding: 20px;
}

.account-container {
  display: flex;
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #3d5a80;
  overflow: hidden;
}

.account-sidebar {
  width: 200px;
  background-color: #f8fafc;
  padding: 20px 0;
  border-right: 1px solid #98c1d9;
}

.sidebar-button {
  display: block;
  width: 100%;
  padding: 12px 20px;
  background: none;
  border: none;
  text-align: left;
  color: #3d5a80;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
}

.sidebar-button:hover {
  background-color: #edf3f8;
}

.sidebar-button.active {
  background-color: #edf3f8;
  border-left: 3px solid #3d5a80;
  font-weight: 500;
  color: #3d5a80;
}

.sidebar-button.admin-button {
  color: #8a4fff;
  font-weight: 500;
}

.sidebar-button.admin-button.active {
  border-left: 3px solid #8a4fff;
  color: #8a4fff;
}

.account-content {
  flex-grow: 1;
  padding: 30px;
  background-color: #ffffff;
}

.account-content h2 {
  text-align: center;
  margin-bottom: 24px;
  color: #3d5a80;
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.account-info {
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #98c1d9;
}

.info-item {
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e7eb;
}

.info-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.info-label {
  flex: 0 0 120px;
  font-weight: 500;
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
}

.info-value {
  flex: 1;
  color: #4b5563;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
}

.admin-badge {
  display: inline-block;
  background-color: #8a4fff;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .account-container {
    flex-direction: column;
  }

  .account-sidebar {
    width: 100%;
    padding: 10px 0;
  }

  .sidebar-button {
    padding: 10px;
    text-align: center;
  }

  .info-item {
    flex-direction: column;
  }

  .info-label {
    margin-bottom: 5px;
  }
}

.clear-account-section {
  background-color: #fff0f0;
  border: 1px solid #98c1d9;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
}

.clear-account-section h2 {
  color: #dc2626;
  margin-bottom: 15px;
  text-align: left;
}

.clear-account-section p {
  margin-bottom: 20px;
  color: #4b5563;
  font-size: 0.9rem;
  line-height: 1.5;
}

.danger-button {
  background-color: transparent;
  color: #dc2626;
  border: 1px solid #dc2626;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  transition: all 0.2s ease;
}

.danger-button:hover {
  background-color: #fee2e2;
}

.cancel-button {
  background-color: #f8fafc;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  margin-left: 10px;
  transition: all 0.2s ease;
}

.cancel-button:hover {
  background-color: #edf3f8;
}

.confirmation-dialog {
  background-color: #fff;
  border: 1px solid #ffcccb;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.confirmation-dialog p {
  margin-bottom: 20px;
  font-weight: bold;
  color: #d32f2f;
}

/* Add these styles for the not-logged-in message */
.account-container.not-logged-in {
  padding: 40px;
  text-align: center;
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.account-container.not-logged-in h2 {
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  margin: 0;
  display: block;
  width: 100%;
}

.account-container.not-logged-in p {
  color: #4b5563;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  margin: 0;
  display: block;
  width: 100%;
}

/* Admin Controls Styles */
.admin-controls-section {
  background-color: #f8f9ff;
  border: 1px solid #8a4fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
}

.admin-controls-section h2 {
  color: #8a4fff;
  margin-bottom: 15px;
  text-align: left;
}

.admin-actions {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.admin-action {
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.admin-action h3 {
  color: #3d5a80;
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 500;
}

.admin-action p {
  color: #4b5563;
  margin-bottom: 15px;
  font-size: 0.9rem;
  line-height: 1.5;
}

.admin-form {
  margin-top: 1rem;
}

.admin-form .form-group {
  margin-bottom: 1rem;
}

.admin-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #3d5a80;
}

.admin-form .admin-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 0.95rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.admin-form .admin-input:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 3px rgba(61, 90, 128, 0.1);
}

.admin-form .form-actions {
  display: flex;
  gap: 1rem;
}

.admin-form .cancel-button {
  background-color: #e5e7eb;
  color: #4b5563;
  border: none;
  border-radius: 4px;
  padding: 0.6rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.admin-form .cancel-button:hover {
  background-color: #d1d5db;
}

.admin-button {
  background-color: #8a4fff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  transition: all 0.2s ease;
}

.admin-button:hover {
  background-color: #7a3fef;
}

.admin-success {
  color: #059669;
  font-size: 0.9rem;
  margin-top: 10px;
}

.admin-error {
  color: #dc2626;
  font-size: 0.9rem;
  margin-top: 10px;
}

.admin-link {
  display: inline-block;
  background-color: #8a4fff;
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  transition: all 0.2s ease;
}

.admin-link:hover {
  background-color: #7a3fef;
}

@media (min-width: 768px) {
  .admin-actions {
    grid-template-columns: 1fr 1fr;
  }
}

/* Setup Admin Section Styles */
.setup-admin-section {
  background-color: #f8f9ff;
  border: 1px solid #8a4fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
}

.setup-admin-section h3 {
  color: #8a4fff;
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 500;
}

.setup-admin-section p {
  color: #4b5563;
  margin-bottom: 15px;
  font-size: 0.9rem;
  line-height: 1.5;
}

.setup-admin-section .admin-button {
  background-color: #8a4fff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  transition: all 0.2s ease;
}

.setup-admin-section .admin-button:hover {
  background-color: #7a3fef;
}

.setup-admin-section .admin-button:disabled {
  background-color: #c4b5ff;
  cursor: not-allowed;
}

.firebase-error {
  background-color: #fff0f0;
  border: 1px solid #ffcccb;
  border-radius: 8px;
  padding: 15px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(255, 0, 0, 0.05);
}

.firebase-error .admin-error {
  color: #d32f2f;
  font-weight: 500;
  margin-bottom: 8px;
}

.firebase-error p {
  margin-bottom: 10px;
  color: #555;
  font-size: 0.9rem;
  line-height: 1.5;
}

.firebase-error p:last-child {
  margin-bottom: 0;
}

.firebase-error .error-help {
  background-color: #f8f8f8;
  border-left: 3px solid #d32f2f;
  padding: 8px 12px;
  margin-top: 10px;
  font-size: 0.85rem;
}

.firebase-error .error-code {
  font-family: monospace;
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 3px;
  color: #d32f2f;
}
