/* Modal overlay */
.dice-roller-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Modal container */
.dice-roller-modal {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: diceroller-popup-appear 0.3s ease-out;
  font-family: 'Poppins', sans-serif;
}

@keyframes diceroller-popup-appear {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Modal header */
.dice-roller-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.25rem;
  background-color: #3d5a80;
  border-radius: 10px 10px 0 0;
  position: relative;
}

.dice-roller-header h2 {
  margin: 0;
  font-family: 'Mondia Light', sans-serif;
  font-size: 1.6rem;
  color: white;
  text-align: left;
  padding-right: 30px;
}

.close-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  color: #f0f0f0;
}

.close-button svg {
  width: 20px;
  height: 20px;
}

.dice-icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
  transition: transform 0.2s;
}

.dice-icon-button:hover .dice-icon {
  transform: scale(1.05);
}

.dice-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Make d10 icon slightly larger to match other dice */
.dice-icon.d10 img {
  width: 110%;
  height: 110%;
  margin: -5%;
}

.dice-icon.d4 img, .dice-icon.d6 img, .dice-icon.d8 img, .dice-icon.d10 img, .dice-icon.d12 img, .dice-icon.d100 img {
  filter: invert(32%) sepia(15%) saturate(1967%) hue-rotate(173deg) brightness(92%) contrast(85%);
}

.dice-icon.d20 img {
  filter: invert(56%) sepia(51%) saturate(2341%) hue-rotate(334deg) brightness(97%) contrast(92%);
}

.dice-icon-button:hover .dice-icon.d4 img,
.dice-icon-button:hover .dice-icon.d6 img,
.dice-icon-button:hover .dice-icon.d8 img,
.dice-icon-button:hover .dice-icon.d10 img,
.dice-icon-button:hover .dice-icon.d12 img,
.dice-icon-button:hover .dice-icon.d100 img {
  filter: invert(18%) sepia(10%) saturate(2207%) hue-rotate(173deg) brightness(96%) contrast(88%);
}

.dice-icon-button:hover .dice-icon.d20 img {
  filter: invert(39%) sepia(40%) saturate(2341%) hue-rotate(334deg) brightness(97%) contrast(92%);
}

.dice-icon span {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #3D5A80;
  display: block;
  margin-top: 5px;
}

.modifier-control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.modifier-control label {
  font-size: 0.95rem;
  color: #555;
  font-weight: 500;
}

.modifier-control input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.95rem;
  width: 80px;
  box-sizing: border-box;
  height: 38px;
}

/* Selected Dice Area */
.selected-dice-area {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.25rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.selected-dice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-dice-header h3 {
  margin: 0;
  color: #3d5a80;
  font-size: 1.1rem;
  font-family: 'Mondia Light', sans-serif;
}

.clear-dice-button {
  background: none;
  border: none;
  color: #ee6c4d;
  cursor: pointer;
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  transition: color 0.2s;
}

.clear-dice-button:hover {
  color: #e84a5f;
  text-decoration: underline;
}

.clear-dice-button:disabled {
  color: #aaa;
  cursor: not-allowed;
  text-decoration: none;
}

.empty-selection {
  text-align: center;
  color: #999;
  padding: 1rem;
  font-style: italic;
}

.selected-dice-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  padding: 0.5rem 0;
}

.selected-die {
  position: relative;
  display: flex;
  align-items: center;
}

.selected-die-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
}

.selected-die-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Make selected d10 icon slightly larger to match other dice */
.selected-die-icon.d10 img {
  width: 110%;
  height: 110%;
  margin: -5%;
}

.selected-die-icon.d4 img, .selected-die-icon.d6 img, .selected-die-icon.d8 img, .selected-die-icon.d10 img, .selected-die-icon.d12 img, .selected-die-icon.d100 img {
  filter: invert(32%) sepia(15%) saturate(1967%) hue-rotate(173deg) brightness(92%) contrast(85%);
}

.selected-die-icon.d20 img {
  filter: invert(56%) sepia(51%) saturate(2341%) hue-rotate(334deg) brightness(97%) contrast(92%);
}

.selected-die-icon span {
  position: absolute;
  z-index: 2;
  font-weight: 600;
  font-size: 0.8rem;
  color: transparent;
}

.remove-die-button {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e84a5f;
  color: white;
  border: none;
  font-size: 0.9rem;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
}

.remove-die-button:hover {
  background-color: #d33f57;
}

.remove-die-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.roll-button {
  background-color: #3d5a80;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1.25rem;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 600;
  height: 38px;
  align-self: center;
  margin-top: 0.5rem;
}

.roll-button:hover {
  background-color: #2c3e50;
}

.roll-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

/* Result section */
.dice-roller-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 1.25rem 0;
  border-bottom: 1px solid #e0e0e0;
}

.dice-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  min-height: 90px;
  flex-wrap: wrap;
  padding: 0.75rem;
}

.die {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
  transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.die img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Make rolled d10 icon slightly larger to match other dice */
.die.d10 img {
  width: 110%;
  height: 110%;
  margin: -5%;
}

.die.d4 img, .die.d6 img, .die.d8 img, .die.d10 img, .die.d12 img, .die.d100 img {
  filter: invert(32%) sepia(15%) saturate(1967%) hue-rotate(173deg) brightness(92%) contrast(85%);
}

.die.d20 img {
  filter: invert(56%) sepia(51%) saturate(2341%) hue-rotate(334deg) brightness(97%) contrast(92%);
}

.die span {
  position: absolute;
  z-index: 2;
  font-weight: bold;
  font-size: 1.4rem;
  color: #333;
  text-shadow: 0px 0px 2px white;
}

.result-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  text-align: center;
}

.result-formula {
  font-size: 1.1rem;
  font-weight: 600;
  color: #3d5a80;
}

.result-breakdown {
  font-size: 0.85rem;
  color: #666;
}

.result-total {
  font-size: 1.7rem;
  font-weight: 700;
  color: #ee6c4d;
  margin-top: 0.4rem;
}

.result-total span {
  font-size: 0.95rem;
  color: #666;
  font-weight: normal;
}

/* History section */
.dice-roller-history {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.history-header h3 {
  margin: 0;
  color: #3d5a80;
  font-size: 1.1rem;
  font-family: 'Mondia Light', sans-serif;
}

.clear-history-button {
  background: none;
  border: none;
  color: #ee6c4d;
  cursor: pointer;
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
  transition: color 0.2s;
}

.clear-history-button:hover {
  color: #e84a5f;
  text-decoration: underline;
}

.empty-history {
  text-align: center;
  color: #999;
  padding: 0.75rem;
  font-style: italic;
}

.history-list {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  max-height: 180px;
  overflow-y: auto;
}

.history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  border-left: 3px solid #98c1d9;
}

.history-formula {
  font-weight: 600;
  color: #3d5a80;
}

.history-result {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.history-breakdown {
  font-size: 0.8rem;
  color: #666;
}

.history-total {
  font-weight: 700;
  color: #ee6c4d;
  min-width: 2.5rem;
  text-align: right;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dice-icons {
    gap: 0.5rem;
  }
  
  .dice-icon {
    width: 50px;
    height: 50px;
    font-size: 1rem;
  }
  
  .dice-icon.d100 {
    width: 55px;
  }
  
  .selected-dice-area {
    padding: 1rem;
  }
  
  .die {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }
  
  .die.d100 {
    width: 60px;
  }
}

@media (max-width: 480px) {
  .dice-icons {
    gap: 0.4rem;
  }
  
  .dice-icon {
    width: 40px;
    height: 40px;
    font-size: 0.9rem;
  }
  
  .dice-icon.d100 {
    width: 45px;
  }
  
  .selected-die-icon {
    width: 35px;
    height: 35px;
    font-size: 0.8rem;
  }
  
  .selected-die-icon.d100 {
    width: 40px;
  }
  
  .dice-roller-header h2 {
    font-size: 1.4rem;
  }
  
  .dice-roller-content {
    padding: 0.75rem;
  }
  
  .die {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }
  
  .die.d100 {
    width: 50px;
  }
  
  .result-total {
    font-size: 1.5rem;
  }
}

/* Modal content */
.dice-roller-content {
  padding: 1.25rem 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

/* Dice Selection Area */
.dice-selection-area {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #e0e0e0;
}

.dice-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  justify-content: center;
}

.dice-icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: transform 0.2s;
}

.dice-icon-button:hover {
  transform: translateY(-3px);
}

.dice-icon-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
} 