.advanced-filtering-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.advanced-filtering-content {
  position: relative;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 85%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  animation: advancedfilter-popup-appear 0.3s ease-out;
  z-index: 10000;
  border: none;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  transform: none !important;
  margin: 0 auto;
}

@keyframes advancedfilter-popup-appear {
  from {
    opacity: 0;
    transform: scale(0.8) !important;
  }
  to {
    opacity: 1;
    transform: scale(1) !important;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.25rem;
  background-color: #3d5a80;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  margin-bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

.modal-header h2 {
  margin: 0;
  color: white;
  font-family: 'Mondia Light', sans-serif;
  font-size: 1.6rem;
  text-align: left;
  padding-right: 30px;
}

.close-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  color: #f0f0f0;
}

.close-button svg {
  width: 20px;
  height: 20px;
}

.modal-body {
  padding: 12px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  box-sizing: border-box;
  width: 100%;
}

.warning-banner {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.warning-icon {
  font-size: 1.1rem;
}

.warning-text {
  color: #856404;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
}

.filter-section {
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 6px;
  padding: 8px 10px;
  margin-bottom: 8px;
}

.filter-section h3 {
  color: #3d5a80;
  font-size: 0.9rem;
  margin-top: 0;
  margin-bottom: 6px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.filter-select-container {
  margin-bottom: 10px;
}

.filter-select {
  width: 100%;
  padding: 8px 30px 8px 12px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #3d5a80;
  background-color: white;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 16px auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.filter-select:hover {
  border-color: #2c4a6e;
}

.filter-select:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

.selected-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.filter-tag {
  display: flex;
  align-items: center;
  background-color: #e6f0fa;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.85rem;
  color: #3d5a80;
}

.remove-filter {
  background: none;
  border: none;
  color: #3d5a80;
  margin-left: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
  padding: 0 0 0 5px;
}

.remove-filter:hover {
  color: #e63946;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin: 12px 12px;
}

.clear-button, .apply-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.clear-button {
  background-color: #f1f5f9;
  color: #3d5a80;
  border: 1px solid #98c1d9;
}

.clear-button:hover {
  background-color: #e2e8f0;
}

.apply-button {
  background-color: #3d5a80;
  color: white;
}

.apply-button:hover {
  background-color: #2c4a6e;
}

@media (max-width: 640px) {
  .advanced-filtering-content {
    width: 92%;
    margin: 0 auto;
  }
  
  .modal-body {
    padding: 10px;
  }
  
  .modal-header h2 {
    font-size: 1.4rem;
  }

  .filter-tags {
    gap: 4px;
  }

  .filter-tag-button {
    padding: 3px 8px;
    font-size: 0.8rem;
  }

  .filter-group-label {
    font-size: 0.8rem;
  }
}

/* Notes filter styles */
.notes-filter-container {
  padding: 8px 0;
}

.notes-checkbox {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #3d5a80;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.notes-checkbox input[type="checkbox"] {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.notes-checkbox:hover {
  color: #2c4a6e;
}

/* Filter tags container */
.filter-tags-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.filter-group-label {
  font-size: 0.8rem;
  color: #64748b;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  padding-left: 2px;
}

.filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.filter-tag-button {
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 0.8rem;
  color: #3d5a80;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  transition: all 0.2s ease;
  white-space: nowrap;
  line-height: 1.2;
}

.filter-tag-button:hover {
  background-color: #e2eef9;
  border-color: #3d5a80;
}

.filter-tag-button.active {
  background-color: #3d5a80;
  border-color: #3d5a80;
  color: white;
}

.filter-tag-button.active:hover {
  background-color: #2c4a6e;
  border-color: #2c4a6e;
}

.filter-tag-button.exclude {
  background-color: #e74c3c;
  border-color: #e74c3c;
  color: white;
}

.filter-tag-button.exclude:hover {
  background-color: #c0392b;
  border-color: #c0392b;
}

/* Filters grid layout */
.filters-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1px;
}

/* Make sections span full width */
.filter-section.full-width {
  width: 100%;
  margin-bottom: 8px;
  box-sizing: border-box;
}

/* Yes/No filters group styling */
.yes-no-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 0;
  box-sizing: border-box;
}

.yes-no-filter {
  background-color: #f8fafc;
  border: 1px solid #98c1d9;
  border-radius: 6px;
  padding: 6px 8px;
  margin: 0;
}

.yes-no-filter h3 {
  font-size: 0.85rem;
  margin-bottom: 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .yes-no-group {
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
  }
  
  .modal-body {
    padding: 10px;
  }
  
  .filter-section {
    padding: 6px 8px;
    margin-bottom: 6px;
  }
  
  .filter-section h3 {
    font-size: 0.85rem;
    margin-bottom: 4px;
  }

  .yes-no-filter {
    padding: 4px 6px;
  }

  .modal-footer {
    margin: 10px 10px;
  }
}

@media (max-width: 480px) {
  .yes-no-group {
    grid-template-columns: 1fr;
    gap: 6px;
  }
  
  .modal-body {
    padding: 8px;
  }
  
  .filter-section {
    padding: 4px 6px;
    margin-bottom: 6px;
  }

  .yes-no-filter {
    padding: 4px 6px;
  }

  .modal-footer {
    margin: 8px 8px;
  }
} 