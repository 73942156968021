/* Modern, integrated TabBar styling with subtle depth effects */
.tab-navigation {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: transparent;
  padding: 0;
  position: relative;
  z-index: 3;
  box-shadow: none;
  height: 3.8rem;
  margin-top: -3.8rem;
  border-bottom: none;
}

/* Stylish fading line underneath the tabbar */
.tab-navigation::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 1px;
  background: linear-gradient(to right, 
    rgba(61, 90, 128, 0) 0%, 
    rgba(61, 90, 128, 0.2) 5%, 
    rgba(61, 90, 128, 0.5) 15%, 
    rgba(61, 90, 128, 0.9) 30%, 
    rgba(61, 90, 128, 0.9) 70%, 
    rgba(61, 90, 128, 0.5) 85%, 
    rgba(61, 90, 128, 0.2) 95%, 
    rgba(61, 90, 128, 0) 100%
  );
  z-index: 2;
  box-shadow: 0 0 10px rgba(61, 90, 128, 0.4);
}

/* Elegant, modern tab design */
.tab {
  position: relative;
  padding: 0.9rem 1.5rem;
  margin: 0 4px;
  background-color: rgba(226, 232, 240, 0.9);
  color: #3d5a80;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: none;
  border: none;
  transition: all 0.2s ease-out;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.05);
  min-width: 140px;
  width: clamp(140px, 22vw, 200px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  overflow: hidden;
}

/* Tab background decoration */
.tab::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  z-index: 1;
  border-radius: 8px 8px 0 0;
}

/* Text Wrapper */
.tab span {
  position: relative;
  z-index: 2;
  display: block;
  text-align: center;
  white-space: nowrap;
  transition: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Hover State */
.tab:hover {
  background-color: rgba(241, 245, 249, 0.95);
  transform: translateY(-3px);
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.08);
}

/* Active State */
.tab.active {
  color: #ffffff;
  font-weight: 600;
  background-color: #3d5a80;
  border: none;
  padding: 1.1rem 1.8rem;
  font-size: 0.95rem;
  transform: translateY(-1px);
  z-index: 5;
  min-width: 140px;
  width: clamp(140px, 22vw, 200px);
  box-shadow: 0 -4px 12px rgba(61, 90, 128, 0.3);
}

/* Active tab top edge highlight */
.tab.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 10%;
  right: 10%;
  height: 3px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .tab-navigation {
    justify-content: center;
    height: 3.5rem;
    margin-top: -3.5rem;
  }
  
  .tab-navigation::after {
    width: 90%;
    height: 1px;
    background: linear-gradient(to right, 
      rgba(61, 90, 128, 0) 0%, 
      rgba(61, 90, 128, 0.2) 5%, 
      rgba(61, 90, 128, 0.5) 15%, 
      rgba(61, 90, 128, 0.9) 30%, 
      rgba(61, 90, 128, 0.9) 70%, 
      rgba(61, 90, 128, 0.5) 85%, 
      rgba(61, 90, 128, 0.2) 95%, 
      rgba(61, 90, 128, 0) 100%
    );
    box-shadow: 0 0 8px rgba(61, 90, 128, 0.35);
  }
  
  .tab {
    padding: 0.8rem 0.8rem;
    font-size: 0.8rem;
    margin: 0 3px;
    width: auto;
    flex: 1 1 auto;
    min-width: 110px;
    max-width: 160px;
    letter-spacing: 0.04em;
    border-radius: 6px 6px 0 0;
  }
  
  .tab.active {
    padding: 0.95rem 1rem;
    font-size: 0.85rem;
    transform: translateY(-1.5px);
    min-width: 110px;
    max-width: 170px;
  }
}

@media screen and (max-width: 600px) {
  .tab {
    padding: 0.75rem 0.6rem;
    font-size: 0.75rem;
    margin: 0 2px;
    min-width: 90px;
    max-width: 140px;
    letter-spacing: 0.03em;
  }
  
  .tab.active {
    padding: 0.85rem 0.8rem;
    font-size: 0.8rem;
    min-width: 90px;
    max-width: 150px;
  }
}

@media screen and (max-width: 480px) {
  .tab-navigation {
    height: 3.2rem;
    margin-top: -3.2rem;
  }
  
  .tab-navigation::after {
    width: 95%;
    height: 1px;
    background: linear-gradient(to right, 
      rgba(61, 90, 128, 0) 0%, 
      rgba(61, 90, 128, 0.2) 5%, 
      rgba(61, 90, 128, 0.5) 15%, 
      rgba(61, 90, 128, 0.9) 30%, 
      rgba(61, 90, 128, 0.9) 70%, 
      rgba(61, 90, 128, 0.5) 85%, 
      rgba(61, 90, 128, 0.2) 95%, 
      rgba(61, 90, 128, 0) 100%
    );
    box-shadow: 0 0 6px rgba(61, 90, 128, 0.3);
  }
  
  .tab {
    padding: 0.7rem 0.5rem;
    font-size: 0.65rem;
    letter-spacing: 0.01em;
    margin: 0 2px;
    border-radius: 5px 5px 0 0;
    min-width: 80px;
    max-width: 120px;
  }
  
  .tab.active {
    padding: 0.8rem 0.6rem;
    font-size: 0.7rem;
    transform: translateY(-1px);
    min-width: 80px;
    max-width: 130px;
  }
  
  .tab.active::after {
    left: 20%;
    right: 20%;
    height: 2px;
  }
}

/* Font declarations */
@font-face {
  font-family: 'OPTIBerling-Agency';
  src: url('../../assets/fonts/OPTIBerling-Agency/OPTIBerling-Agency.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mondia-Light';
  src: url('../../assets/fonts/Mondia Light/Mondia Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

