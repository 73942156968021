/* SPELLCARD GENERATOR SPECIFIC */
.spellcard-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Match DiceRollerModal opacity */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Same high z-index as other working modals */
  pointer-events: auto;
  /* No blur effect */
}

/* Force white background for content */
.spell-card-generator.modal-content {
  background-color: white !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  z-index: 1001;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  width: 85%;
  max-width: 500px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  pointer-events: auto;
  cursor: default; /* Normal arrow cursor */
  border-radius: 12px;
  padding: 0;
  animation: spellcard-popup-appear 0.3s ease-out;
}

/* Add the animation keyframes */
@keyframes spellcard-popup-appear {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.generator-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.generator-content h2 {
  margin: 0;
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
}

.spell-list-preview {
  border: 1px solid #98c1d9;
  background-color: #f8fafc;
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
  padding: 6px;
}

.spell-preview-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: #3d5a80;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.85rem;
  padding: 3px 6px;
  margin: 2px 0;
}

.spell-preview-item:last-child {
  border-bottom: none;
}

.generator-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 8px;
  padding: 0 8px 8px;
}

.generate-button, 
.cancel-button {
  padding: 0.6rem 1.2rem;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.generate-button {
  background-color: #3d5a80;
  color: white;
  border: none;
}

.generate-button:hover {
  background-color: #2c4a6e;
}

.cancel-button {
  background-color: transparent;
  color: #4a4a4a;
  border: 1px solid #d1d5db;
}

.cancel-button:hover {
  background-color: #f3f4f6;
}

@media (max-width: 768px) {
  .spell-card-generator.modal-content {
    width: 95%;
    max-width: none;
    max-height: 85vh;
  }
  
  .modal-header {
    padding: 0.5rem 1rem;
  }
  
  .modal-header h2 {
    font-size: 1.3rem;
    padding-right: 30px;
  }
  
  .spell-card-generator .close-button {
    right: 10px;
  }
  
  .modal-body {
    padding: 15px;
  }
  
  .checkbox-options {
    flex-direction: column;
  }
  
  .form-row {
    flex-direction: column;
  }
  
  .form-group select {
    width: 100%;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.25rem;
  background-color: #3d5a80;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  margin-bottom: 0;
}

.modal-header h2 {
  margin: 0;
  font-family: 'Mondia Light', sans-serif;
  font-size: 1.6rem;
  color: white;
  text-align: left;
  padding-right: 30px;
}

.spell-card-generator .close-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spell-card-generator .close-button:hover {
  color: #f0f0f0;
}

.spell-card-generator .close-button svg {
  width: 20px;
  height: 20px;
}

.modal-body {
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.selected-spells-section {
  padding: 0;
  border: none;
  background: none;
  margin-bottom: 0;
  margin-top: 0;
}

.selected-spells-section h3,
.layout-options-section h3 {
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  margin-bottom: 12px;
}

.form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.form-row .form-group {
  flex: 1;
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  color: #000000;
  font-weight: 500;
  font-size: 0.9rem;
}

.form-group select,
.form-group input[type="text"] {
  border: 1px solid #3d5a80;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 0.9rem;
  color: #3d5a80;
  background-color: white;
}

.form-group select {
  min-width: 120px;
  max-width: 200px;
  width: 100%;
  padding: 5px 8px;
  font-size: 0.85rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 10px;
  background-position: right 6px center;
}

.checkbox-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.checkbox-options label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #4b5563;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px;
  margin-top: 15px;
}

.header-input {
  max-width: 320px;
  width: 100%;
  padding: 6px 10px;
  box-sizing: border-box;
}

.header-input:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

/* Add scrollbar styling to match other forms */
.spell-card-generator.modal-content::-webkit-scrollbar {
  width: 8px;
}

.spell-card-generator.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.spell-card-generator.modal-content::-webkit-scrollbar-thumb {
  background: #98c1d9;
  border-radius: 4px;
}

.spell-card-generator.modal-content::-webkit-scrollbar-thumb:hover {
  background: #3d5a80;
}

/* Update the page orientation dropdown container */
.form-row.orientation-row {
  align-items: flex-end;
}

.layout-options-section {
  margin-bottom: 0;
}

.layout-options-section h3 {
  margin-bottom: 10px;
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
}

.layout-options-container {
  border: 1px solid #98c1d9;
  border-radius: 4px;
  padding: 15px;
  background-color: #f8fafc;
  margin-bottom: 0;
}

/* Add school badge styles */
.school-badge {
  padding: 1px 4px;
  border-radius: 10px;
  font-size: 0.75em;
  font-weight: 500;
  width: 35px;
  min-width: 35px;
  text-align: center;
  border: 1.5px solid;
  background-color: transparent;
  line-height: 1.2;
}

/* School colors - match spellbook generator */
.school-abjuration {
  color: #3498db;
  border-color: #3498db;
}

.school-conjuration {
  color: #f1c40f;
  border-color: #f1c40f;
}

.school-divination {
  color: #95a5a6;
  border-color: #95a5a6;
}

.school-enchantment {
  color: #e91e63;
  border-color: #e91e63;
}

.school-evocation {
  color: #e74c3c;
  border-color: #e74c3c;
}

.school-illusion {
  color: #9b59b6;
  border-color: #9b59b6;
}

.school-necromancy {
  color: #2ecc71;
  border-color: #2ecc71;
}

.school-transmutation {
  color: #e67e22;
  border-color: #e67e22;
}

.form-group.checkbox-label {
  max-width: 200px;  /* Match the max-width of other form elements */
  align-items: flex-start;
}

.form-group .checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;  /* Increased spacing between checkbox and label */
  margin-left: 0;
  padding-left: 0;
}

.form-group .checkbox-label input[type="checkbox"] {
  margin: 0;
}

/* Add spacing between checkbox and text */
.checkbox-text {
  margin-left: 5px; /* Add extra spacing between the checkbox and the text */
}

/* Add new button container styles */
.buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Adjust spacing for selected spells section */
.selected-spells-section {
  margin-top: -25px;
  padding-top: 0;
}

/* Restore cancel button styles */
.buttons-container .cancel-button {
  background-color: #f8fafc;
  color: #3d5a80;
  border: 1px solid #3d5a80;
  padding: 8px 16px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.buttons-container .cancel-button:hover {
  background-color: #e5e7eb;
}

/* Add specific styling for form elements to override any global styles */
.spell-card-generator .form-group input,
.spell-card-generator .form-group select,
.spell-card-generator .form-group textarea {
  padding: 5px 8px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 0.85em;
  color: #3d5a80;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  height: auto;
  min-height: initial;
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}

.spell-card-generator .form-group select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%233d5a80' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 16px auto;
  padding-right: 24px;
}

.checkbox-options input[type="checkbox"],
.spell-card-generator input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  margin: 0;
  border: 1px solid #9ca3af;
  border-radius: 3px;
  background-color: white;
  position: relative;
  cursor: pointer;
  outline: none;
}

.checkbox-options input[type="checkbox"]:checked,
.spell-card-generator input[type="checkbox"]:checked {
  background-color: white;
}

.checkbox-options input[type="checkbox"]:checked::before,
.spell-card-generator input[type="checkbox"]:checked::before {
  content: '\2714'; /* Unicode character for a checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: black;
}

.checkbox-options input[type="checkbox"]:hover,
.spell-card-generator input[type="checkbox"]:hover {
  border-color: #6b7280;
} 