.contact-page {
  min-height: auto;
  padding: 40px 0 60px;
  background-color: #f8fafc;
  background-image: linear-gradient(to bottom, #f8fafc, #edf3f8);
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 40px;
}

/* Left side - Contact Info */
.contact-info {
  background: white;
  padding: 30px;
  border-radius: 12px;
  border: 1px solid #98c1d9;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.contact-info h2 {
  color: #3d5a80;
  margin-bottom: 1rem;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 2rem;
  position: relative;
}

.contact-info h2:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: #EE6C4D;
}

.contact-intro {
  color: #4b5563;
  margin-bottom: 2rem;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
}

/* Contact Features */
.contact-features {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.contact-feature {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.feature-icon {
  background-color: #edf3f8;
  color: #3d5a80;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.feature-icon i {
  font-size: 1.2rem;
}

.feature-text h3 {
  color: #3d5a80;
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.feature-text p {
  color: #4b5563;
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
}

/* Social Connect */
.social-connect {
  margin-top: auto;
  padding-top: 1.5rem;
  border-top: 1px solid #edf3f8;
}

.social-connect h3 {
  color: #3d5a80;
  margin: 0 0 1rem 0;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icons a {
  background-color: #edf3f8;
  color: #3d5a80;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.social-icons a:hover {
  background-color: #3d5a80;
  color: white;
  transform: translateY(-3px);
}

.social-icons i {
  font-size: 1.5rem;
}

.social-icons svg {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}

/* Right side - Form */
.form-container {
  background: white;
  padding: 30px;
  border-radius: 12px;
  border: 1px solid #98c1d9;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  height: fit-content;
}

.form-container h2 {
  color: #3d5a80;
  margin-bottom: 1.5rem;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 2rem;
  position: relative;
}

.form-container h2:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: #EE6C4D;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  align-items: start;
}

.form-group {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 0.95rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #d1dce5;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  height: 46px;
  box-sizing: border-box;
  min-height: 0;
  line-height: normal;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
  color: #333333;
}

/* Reset browser-specific styling */
.contact-form input,
.contact-form textarea {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: 8px !important;
  background-color: #ffffff !important;
  color: #333333 !important;
  opacity: 1 !important;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #a0aec0;
  font-size: 1rem;
  opacity: 1;
}

/* Specific styles for form inputs and textareas */
.form-input,
.form-textarea {
  background-color: #ffffff !important;
  color: #333333 !important;
}

/* Scope form styles to contact page only */
.contact-page .form-group input,
.contact-page .form-group textarea,
.contact-page .form-input,
.contact-page .form-textarea {
  background-color: #ffffff !important;
  color: #333333 !important;
  border: 1px solid #d1dce5;
  border-radius: 8px;
  padding: 12px 15px;
  height: 46px;
  box-sizing: border-box;
}

.contact-page .form-group textarea {
  min-height: 150px;
  height: auto;
}

/* Remove global styling that might affect other forms */
.form-input,
.form-textarea {
  background-color: initial;
  color: initial;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 3px rgba(61, 90, 128, 0.1);
  background-color: white;
}

.captcha-container {
  margin: 0;
  min-height: 78px; /* Standard height of hCaptcha */
  display: flex;
  align-items: center;
}

.captcha-loading {
  color: #3d5a80;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  padding: 10px 0;
}

.submit-button {
  background-color: #3d5a80;
  color: white;
  border: none;
  padding: 14px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 0.25rem;
}

.submit-button:hover {
  background-color: #2c4a6e;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(61, 90, 128, 0.2);
}

.submit-button.sending {
  background-color: #a0aec0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.submit-button i {
  font-size: 1rem;
}

.success-message {
  margin-top: 1rem;
  padding: 15px;
  background-color: #edf7ed;
  color: #2e7d32;
  border: 1px solid #c8e6c9;
  border-radius: 8px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.error-message {
  margin-top: 1rem;
  padding: 15px;
  background-color: #fef2f2;
  color: #dc2626;
  border: 1px solid #fecaca;
  border-radius: 8px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/* Responsive styles */
@media (max-width: 992px) {
  .contact-container {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .contact-info {
    height: auto;
  }
  
  .contact-page {
    padding: 30px 0 40px;
  }
}

@media (max-width: 768px) {
  .form-row {
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }
  
  .contact-page {
    padding: 20px 0 30px;
  }
  
  .contact-container {
    padding: 0 15px;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .contact-info,
  .form-container {
    padding: 20px;
  }
  
  .contact-info h2,
  .form-container h2 {
    font-size: 1.8rem;
  }
} 