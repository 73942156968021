.prepared-spells-presets-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.prepared-spells-presets {
  flex: 1;
  padding: 15px;
  background-color: #f8fafc;
  border: 1px solid #98C1D9;
  border-radius: 4px;
  min-width: 280px;
  flex-basis: 350px;
  margin-left: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.preset-explanation {
  flex: 1;
  padding: 15px;
  background-color: #f8fafc;
  border: 1px solid #98C1D9;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: #4a5568;
  line-height: 1.5;
  min-width: 280px;
  flex-basis: 350px;
}

.preset-explanation h4 {
  margin-top: 0;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  color: #3d5a80;
  text-align: left;
  font-size: 1.2rem;
  font-weight: normal;
}

.prepared-spells-presets h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: #3d5a80;
  text-align: left;
  font-size: 1.2rem;
  font-weight: normal;
}

.preset-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.preset-actions input {
  flex-grow: 1;
  max-width: 200px;
  padding: 8px 12px;
  border: 1px solid #3d5a80;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: #4a5568;
  background-color: white;
  transition: all 0.3s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.preset-actions input:focus {
  outline: none;
  border-color: #3d5a80;
  box-shadow: 0 0 0 2px rgba(61, 90, 128, 0.2);
}

.preset-actions input::placeholder {
  color: #98C1D9;
}

.preset-actions button,
.preset-list button {
  padding: 5px 10px;
  background-color: #3d5a80;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s ease;
}

.preset-actions button:hover,
.preset-list button:hover {
  background-color: #2c4a6e;
}

.preset-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.preset-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #98C1D9;
  cursor: pointer;
  transition: all 0.2s ease;
}

.preset-list li:hover {
  background-color: #f0f7ff;
}

.preset-list li:last-child {
  border-bottom: none;
}

.preset-name {
  font-family: 'Poppins', sans-serif;
  color: #4a5568;
  flex-grow: 1;
  font-size: 0.9rem;
  padding: 4px 0;
}

.preset-name > span {
  font-family: 'Mondia-Light', 'OPTIBerling-Agency', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
}

.preset-buttons {
  display: flex;
  gap: 5px;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.preset-buttons button {
  position: relative;
  z-index: 1;
}

.load-button,
.save-button,
.delete-button {
  padding: 4px 8px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s ease;
  font-size: 0.8rem;
}

.load-button {
  background-color: #3d5a80;
}

.save-button {
  background-color: #4CAF50;
}

.load-button:hover,
.save-button:hover {
  background-color: #2c4a6e;
}

.save-button:hover {
  background-color: #45a049;
}

.delete-button {
  background-color: #e2e8f0;
  color: #718096;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid #98C1D9;
}

.delete-button:hover {
  background-color: #fed7d7;
  color: #e53e3e;
  border-color: #feb2b2;
}

.delete-button svg {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.preset-list li.active {
  background-color: #e6f0ff;
  border-radius: 4px;
  border-bottom: 1px solid #98C1D9;
}

@media screen and (max-width: 768px) {
  .prepared-spells-presets-container {
    flex-direction: column;
  }

  .prepared-spells-presets,
  .preset-explanation {
    max-width: 100%;
    flex-basis: auto;
  }

  .preset-actions {
    flex-direction: column;
  }

  .preset-buttons {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .preset-list li {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .preset-buttons {
    width: 100%;
    justify-content: flex-start;
  }

  .load-button,
  .save-button,
  .delete-button {
    padding: 8px 12px;
  }

  .preset-actions input {
    max-width: 100%;
  }
}

.preset-actions button.disabled {
  background-color: #cbd5e1;
  cursor: not-allowed;
  opacity: 0.7;
}

.preset-actions button.disabled:hover {
  background-color: #cbd5e1;
}

.preset-actions input:disabled {
  background-color: #f1f5f9;
  cursor: not-allowed;
  opacity: 0.7;
}

.preset-actions input:disabled::placeholder {
  color: #94a3b8;
}
